import { Button, Card, Col, Form, ListGroup, Row, Spinner } from 'react-bootstrap';
import styles from './FormField.module.css'


const FormField = ({ fieldName, fieldData, isProcessing, onChange }) => {
  return (
    <>
      <Form.Group key={fieldName} as={Row} className={`${styles['field-item']}`}>
        <Form.Label className={styles['field-label']} column xs={6}>{fieldData.label}</Form.Label>
        <Col xs={6}>
          <Form.Control
            data-testid={`input-${fieldName}`}
            maxLength={10} 
            name={fieldName} 
            className={styles['field-input']} 
            type="number" 
            step=".01" 
            onChange={(e) => onChange(e, 'todayVariables')}
            disabled={isProcessing}
            value={fieldData.value}
          />
          {
            fieldData.datetime ? <Form.Text className={styles['field-description']}>{fieldData.datetime}</Form.Text> : ''
          }
        </Col>
      </Form.Group>
    </>
  )
}

export default FormField
