
function tzoffset() {
  return (new Date()).getTimezoneOffset() * 60000;
}

function localISODateTime(date) {
  return (new Date(date - tzoffset()));
}

export function localISODateStr(date) {
  return localISODateTime(date).toISOString().substr(0, 10);
}

export function localISOTimeStr(date) {
  return localISODateTime(date).toISOString().substring(11, 16);
}

export function localDate(date) {
  date.setHours(0, 0, 0, 0);
  return date;
}

export function convertToLocalTime(datetime) {
  return datetime.toLocaleString()
}
