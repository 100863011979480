import React, { Fragment } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { Button, Col, Row, Table, Form, ButtonGroup }  from 'react-bootstrap';

import api from 'utils/api';


const OrderProcessing = () => {

  const [lineItems, setLineItems] = React.useState([]);
  const [harvestTaskDates, setHarvestTaskDates] = React.useState([]);

  const fetchUnsetLineItems = React.useCallback(() => {
    // Fetch unset line items
    const fetchData = () => {
      let url = '/packaging/unset_lineitem_names/';
      api
        .get(url)
        .then(res => {
          const { data } = res;
          if (data) {
            setLineItems(data);
          }
        });
      const harvestDateUrl = '/task/harvest_task_dates/';
      api
        .get(harvestDateUrl)
        .then(res => {
          const { data } = res;
          if (data) {
            setHarvestTaskDates(data);
          }
        });
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    fetchUnsetLineItems();
  }, [fetchUnsetLineItems]);

  const uploadFile = (file) => {
    const formData = new FormData();
    formData.append('file', file);
    api
      .post('/packaging/upload/', formData)
      .then(res => {
        toast.success(res.data);
        fetchUnsetLineItems();
      }).catch(err => {
        toast.error(err.response.error.message);
      });
  }

  function handleChange(event) {
    const newItems = lineItems.map(obj => {
            if (obj.lineitem_name === event.target.name) {
                return {...obj, harvest_id: event.target.value};
            }
            return obj;
        });
    setLineItems(newItems);
  }

  const navigate = useNavigate();
  const packagingPath = '/app/packaging/';
  const toPackaging = () =>{
    navigate(packagingPath);
  }

  const onDatesSave = React.useCallback(() => {
    api
      .post('/packaging/set_harvests/', lineItems)
      .then(res => {
        toast.success(res.data);
      }).catch(err => {
        toast.error(err.response.error.message);
      }).finally(() => {
        navigate(packagingPath);
      });
  }, [lineItems, navigate]);

  const backendOrderUrl = process.env.REACT_APP_BACKEND_URL.slice(0, process.env.REACT_APP_BACKEND_URL.length-5)
                          +"/admin/packaging/order/";

  return (
    <Fragment>
        <Row>
          <Col>
            <ButtonGroup aria-label="Basic example">
                <Button onClick={() => toPackaging()}>Packaging</Button>
                <Button variant="secondary" href={backendOrderUrl}>Manage Orders</Button>
            </ButtonGroup>
          </Col>
          <Col className='text-center'>
            <h3>Order Processing</h3>
          </Col>
          <Col>
            <div className='input-group'>
              <label htmlFor="csvUpload" className="input-group-text">Upload CSV</label>
              <Form.Control
                type='file'
                id='csvUpload'
                accept='text/csv'
                onChange={event => uploadFile(event.target.files[0])}
              />
            </div>
          </Col>
        </Row>
        <Row className='py-3'>
          <Col className='text-center px-2'>
            <Table bordered>
              <thead>
                <tr>
                  <th>Line Item Name</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody className='text-start'>
                {lineItems.map(item => (
                  <tr key={item.lineitem_name}>
                    <td>{item.lineitem_name}</td>
                    <td><Form.Select name={item.lineitem_name} value={item.harvest_id} onChange={handleChange}>
                      <option value=""></option>
                      {harvestTaskDates.map(opt => (<option key={opt.id} value={opt.id}>{opt.date}</option>))}
                    </Form.Select></td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          {lineItems.length>0 &&
          <Col className="text-end">
            <Button onClick={() => onDatesSave()}>Save</Button>
          </Col>
          }
        </Row>
    </Fragment>
  )
}

export default OrderProcessing;
