import React, { useContext, useState, createContext } from "react";

import api from 'utils/api';


const authContext = createContext();
const USER_KEY = 'user';

export const AUTH_GOOGLE_CLIENT_ID = "233590684451-m4qddgag8kob2sbr2a01458rclo0c0cv.apps.googleusercontent.com"

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [user, setUser] = useState(() => {
    let storage_user = localStorage.getItem(USER_KEY);
    if(storage_user)
      return JSON.parse(storage_user);
  });

  const signin = (googleAuthorizationCode, callbackSuccess, callbackError) => {
    const formData = new FormData()
    formData.append('code', googleAuthorizationCode)

    api.post('/signin/',
      formData
    ).then(res => {
      setUser(res.data);
      localStorage.setItem(USER_KEY, JSON.stringify(res.data));
      callbackSuccess();
    }).catch(err => {
      callbackError(err);
    })
  }

  const signout = () => {
    api.post(
      '/signout/'
    ).then(res => {
      setUser(null);
      localStorage.removeItem(USER_KEY);
    }).catch(err => {
    })
  };

  const cleanup = () => {
    localStorage.removeItem(USER_KEY);
  }

  const renew = () => {
    api.get(
      '/user/' + user.id + '/',
    ).then(res => {
      setUser(res.data);
      localStorage.setItem(USER_KEY, JSON.stringify(res.data));
    })
  };

  return {
    user,
    signin,
    signout,
    cleanup,
    renew,
  };
}
