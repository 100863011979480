import axios from 'axios';

import history from './history';

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL
});

api.defaults.withCredentials = true;

api.interceptors.response.use(
  response => response,
  err => {
    let error;
    try {
      error = err.toJSON();
    } catch (e) {
      error = err;
    }
    const status = err.response?.status;

    if (status === 401 || status === 403) {
      history.push('/signin/');
    } else if (status >= 500) {
    }
    if (err && err.response) {
      error.response = err.response.data;
    }
    return Promise.reject(error);
  }
);

export default api;
