import React, { Fragment } from 'react'
import { Form, Table }  from 'react-bootstrap';
import { Image, Plus, X } from 'react-bootstrap-icons';

import RadioReasonGroup from 'components/tasks/subComponents/RadioReasonGroup'


const PhysicalExaminationTaskComponent = (props) => {

    const updateChanges = (prop, value) => {
        const newChanges = {...props.changes};
        newChanges[prop] = value;
        props.setChanges(newChanges);
    }

    const invalidatePlSizes = (newChanges) => {
        if(!('pl_sizes' in newChanges)) {
            var pl_sizes = [];
            for(var pl_size in props.task.pl_sizes) {
                pl_sizes.push(props.task.pl_sizes[pl_size]);
            }
            newChanges['pl_sizes'] = pl_sizes;
        }
        return newChanges
    }

    const plSizes = () => {
        return ('pl_sizes' in props.changes ? props.changes.pl_sizes : props.task.pl_sizes)
    }

    const onPlSizeAdd = (task_id) => {
        var newChanges = {...props.changes};
        newChanges = invalidatePlSizes(newChanges);
        newChanges['pl_sizes'].push({'size': '' });
        props.setChanges(newChanges);
    }

    const onPlSizeDelete = (index) => {
        var newChanges = {...props.changes};
        newChanges = invalidatePlSizes(newChanges);
        newChanges['pl_sizes'].splice(index, 1);
        props.setChanges(newChanges);
    }

    const onPlSizeChange = (index, value) => {
        var newChanges = {...props.changes};
        newChanges = invalidatePlSizes(newChanges);
        newChanges['pl_sizes'][index]['size'] = value;
        props.setChanges(newChanges);
    }

    const isPlSizeInvalid = (index) => {
        return isNaN(plSizes()[index].size);
    }

    const onPictureAdd = (task_id) => {
        const newChanges = {...props.changes};
        if(!('new_pictures' in newChanges)) {
            newChanges['new_pictures'] = [];
        }
        newChanges['new_pictures'].push({
            'task': task_id,
            'description': '',
        });
        props.setChanges(newChanges);
    }

    const onPictureDelete = (id, value) => {
        const newChanges = {...props.changes};
        if(!('delete_pictures' in newChanges)) {
            newChanges['delete_pictures'] = {};
        }
        newChanges['delete_pictures'][id] = {'delete': value};
        props.setChanges(newChanges);
    }

    const onNewPictureDelete = (index) => {
        const newChanges = {...props.changes};
        newChanges['new_pictures'].splice(index, 1); 
        props.setChanges(newChanges);
    }

    const setPictureDescription = (id, value) => {
        const newChanges = {...props.changes};
        if(!('update_pictures' in newChanges)) {
            newChanges['update_pictures'] = {};
        }
        newChanges['update_pictures'][id] = {'description': value};
        props.setChanges(newChanges);
    }

    const setNewPictureDescription = (id, value) => {
        const newChanges = {...props.changes};
        newChanges['new_pictures'][id]['description'] = value;
        props.setChanges(newChanges);
    }

    const setNewPictureImage = (id, value) => {
        const newChanges = {...props.changes};
        newChanges['new_pictures'][id]['image'] = value;
        props.setChanges(newChanges);
    }

    return (
        <Fragment>
          <tr>
            <td className='align-middle'>Number of samples</td>
            <td className='align-middle'>
              <Form.Group as="span">
                <Form.Control
                  type='text'
                  defaultValue={props.task.number_of_samples}
                  onChange={e => updateChanges('number_of_samples', e.target.value)}
                  isInvalid={props.errors.number_of_samples}
                />
                <Form.Control.Feedback type='invalid'>
                  {props.errors.number_of_samples?.[0]?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </td>
          </tr>
          <tr>
            <td className='align-middle'>Overall score</td>
            <td className='align-middle'>{props.task.overall_score}</td>
          </tr>
          <tr>
            <td className='align-middle'>Average PL Size</td>
            <td className='align-middle'>{props.task.average_pl_size}</td>
          </tr>

          <tr>
            <td style={{backgroundColor: "LightSlateGray", color: "white"}}
            className='align-middle' colSpan="2">Hepatopancreas (HP)</td>
          </tr>
          {[
            'hp_color',
            'hp_necrosis',
            'hp_fluidity',
            'hp_upper_tissue',
            'hp_microphili_thickness',
            'hp_microphili_double_layer',
            'hp_microphili_tip',
            'hp_liquid_content',
            'hp_wrinkle',
            'hp_gastrointestinal_tract',
          ].map((fieldName) => (
            <RadioReasonGroup key={`radioReason-${fieldName}`}
                name={fieldName}
                value={props.task[fieldName]}
                reason={props.task[`${fieldName}_reason`]}
                changes={props.changes}
                setChanges={props.setChanges}
                errors={props.errors}/>
          ))}
          <tr>
            <td className='align-middle'>HP Overall score</td>
            <td className='align-middle'>
              <Form.Control
                as="select"
                onChange={e => updateChanges('hp_overall_score', e.target.value)}
                isInvalid={props.errors.hp_overall_score}
                defaultValue={props.task.hp_overall_score}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => (
                  <option key={value} value={value}>{value}</option>
                ))}
              </Form.Control>
            </td>
          </tr>
          <tr>
            <td style={{backgroundColor: "LightSlateGray", color: "white"}}
             className='align-middle' colSpan="2">Rostrum</td>
          </tr>
          {[
            'color',
            'muscle_damage',
          ].map((fieldName) => (
            <RadioReasonGroup key={`radioReason-${fieldName}`}
                name={fieldName}
                value={props.task[fieldName]}
                reason={props.task[`${fieldName}_reason`]}
                changes={props.changes}
                setChanges={props.setChanges}
                errors={props.errors}/>
          ))}
          <tr>
            <td className='align-middle'>Deformation</td>
            <td className='align-middle'>
              <Form.Group as="span">
                <Form.Control
                  type='text'
                  defaultValue={props.task.deformation}
                  onChange={e => updateChanges('deformation', e.target.value)}
                  isInvalid={props.errors.deformation}
                />
                <Form.Control.Feedback type='invalid'>
                  {props.errors.deformation?.[0]?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </td>
          </tr>
          <tr>
            <td className='align-middle'>Rostrum Overall score</td>
            <td className='align-middle'>
              <Form.Control
                as="select"
                onChange={e => updateChanges('rostrum_overall_score', e.target.value)}
                isInvalid={props.errors.rostrum_overall_score}
                defaultValue={props.task.rostrum_overall_score}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => (
                  <option key={value} value={value}>{value}</option>
                ))}
              </Form.Control>
            </td>
          </tr>
          <tr>
            <td style={{backgroundColor: "LightSlateGray", color: "white"}}
             className='align-middle' colSpan="2">Exoskeleton</td>
          </tr>
          {[
            'shell',
            'eyes',
            'legs',
            'gills',
            'gills_parasite',
          ].map((fieldName) => (
            <RadioReasonGroup key={`radioReason-${fieldName}`}
                name={fieldName}
                value={props.task[fieldName]}
                reason={props.task[`${fieldName}_reason`]}
                changes={props.changes}
                setChanges={props.setChanges}
                errors={props.errors}/>
          ))}
          <tr>
            <td className='align-middle'>Excoskeleton Overall score</td>
            <td className='align-middle'>
              <Form.Control
                as="select"
                onChange={e => updateChanges('exoskeleton_overall_score', e.target.value)}
                isInvalid={props.errors.exoskeleton_overall_score}
                defaultValue={props.task.exoskeleton_overall_score}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => (
                  <option key={value} value={value}>{value}</option>
                ))}
              </Form.Control>
            </td>
          </tr>
          <tr>
            <td
              style={{backgroundColor: "LightSlateGray", color: "white"}}
              className='align-middle'
              colSpan="2"
            >
              <span>PL Sizes</span>
              <span className="float-end" style={{'paddingLeft': '0px', 'paddingRight': '0px', 'cursor': 'pointer'}} onClick={() => onPlSizeAdd(props.task.id)}>
                <Plus size={30} as="span" />
              </span>
            </td>
          </tr>
          <tr>
            <td colSpan="2" style={{'padding': '0px'}} >
              <Table bordered className='mb-0'>
                <tbody>
                {plSizes().map((pl_size, index) => (
                  <tr key={index}>
                    <td className='align-middle'>Size</td>
                    <td className='align-middle'>
                      <Form.Group as="span">
                        <Form.Control
                          type='text'
                          value={pl_size.size}
                          onChange={e => onPlSizeChange(index, e.target.value)}
                          isInvalid={isPlSizeInvalid(index)}
                        />
                        <Form.Control.Feedback type='invalid'>
                          A number is required.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </td>
                    <td>
                      <X size={40} as="span" onClick={() => onPlSizeDelete(index)} />
                    </td>
                  </tr>
                ))}
                </tbody>
              </Table>
            </td>
          </tr>
          <tr>
            <td
              style={{backgroundColor: "LightSlateGray", color: "white"}}
              className='align-middle'
              colSpan="2"
            >
              <span>Pictures</span>
              <span className="float-end" style={{'paddingLeft': '0px', 'paddingRight': '0px', 'cursor': 'pointer'}} onClick={() => onPictureAdd(props.task.id)}>
                <Plus size={30} as="span" />
              </span>
            </td>
          </tr>
          <tr>
            <td colSpan="2" style={{'padding': '0px'}} >
              <Table bordered className='mb-0'>
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Description</th>
                    <th>Delete?</th>
                  </tr>
                </thead>
                <tbody>
                  {props.task.pictures.map(picture => (
                    <tr key={picture.id}>
                      <td className='align-middle'>{ picture.image &&
                        <a href={picture.image} target="_blank" rel="noreferrer"><Image size={16} /></a>
                      }
                      </td>
                      <td className='align-middle'>
                        <Form.Group as="span">
                          <Form.Control
                            type='text'
                            defaultValue={picture.description}
                            onChange={e => setPictureDescription(picture.id, e.target.value)}
                            isInvalid={props.errors.update_pictures?.[picture.id]?.description}
                          />
                          <Form.Control.Feedback type='invalid'>
                            {props.errors.update_pictures?.[picture.id]?.description?.[0]?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </td>
                      <td className='align-middle'>
                        <Form.Group as="span">
                          <Form.Check
                            type="checkbox"
                            onChange={e => onPictureDelete(picture.id, e.target.checked)}
                            label=""
                          />
                        </Form.Group>
                      </td>
                    </tr>
                  ))}
                  {props.changes.new_pictures && props.changes.new_pictures.map((picture, index) => (
                    <tr key={index}>
                      <td>
                        <Form.Control
                          type='file'
                          accept='image/*, application/pdf'
                          onChange={event => setNewPictureImage(index, event.target.files[0])}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type='text'
                          value={picture['description']}
                          onChange={e => setNewPictureDescription(index, e.target.value)}
                        />
                      </td>
                      <td>
                        <X size={40} as="span" onClick={() => onNewPictureDelete(index)} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </td>
          </tr>
        </Fragment>
    )
};

export default PhysicalExaminationTaskComponent;