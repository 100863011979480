import React from 'react'
import { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Image, Button, Card, Col, Container, Form, Navbar, Row }  from 'react-bootstrap';

import { useAuth } from "utils/auth.js";
import { useTheme } from 'components/ThemeProvider';
import api from 'utils/api';
import logo from 'images/logo.png';
import logo_white from 'images/logo_white.svg';

const EmailPassword = () => {

  const auth = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();

  const [email, setEmail] = useState('');
  const [done, setDone] = useState(false);

  const sendEmail = React.useCallback((event) => {
    event.preventDefault();
    api
      .post('/user/reset-password-email/', {
        'email': email
      })
      .then(res => {
      }).catch(err => {
      }).finally(() => {
        setDone(true);
      });
  }, [email, setDone]);

  return (
    <Fragment>
      <Navbar>
        <Container fluid>
          <Navbar.Brand href='/'>
            <Image src={theme[0] == 'dark' ? logo_white : logo} />
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container fluid='md'>
        <Row>
          <Col className="col-sm-8 offset-sm-2 col-lg-6 offset-lg-3">
            <Card>
              <Card.Body>
                <Container>
                  <Row>
                    <Col>
                      <h3 className="text-center">Password reset</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <hr/>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-md-8 offset-md-2">
                    {done ?
                      <div className='d-flex justify-content-center'>The link is sent, open it from your email</div>
                    :
                      <Form onSubmit={sendEmail}>
                        <Form.Group className="mb-3">
                          <Form.Control type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                        </Form.Group>
                        <Button variant="primary" type="submit" className="float-end">
                          Send link
                        </Button>
                      </Form>
                    }
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default EmailPassword;
