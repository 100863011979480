import React from 'react'
import { useLocation } from 'react-router-dom';
import { Fragment, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Image, Button, Card, Col, Container, Form, Navbar, Row }  from 'react-bootstrap';

import api from 'utils/api';
import { useAuth } from "utils/auth.js";
import { useTheme } from 'components/ThemeProvider';
import logo from 'images/logo.png';
import logo_white from 'images/logo_white.svg';

const ResetPassword = () => {

  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  // const [done, setDone] = useState(false);

  const changePassword = React.useCallback((event) => {
    event.preventDefault();

    let params = new URLSearchParams(location.search);
    let code = params.get('code');
    let token = params.get('token');

    api
      .post('/user/reset-password/', {
        'uidb64': code,
        'token': token,
        'password': password
      })
      .then(res => {
      }).catch(err => {
      }).finally(() => {
        navigate('/', {replace: true});
      });
  // }, [password, setDone]);
  }, [password]);

  return (
    <Fragment>
      <Navbar>
        <Container fluid>
          <Navbar.Brand href='/'>
            <Image src={theme[0] == 'dark' ? logo_white : logo} />
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container fluid='md'>
        <Row>
          <Col className="col-sm-8 offset-sm-2 col-lg-6 offset-lg-3">
            <Card>
              <Card.Body>
                <Container>
                  <Row>
                    <Col>
                      <h3 className="text-center">Choose a new password</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <hr/>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-md-8 offset-md-2">
                      <Form onSubmit={changePassword}>
                        <Form.Group className="mb-3">
                          <Form.Control type="password" placeholder="New password" onChange={(e) => setPassword(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Control type="password" placeholder="Confirm password" onChange={(e) => setConfirmPassword(e.target.value)}/>
                        </Form.Group>
                        <Button variant="primary" type="submit" className="float-end">
                          Change Password
                        </Button>
                      </Form>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default ResetPassword;
