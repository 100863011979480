import styles from './SimulationResult.module.css'
import { ListGroup } from 'react-bootstrap';


const SimulationResult = ({ result, isProcessing }) => {
  return (
    <ListGroup variant="flush">
      {
        Object.entries(result).map(([key, val]) => (
          <ListGroup.Item key={key} className={styles['result-list-item']}>
            <div className={styles['result-list-icon']}>
              <val.icon className={styles['result-icon']} />
            </div>
            <div className={styles['result-list-name']}>
              {val.name}
            </div>
            <div className={styles['result-list-number']}>
              {
                isProcessing ? <div data-testid="loader" className={styles.loader}></div> : val.value
              }
            </div>
          </ListGroup.Item>
        ))
      }
    </ListGroup>
  )
}

export default SimulationResult
