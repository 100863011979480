import React, { Fragment } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Col, Row, Form, Button }  from 'react-bootstrap';
import { PDFViewer } from '@react-pdf/renderer';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import PdfLabelAddressOnly from 'components/labels/PdfLabelAddressOnly'
import api from 'utils/api';
import './PrintLabels.css';

const PrintLabels = () => {

  const { date } = useParams()

  const [labels, setLabels] = React.useState([]);

  const [harvestDates, setHarvestDates] = React.useState([]);
  const [harvestDate, setHarvestDate] = React.useState(date);

  const fetchLabel = React.useCallback(() => {
    const fetchData = () => {
      let url = '/label/';

      if(harvestDate) {
        url += '?harvest_date=' + harvestDate;
        api
          .get(url)
          .then(res => {
            const { data } = res;
            if (data) {
              setLabels(data);
            }
          })
      }
    };
    fetchData();
  }, [harvestDate]);

  const fetchHarvestDates = React.useCallback(() => {
    const fetchData = () => {
      let url = '/packaging/dates/';
      api
        .get(url)
        .then(res => {
          const { data } = res;
          if (data) {
            const dates = data.map(x => ({'id': x, 'label': new Date(x).toLocaleDateString()}));
            setHarvestDates(dates);
          }
        })
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    fetchLabel();
    fetchHarvestDates();
  }, [fetchLabel, fetchHarvestDates]);

  const navigate = useNavigate();
  const toLabelling = () =>{
    const path = harvestDate ? '/app/label/'+harvestDate : '/app/label/';
    navigate(path);
  };

    return (
    <Fragment>
        <Row>
          <Col>
            <Button onClick={toLabelling}>Back to Labelling</Button>
          </Col>
          <Col className='text-center'>
            <h3>Print Label</h3>
          </Col>
          <Col>
            <Typeahead
              id="harvestDate-typeahead"
              onChange={(date) => setHarvestDate(date[0]?.['id'])}
              placeholder='Harvest date (dd/mm/yyy)'
              options={harvestDates}
              defaultSelected={harvestDate ? [new Date(harvestDate).toLocaleDateString()] : []}
            />
          </Col>
        </Row>
        <Row>
            {labels.length > 0 &&
            <PDFViewer className="pdfViewer">
                <PdfLabelAddressOnly labels={labels}/>
            </PDFViewer>}
        </Row>
    </Fragment>
    )
};

export default PrintLabels;