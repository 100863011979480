import React, { Fragment } from 'react'
import { Col, Row, Table, Form, InputGroup, Button }  from 'react-bootstrap';

import api from 'utils/api';


const TraceLot = () => {

  const [lotNumber, setLotNumber] = React.useState(null);
  const [lotInformation, setLotInformation] = React.useState(null);
  const [errorMsg, setErrorMsg] = React.useState(null);

  const fetchData = () => {
      let url = '/lotquery/';
      if(lotNumber) {
        setErrorMsg(null);
        url += lotNumber + '/';
        api
          .get(url)
          .then(res => {
            const { data } = res;
            if (data) {
              setLotInformation(data);
            }
          })
          .catch(ex => {
            const { response } = ex;
            setErrorMsg(response?.error?.message);
            setLotInformation(null);
          })
      }
  };

  return (
    <Fragment>
        <Row>
          <Col className='text-center'>
            <h3>Trace Lot</h3>
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col xs={6}>
            <InputGroup>
                <InputGroup.Text>
                    Lot Number
                </InputGroup.Text>
                <Form.Control type='text' onChange={(e) => setLotNumber(e.target.value)} />
                <Button onClick={fetchData}>Search</Button>
            </InputGroup>
          </Col>
        </Row>
        <Row className='py-3'>
          <Col className='text-center'>
          {errorMsg ? errorMsg : null}
          {lotInformation ?
            <Table bordered>
              <thead>
                <tr>
                  <th>Date of harvest</th>
                  <th>Harvest tank</th>
                  <th>Facility</th>
                  <th>First received on</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                    <td className='align-middle'>{lotInformation.harvest_date}</td>
                    <td className='align-middle'>{lotInformation.harvest_tank}</td>
                    <td className='align-middle'>{lotInformation.facility}</td>
                    <td className='align-middle'>{lotInformation.first_received}</td>
                </tr>
              </tbody>
            </Table>
          : null}
          </Col>
        </Row>
    </Fragment>
  )
}

export default TraceLot;
