import { useCallback } from 'react'
import { toast } from 'react-toastify'

const useErrorHandler = () => {
  const handleError = useCallback((error) => {
    const errorDetail = error.response?.error?.detail || error.response?.detail

    if (typeof errorDetail === 'string') {
      toast.error(errorDetail)
      return
    }

    if (typeof error === 'object') {
      Object.entries(errorDetail).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((message) => {
            toast.error(`${key}: ${message}`);
          })
        } else if (typeof value === 'string') {
          toast.error(`${key}: ${value}`)
        } else if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
          if (value.message) {
            toast.error(value.message)
          } else {
            Object.entries(value).forEach(([key, value]) => {
              toast.error(`${key}: ${value[0].message}`)
            })
          }
        }
      })
      return
    }

    toast.error('An unexpected error occured')
    return
  }, [])

  return handleError
}

export default useErrorHandler
