import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom';
import { Spinner, Button, Col, Row, Table }  from 'react-bootstrap';
import { ArrowClockwise } from 'react-bootstrap-icons';
import { useParams } from 'react-router-dom';

import { statusColor } from 'utils/color';
import { useAuth } from "utils/auth.js";
import api from 'utils/api';
import './Tasks.css';


const MyTasks = () => {

  let { id } = useParams();
  const auth = useAuth();
  const navigate = useNavigate();
  const [tasks, setTasks] = React.useState([]);
  const [tank, setTank] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const fetchTank = React.useCallback(() => {
    if(!id)
        return

    const fetchData = () => {
      api
        .get('/tank/' + id + '/')
        .then(res => {
          const { data } = res;
          if (data) {
            setTank(data);
          }
        })
    };
    fetchData();
  }, [id]);

  const fetchTasks = React.useCallback(() => {
    setLoading(true);

    const fetchData = () => {
      api
        .get('/task/?mytasks=true&user=' + auth.user.id + (id ? '&tank=' + id : ''))
        .then(res => {
          const { data } = res;
          if (data) {
            setTasks(data);
          }
        }).finally(() => {
          setLoading(false);
        });
    };
    fetchData();
  }, [auth, id, setTasks]);

  React.useEffect(() => {
    fetchTank();
    fetchTasks();
  }, [fetchTasks, fetchTank]);

  React.useEffect(() => {
    let interval = setInterval(() => {
      fetchTasks();
    }, 1000 * 60);
    return () => clearInterval(interval);
  }, [fetchTasks]);

  function onTaskClick(task) {
    navigate('/app/task/' + task.id + '/');
  }

  return (
    <Fragment>
        <Row>
          <Col xs={3}>
            <Button style={{'width':'100px'}} onClick={() => navigate('/app/task/add/')}>Create</Button>
          </Col>
          <Col xs={6} className='text-center'>
            <h3>My Tasks{tank ? ': Tank ' + tank.display_id : ''}</h3>
          </Col>
          <Col xs={3} className='text-end'>
            <div className='pt-1 ps-2' style={{'cursor': 'pointer'}} onClick={fetchTasks}><ArrowClockwise size={32} /></div>
          </Col>
        </Row>
        <Row className='py-3'>
          <Col className='text-center'>
            {loading ? <Spinner /> :
            <Table bordered>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Task</th>
                  <th>Tank</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {tasks.map(task => (
                  <tr key={task.id} onClick={(e) => onTaskClick(task)} style={{cursor: 'pointer'}}>
                    <td className='align-middle'>{task.date_display}</td>
                    <td className='align-middle'>{task.time}</td>
                    <td className='align-middle'>{task.type_display}</td>
                    <td className='align-middle'>{task.tank_display || task.tanks_display}</td>
                    <td className='align-middle' style={{backgroundColor: statusColor(task.status_display)}}>{task.status_display}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            }
          </Col>
        </Row>
        {/* RESERVED: if QR code scan will be required
        <Row>
          <Col className='text-center'>
            <Link to='/app/scanner/'>
              <Button variant='warning'>Scan QR Code</Button>
            </Link>
          </Col>
        </Row>
        */}
    </Fragment>
  )
}

export default MyTasks;
