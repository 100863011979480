import React, { Fragment } from 'react'
import { Form, Col, Row, Table, Button, Container }  from 'react-bootstrap';
import { Image, Plus, X } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import DropdownMultiselect from 'thirdparty/react-multiselect-dropdown-bootstrap/DropdownMultiselect';

import { localISODateStr, localISOTimeStr } from 'utils/date';
import api from 'utils/api';


const Reports = () => {

  const navigate = useNavigate();
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState(localISODateStr(new Date()));
  const [tanksSelected, setTanksSelected] = React.useState(null);
  const [tankOptions, setTankOptions] = React.useState(null);
  const [batchesSelected, setBatchesSelected] = React.useState(null);
  const [batchOptions, setBatchOptions] = React.useState(null);

  React.useEffect(() => {
    api
      .get('/tank/')
      .then(res => {
        const { data } = res;
        if (data) {
            const result = data
            .filter((x) => (x.display_id.includes('NT') && !x.display_id.includes('B')) || x.display_id.includes('HT'))
            .map((x) => ({ 'key': x.display_id, 'label': x.display_id }));
            setTankOptions(result);
        }
      });
  }, [setTankOptions]);

  React.useEffect(() => {
    api
      .get('/batch/')
      .then(res => {
        const { data } = res;
        if (data) {
            const result = data.filter((x) => parseInt(x.display_id) != NaN && parseInt(x.display_id) > 10)
            .map((x) => ({ 'key': parseInt(x.display_id), 'label': x.display_id }));
            setBatchOptions(result);
        }
      });
  }, [setBatchOptions]);

  const onTanksSelected = React.useCallback((selectedTanks) => {
    setTanksSelected(selectedTanks);
  }, [setTanksSelected]);

  const onBatchesSelected = React.useCallback((selectedBatches) => {
    setBatchesSelected(selectedBatches);
  }, [setBatchesSelected]);

  const onExportClick = () => {

    let params = {
      start_date: startDate,
      end_date: endDate,
    };
    if(tanksSelected)
        params['tanks'] = tanksSelected;
    if(batchesSelected)
        params['batches'] = batchesSelected;

    api.post('/export/performance/', params)
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', "Performance Date.csv");
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(error => console.error(error));
  };

  return (
    <Fragment>
      <Form noValidate>
        <Row>
          <Col className='text-center'>
            <h3>Export Performance Data</h3>
          </Col>
        </Row>
        <Row className='py-3'>
          <Col className='text-center'>
            <Table bordered>
              <tbody>
                <tr>
                  <td className='align-middle'>Start Date</td>
                  <td className='align-middle' colSpan="2">
                    <Form.Group as="span">
                      <Form.Control
                        type='date'
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td className='align-middle'>End Date</td>
                  <td className='align-middle' colSpan="2">
                    <Form.Group as="span">
                      <Form.Control
                        type='date'
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td className='align-middle'>Tanks</td>
                  <td className='align-middle'>
                    {tankOptions &&
                      <DropdownMultiselect
                        options={tankOptions}
                        name='tanks'
                        placeholder='Select tanks...'
                        handleOnChange={(selectedTanks) => {
                          onTanksSelected(selectedTanks);
                        }}
                      />
                    }
                  </td>
                </tr>
                <tr>
                  <td className='align-middle'>Batches</td>
                  <td className='align-middle'>
                    {batchOptions &&
                      <DropdownMultiselect
                        options={batchOptions}
                        name='batches'
                        placeholder='Select batches...'
                        handleOnChange={(selectedBatches) => {
                          onBatchesSelected(selectedBatches);
                        }}
                      />
                    }
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button variant='secondary' style={{'width':'100px'}} onClick={() => navigate(-1)}>Back</Button>
          </Col>
          <Col className='text-end'>
            <Button
              className='mx-0 px-0'
              variant='success'
              style={{'width':'100px'}}
              onClick={() => onExportClick()}
            >Export</Button>
          </Col>
        </Row>
      </Form>
    </Fragment>
  )
}

export default Reports;
