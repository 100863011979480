import React from 'react'
import { Col, Row, Table, Button, Form, Modal, Spinner} from 'react-bootstrap';
import { ArrowClockwise } from 'react-bootstrap-icons';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { toast } from 'react-toastify';

import TaskList from 'components/tasks/TaskList'
import { statusColor } from 'utils/color';
import api from 'utils/api';
import { useAuth } from 'utils/auth.js';
import { localISODateStr, localDate } from 'utils/date';
import './Monitor.css';


const Monitor = () => {
  const auth = useAuth();

  const [loading, setLoading] = React.useState(true);
  const [tasks, setTasks] = React.useState([]);
  const [genericTasks, setGenericTasks] = React.useState([]);
  const [skippedTasks, setSkippedTasks] = React.useState([]);
  const [calendarEvents, setCalendarEvents] = React.useState([]);
  const [currentDate, setCurrentDate] = React.useState(localDate(new Date()));
  const [monitorDate, setMonitorDate] = React.useState(localISODateStr(new Date()));
  const [locations, setLocations] = React.useState(null);

  const [calendarViewChecked, setCalendarViewChecked] = React.useState(false);
  const [calendarLoading, setCalendarLoading] = React.useState(false);
  const [calendarTasksLoading, setCalendarTasksLoading] = React.useState(false);
  const [taskListDate, setTaskListDate] = React.useState(null);
  const [taskListType, setTaskListType] = React.useState(null);
  const [taskListClass, setTaskListClass] = React.useState(null);
  const [taskListShow, setTaskListShow] = React.useState(false);

  const [currentLocation, setCurrentLocation] = React.useState(null);
  const [blinkTanks, setBlinkTanks] = React.useState([]);
  const [deleteSkippedTasksDialogShow, setDeleteSkippedTasksDialogShow] = React.useState(false);

  const onEventClick = React.useCallback((info) => {
    console.log(info);
    var props = info.event.extendedProps;
    setTaskListDate(props.date);
    setTaskListType(props.type);
    setTaskListClass(props.task_class);
    setTaskListShow(true);
  }, [setTaskListDate, setTaskListType, setTaskListClass, setTaskListShow]);

  const onTaskModalHide = React.useCallback(() => {
    setTaskListDate(null);
    setTaskListType(null);
    setTaskListClass(null);
    setTaskListShow(false);
  }, [setTaskListDate, setTaskListType, setTaskListClass, setTaskListShow]);

  const fetchCalendar = React.useCallback((datetime_start, datetime_end) => {
    setCalendarLoading(true);
    const fetchData = () => {
      let url = '/task/full-calendar/?datetime_start=' + encodeURIComponent(datetime_start) + '&datetime_end=' + encodeURIComponent(datetime_end);
      api
        .get(url)
        .then(res => {
          const { data } = res;
          if (data) {
            console.log(data);
            var eventsHash = [];
            for(var e in data) {
              var t_task = data[e];
              var t_date = t_task.date;
              var t_type = t_task.type;
              if(!(t_date in eventsHash))
                eventsHash[t_date] = [];
              if(!(t_type in eventsHash[t_date]))
                eventsHash[t_date][t_type] = {
                  task_class: t_task.task_class,
                  count: 0,
                };
              eventsHash[t_date][t_type].count += 1;
            }            
            var events = {
              events: [],
              display: 'block',              
            };
            for(var date in eventsHash) {
              var types = eventsHash[date];
              for(var type in types) {
                var count = types[type].count;
                var task_class = types[type].task_class;
                events.events.push({
                  title: type + ' - ' + count,
                  date: date,
                  extendedProps: {
                    date: date,
                    type: type,
                    task_class: task_class,
                  },
                  backgroundColor: {
                    // Green
                    'Daily Maintenance': 'SeaGreen',
                    'Digital Logbook': 'ForestGreen',
                    'End Of Day Report': 'DarkGreen',
                    // Blue
                    'Feeding': 'Alice',
                    'Feed Consumption': 'Blue',
                    'Harvest': 'Navy',
                    // Purple
                    'Open Batch': 'RebeccaPurple',
                    'Physical Examination': 'DarkMagenta',
                    'Recalibrate Population': 'Indigo',
                    'Report Mortality': 'DarkSlateBlue',
                    // Orange/ Red
                    'Seaweed Transfer': 'Coral',
                    'Seaweed Merging': 'Tomato',
                    'Seaweed Weighing': 'OrangeRed',
                    'Seaweed Trim Multiple': 'FireBrick',
                    'Shrimp PLs Stocking': 'DarkRed',
                    // Brown
                    'Tank Husbandry': 'Sienna',
                    'Transfer': 'Brown',
                    'Uneaten Feed': 'SaddleBrown',
                    // Gray
                    'Vibrio Testing': 'SlateGray',
                    'Water Quality': 'Grey',
                    'Weighing': 'DimGray',
                  }[type] || 'black',
                });
              }
            }
            setCalendarEvents(events);
          }
        })
        .finally(() => {
          setCalendarLoading(false);
        })
    }
    fetchData();
  }, [setCalendarLoading, setCalendarEvents]);

  const fetchTasks = React.useCallback(() => {
    setLoading(true);
    let skipped_tasks = [];

    const fetchData = () => {
      let url = '/task/?monitor=true&not_generic=true';

      if(monitorDate) {
        url += '&date=' + monitorDate;
      }
      if(currentLocation && currentLocation > 0) {
        url += '&location=' + currentLocation;
      }
      api
        .get(url)
        .then(res => {
          const { data } = res;

          if (data) {
            // Removed blinking for now
            // var blink_tanks = [];
            // for(var i in data) {
            //   var task = data[i];
            //   if('ammonia_nh4_mgl' in task) {
            //     var ammonia_nh4_mgl = task['ammonia_nh4_mgl'];
            //     if(task['ammonia_nh4_mgl'] >= 1)
            //       blink_tanks.push(task.tank.id);
            //   }
            // }
            // setBlinkTanks(blink_tanks);
            setBlinkTanks([]);

            data.forEach((task) => {
              if(task.status_display === 'Skipped') {
                skipped_tasks.push(task);
              }
            });
            setTasks(data);
          } else {
            setTasks([]);
          }
        }).finally(() => {
          setLoading(false);
        });
    };
    fetchData();

    const fetchGenericTasks = () => {
      let url = '/task/?monitor=true&type=GenericTask';

      if(monitorDate) {
        url += '&date=' + monitorDate;
      }
      if(currentLocation && currentLocation > 0) {
        url += '&location=' + currentLocation;
      }
      api
        .get(url)
        .then(res => {
          const { data } = res;

          if (data) {
            data.forEach((task) => {
              if(task.status_display === 'Skipped') {
                skipped_tasks.push(task);
              }
            });
            setGenericTasks(data);
          } else {
            setTasks([]);
          }
        }).finally(() => {
          setLoading(false);
        });
    };
    fetchGenericTasks();

    setSkippedTasks(skipped_tasks);

  }, [monitorDate, currentLocation]);

  React.useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  React.useEffect(() => {
    api
      .get('/location/')
      .then(res => {
        const { data } = res;
        if (data) {
          setLocations(data);
        }
      });
  }, [setLocations]);

  React.useEffect(() => {
    let interval = setInterval(() => {
      if(!calendarViewChecked) {
        fetchTasks();
      }
    }, 1000 * 60);
    return () => clearInterval(interval);
  }, [fetchTasks, calendarViewChecked]);

  React.useEffect(() => {
    let interval = setInterval(() => {

      var date = new Date();
      var today = localDate(new Date());

      if(currentDate < today)
        if(monitorDate)
          setMonitorDate(localISODateStr(date));
      setCurrentDate(today);

    }, 1000 * 3);
    return () => clearInterval(interval);
  }, [fetchTasks, currentDate, monitorDate]);

  const deleteSkippedTasks = React.useCallback(() => {
    var task_ids = [];
    skippedTasks.forEach((task) => {
      task_ids.push(task.id);
    });

    api
      .put('/task/delete/', task_ids)
      .then(() => {
          toast.success('' + task_ids.length + ' skipped tasks deleted successfully');
        })
      .catch(err => {
          toast.error('Deletion failed');
        })
      .finally(() => {
        fetchTasks();
        setDeleteSkippedTasksDialogShow(false);
      });
  }, [skippedTasks, fetchTasks, setDeleteSkippedTasksDialogShow]);

  const onCalendarSwitch = React.useCallback((checked) => {
    setCalendarViewChecked(checked);
  }, [setCalendarViewChecked]);

  const onCalendarDatesSet = React.useCallback((dateInfo) => {
    fetchCalendar(
      dateInfo.startStr, 
      dateInfo.endStr,
    );
  }, [fetchCalendar]);


  return (
    <div data-testid="monitor-screen">
      <Row>
        <Col>
          <div className="d-flex">
            <Form.Control
              as="select"
              data-testid="select-location"
              onChange={e => setCurrentLocation(e.target.value)}
            >
              <option value="0">All locations</option>
              {locations &&
                locations.map((location) => (
                  <option key={location.id} value={location.id}>{location.name}</option>
                ))
              }
            </Form.Control>
            <div
              style={{width: '10px'}}
            />
            <BootstrapSwitchButton
              data-testid="view-switch"
              width={200}
              checked={calendarViewChecked}
              onlabel='List'
              offlabel='Calendar'
              onstyle="outline-secondary"
              offstyle="outline-secondary"
              onChange={(checked) => {
                onCalendarSwitch(checked);
              }}
            />
          </div>
        </Col>
        <Col className='text-center'>
          <h3>Daily Action Tasks</h3>
        </Col>
        <Col className="d-flex justify-content-end">
          {!calendarViewChecked &&
          <Form.Group as="span">
            <Form.Control
              data-testid="date-filter"
              type='date'
              value={monitorDate}
              onChange={e => setMonitorDate(e.target.value)}
            />
          </Form.Group>
          }
          <div className='pt-1 ps-2' style={{'cursor': 'pointer'}} data-testid="refresh-btn" onClick={fetchTasks}><ArrowClockwise size={32} /></div>
        </Col>
      </Row>
      <Row className='pt-3'>
        <Col className='text-center px-2'>
          {!calendarViewChecked &&            
          <>
            {loading ? <Spinner /> :
            <Row>
                <Table bordered>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Time</th>
                      <th>Task</th>
                      <th>User</th>
                      <th>Tank ID</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {genericTasks.map(task => (
                      <tr data-testid="generic-task-row" key={task.id}>
                        <td>{task.date_display}</td>
                        <td>{task.time}</td>
                        <td>{task.type_display}</td>
                        <td>{task.owner_display}</td>
                        <td>{task.tank_display}</td>
                        <td data-testid="task-status" style={{backgroundColor: statusColor(task.status_display)}}>{task.status_display}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <h4>Recurring Tasks</h4>
                <Table bordered>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Time</th>
                      <th>Task</th>
                      <th>User</th>
                      <th>Tank ID</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tasks.map(task => (
                      <tr data-testid="recurring-task-row" key={task.id} className={blinkTanks.includes(task.tank?.id) ? 'blink' : '' }>
                        <td>{task.date_display}</td>
                        <td>{task.time}</td>
                        <td>{task.type_display}</td>
                        <td>{task.owner_display}</td>
                        <td>{task.tank_display}</td>
                        <td data-testid="task-status" style={{backgroundColor: statusColor(task.status_display)}}>{task.status_display}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
            </Row>

            }
          </>
          }
          {calendarViewChecked &&
          <>
            {calendarLoading &&
            <div className="text-start" style={{position: "absolute", paddingLeft: "230px", paddingTop: "5px"}}>
              <Spinner animation="border" role="status" />
            </div>
            }
            <FullCalendar
              plugins={[ dayGridPlugin ]}
              initialView='dayGridMonth'
              datesSet={onCalendarDatesSet}
              events={calendarEvents}
              editable={true}
              defaultTimedEventDuration='00:00'
              displayEventTime={false}
              eventTimeFormat={{
                hour: '2-digit',
                minute: '2-digit',
                meridiem: 'narrow',
              }}
              eventClick={(info) => onEventClick(info)}
            />
          </>
          }
        </Col>
      </Row>
      {auth.user.groups && auth.user.groups.includes('Managers') && skippedTasks.length > 0 &&
      <Row className='pb-3'>
        <Col className='text-end px-2'>
          <Button
            variant='outline-danger'
            onClick={() => setDeleteSkippedTasksDialogShow(true)}
          >Delete skipped tasks</Button>                  
        </Col>
      </Row>
      }
      <Modal
        show={taskListShow}
        size='lg'
        onHide={onTaskModalHide}
      >
        <Modal.Header closeButton>
          <Modal.Title>{(new Date(taskListDate)).toGMTString().substring(5, 17)} - {taskListType}</Modal.Title>
          {calendarTasksLoading &&
          <span style={{paddingLeft: "20px", paddingTop: "2px"}}>
            <Spinner animation="border" role="status" />
          </span>
          }
        </Modal.Header>
        <Modal.Body>
          <TaskList 
            date={taskListDate}
            type={taskListType}
            task_class={taskListClass}
            show={taskListShow}
            loadingHandler={setCalendarTasksLoading}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={deleteSkippedTasksDialogShow}
        onHide={() => setDeleteSkippedTasksDialogShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete tasks</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>Delete all listed tasks with "Skipped" status?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button data-testid="cancel-delete-btn" variant='primary' onClick={() => setDeleteSkippedTasksDialogShow(false)}>Cancel</Button>
          <Button variant='danger' onClick={() => deleteSkippedTasks(true)}>Delete</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Monitor;
