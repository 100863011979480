import { useEffect, useState } from "react"
import { Modal, Button, Spinner, Table, Row, Col } from "react-bootstrap"
import useErrorHandler from '../hooks/useErrorHandler'
import api from "utils/api"
import { convertToLocalTime } from "utils/date"

const createFormField = (label, value = '') => ({ value, label })

const INITIAL_VARIABLES_FIELDS = {
  abw: createFormField('ABW (g)'),
  adg: createFormField('ADG (g)'),
  fcr: createFormField('FCR'),
  survival: createFormField('Survival'),
  feed_rate: createFormField('Real Feed Rate Adj'),
  avg_exchange_rate: createFormField('Avg Exchange Rate (m3/hr)'),
  temp_degc: createFormField('Temperature (degC)'),
  density: createFormField('Density (kg/m3)'),
  ammonia_nh4_mgl: createFormField('Ammonia NH4 (mgL)'),
  do_mgl: createFormField('DO (mgL)'),
  turbidity: createFormField('Turbidity (NTU)'),
  latest_condition_factor: createFormField('Latest Condition Factor'),
  latest_overall_sensory: createFormField('Latest Overall Sensory'),
  current_phase: createFormField('Current Phase'),
  size_at_stocking: createFormField('Size At Stocking (mm)'),
  health_at_stocking: createFormField('Health At Stocking'),
}

const INITIAL_RESULT_FIELDS = {
  started_at: createFormField('Date', ''),
  status: createFormField('Status', ''),
  ending_abw_result: createFormField('Ending ABW', ''),
  time_taken_result: createFormField('Time taken to completed phase', ''),
  phase_fcr_result: createFormField('Phase FCR', ''),
  phase_survival_result: createFormField('Phase Survival', '')
}

const ResultModal = ({ isShowing, resultId, handleDismiss }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [resultFields, setResultFields] = useState(INITIAL_RESULT_FIELDS)
  const [variablesFields, setVariablesFields] = useState(INITIAL_VARIABLES_FIELDS)

  const handleError = useErrorHandler()

  function handleClose() {
    handleDismiss()
  }

  useEffect(() => {
    async function fetchResult() {
      setIsLoading(true)

      try {
        const { data } = await api.get(`/moanabrain/${resultId}`)

        updateResultFields(data)
        updateVariablesFields(data.variables)
      } catch (error) {
        handleError(error)
      }

      setIsLoading(false)
    }

    if (resultId) fetchResult()

  }, [resultId])

  function updateResultFields(newData) {
    setResultFields(prevResult => ({
      ...prevResult,
      started_at: { ...prevResult.started_at, value: convertToLocalTime(new Date(newData.started_at)) },
      status: { ...prevResult.status, value: newData.status },
      ending_abw_result: { ...prevResult.ending_abw_result, value: newData.ending_abw_result },
      time_taken_result: { ...prevResult.time_taken_result, value: newData.time_taken_result },
      phase_fcr_result: { ...prevResult.phase_fcr_result, value: newData.phase_fcr_result },
      phase_survival_result: { ...prevResult.phase_survival_result, value: newData.phase_survival_result }
    }))
  }

  function updateVariablesFields(newData) {
    const newVariablesFields = { ...variablesFields }

    for (const field in newData) {
      if (field in newVariablesFields) {
        newVariablesFields[field] = {
          ...newVariablesFields[field],
          value: newData[field]
        }
      }
    }

    setVariablesFields(newVariablesFields)
  }

  return (
    <Modal
      size="lg"
      show={isShowing}
      onHide={handleClose}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Result
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        {isLoading ? (
          <Spinner as="span" role="status" size="sm" />
        ) : (
          <div className={`px-3`}>
            <Row className="mb-3">
              {Object.values(resultFields).map(field => (
                <Col key={field.label} xs={6}>
                  <Row>
                    <Col className="text-start py-4 fw-bold">{field.label}:</Col>
                    <Col className="text-end py-4">{field.value}</Col>
                  </Row>
                </Col>
              ))}
            </Row>

            <h5 className="fw-bold m-0">Variables</h5>
            <Row>
              {Object.values(variablesFields).map(field => (
                <Col key={field.label} xs={4}>
                  <Row>
                    <Col className="text-start py-4 fw-bold">{field.label}:</Col>
                    <Col className="text-end py-4">{field.value}</Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </div>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default ResultModal