import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom';
import { Dropdown, DropdownButton }  from 'react-bootstrap';

// import QRCodeScanner from 'components/QRCodeScanner'
import { useAuth } from "utils/auth.js";
import api from 'utils/api';


const Scanner = () => {

  const navigate = useNavigate();
  const auth = useAuth();
  const [tanksMap, setTanksMap] = React.useState(null);
  const [tanks, setTanks] = React.useState(null);

  const fetchTasks = React.useCallback(() => {
    const fetchData = () => {
      api
        .get('/task/?user=' + auth.user.id)
        .then(res => {
          const { data } = res;
          if (data) {
            let tanks = {};
            let tanksMap = {};
            data.forEach((task, n) => {
              if(task.tank) {
                tanks[task.tank.id] = task.tank.display_id;
                tanksMap[task.tank.display_id] = task.tank.id;
              }
            });
            setTanksMap(tanksMap);
            setTanks(tanks);
            console.log(tanks);
          }
        })
    };
    fetchData();
  }, [auth]);

  React.useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  function onResultCallback(tank_id) {
    if(tank_id in tanksMap) {
      navigate('/app/mytasks/' + tanksMap[tank_id] + '/');
    } else {
      alert('No tasks for tank ID: ' + tank_id);
    }
  }

  return (
    <Fragment>
    {tanks ? 
      <Fragment>
      {/*
      <QRCodeScanner scannerID='qr-code-scanner' resultCallback={(tank_id) => onResultCallback(tank_id)} />
      */}
      <DropdownButton id="dropdown-basic-button" title='Tanks'>
        {Object.keys(tanks).map((key, index) => (
          <Dropdown.Item key={index} href={'/app/mytasks/' + key + '/'}>{tanks[key]}</Dropdown.Item>
        ))}

      </DropdownButton>
      </Fragment>
      : <Fragment />
    }
    </Fragment>
  )
}

export default Scanner;
