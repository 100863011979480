import styles from './LoaderCube.module.css'

const LoaderCube = ({ isAnimating = true }) => {
  return (
    <div data-testid="loader-cube" className={`${styles.container} ${isAnimating ? styles.animate : ''}`}>
      <div>

        <div className={`${styles.cube} ${styles.h1} ${styles.w1} ${styles.l1}`}>
          <div className={`${styles.face} ${styles.top}`}></div>
          <div className={`${styles.face} ${styles.left}`}></div>
          <div className={`${styles.face} ${styles.right}`}></div>
        </div>

        <div className={`${styles.cube} ${styles.h1} ${styles.w1} ${styles.l2}`}>
          <div className={`${styles.face} ${styles.top}`}></div>
          <div className={`${styles.face} ${styles.left}`}></div>
          <div className={`${styles.face} ${styles.right}`}></div>
        </div>

        <div className={`${styles.cube} ${styles.h1} ${styles.w1} ${styles.l3}`}>
          <div className={`${styles.face} ${styles.top}`}></div>
          <div className={`${styles.face} ${styles.left}`}></div>
          <div className={`${styles.face} ${styles.right}`}></div>
        </div>

        <div className={`${styles.cube} ${styles.h1} ${styles.w2} ${styles.l1}`}>
          <div className={`${styles.face} ${styles.top}`}></div>
          <div className={`${styles.face} ${styles.left}`}></div>
          <div className={`${styles.face} ${styles.right}`}></div>
        </div>

        <div className={`${styles.cube} ${styles.h1} ${styles.w2} ${styles.l2}`}>
          <div className={`${styles.face} ${styles.top}`}></div>
          <div className={`${styles.face} ${styles.left}`}></div>
          <div className={`${styles.face} ${styles.right}`}></div>
        </div>

        <div className={`${styles.cube} ${styles.h1} ${styles.w2} ${styles.l3}`}>
          <div className={`${styles.face} ${styles.top}`}></div>
          <div className={`${styles.face} ${styles.left}`}></div>
          <div className={`${styles.face} ${styles.right}`}></div>
        </div>

        <div className={`${styles.cube} ${styles.h1} ${styles.w3} ${styles.l1}`}>
          <div className={`${styles.face} ${styles.top}`}></div>
          <div className={`${styles.face} ${styles.left}`}></div>
          <div className={`${styles.face} ${styles.right}`}></div>
        </div>

        <div className={`${styles.cube} ${styles.h1} ${styles.w3} ${styles.l2}`}>
          <div className={`${styles.face} ${styles.top}`}></div>
          <div className={`${styles.face} ${styles.left}`}></div>
          <div className={`${styles.face} ${styles.right}`}></div>
        </div>

        <div className={`${styles.cube} ${styles.h1} ${styles.w3} ${styles.l3}`}>
          <div className={`${styles.face} ${styles.top}`}></div>
          <div className={`${styles.face} ${styles.left}`}></div>
          <div className={`${styles.face} ${styles.right}`}></div>
        </div>
      </div>

      <div className={styles.h2Container}>

        <div className={`${styles.cube} ${styles.h2} ${styles.w1} ${styles.l1}`}>
          <div className={`${styles.face} ${styles.top}`}></div>
          <div className={`${styles.face} ${styles.left}`}></div>
          <div className={`${styles.face} ${styles.right}`}></div>
        </div>

        <div className={`${styles.cube} ${styles.h2} ${styles.w1} ${styles.l2}`}>
          <div className={`${styles.face} ${styles.top}`}></div>
          <div className={`${styles.face} ${styles.left}`}></div>
          <div className={`${styles.face} ${styles.right}`}></div>
        </div>

        <div className={`${styles.cube} ${styles.h2} ${styles.w1} ${styles.l3}`}>
          <div className={`${styles.face} ${styles.top}`}></div>
          <div className={`${styles.face} ${styles.left}`}></div>
          <div className={`${styles.face} ${styles.right}`}></div>
        </div>

        <div className={`${styles.cube} ${styles.h2} ${styles.w2} ${styles.l1}`}>
          <div className={`${styles.face} ${styles.top}`}></div>
          <div className={`${styles.face} ${styles.left}`}></div>
          <div className={`${styles.face} ${styles.right}`}></div>
        </div>

        <div className={`${styles.cube} ${styles.h2} ${styles.w2} ${styles.l2}`}>
          <div className={`${styles.face} ${styles.top}`}></div>
          <div className={`${styles.face} ${styles.left}`}></div>
          <div className={`${styles.face} ${styles.right}`}></div>
        </div>

        <div className={`${styles.cube} ${styles.h2} ${styles.w2} ${styles.l3}`}>
          <div className={`${styles.face} ${styles.top}`}></div>
          <div className={`${styles.face} ${styles.left}`}></div>
          <div className={`${styles.face} ${styles.right}`}></div>
        </div>

        <div className={`${styles.cube} ${styles.h2} ${styles.w3} ${styles.l1}`}>
          <div className={`${styles.face} ${styles.top}`}></div>
          <div className={`${styles.face} ${styles.left}`}></div>
          <div className={`${styles.face} ${styles.right}`}></div>
        </div>

        <div className={`${styles.cube} ${styles.h2} ${styles.w3} ${styles.l2}`}>
          <div className={`${styles.face} ${styles.top}`}></div>
          <div className={`${styles.face} ${styles.left}`}></div>
          <div className={`${styles.face} ${styles.right}`}></div>
        </div>

        <div className={`${styles.cube} ${styles.h2} ${styles.w3} ${styles.l3}`}>
          <div className={`${styles.face} ${styles.top}`}></div>
          <div className={`${styles.face} ${styles.left}`}></div>
          <div className={`${styles.face} ${styles.right}`}></div>
        </div>
      </div>

      <div className={styles.h3Container}>

        <div className={`${styles.cube} ${styles.h3} ${styles.w1} ${styles.l1}`}>
          <div className={`${styles.face} ${styles.top}`}></div>
          <div className={`${styles.face} ${styles.left}`}></div>
          <div className={`${styles.face} ${styles.right}`}></div>
        </div>

        <div className={`${styles.cube} ${styles.h3} ${styles.w1} ${styles.l2}`}>
          <div className={`${styles.face} ${styles.top}`}></div>
          <div className={`${styles.face} ${styles.left}`}></div>
          <div className={`${styles.face} ${styles.right}`}></div>
        </div>

        <div className={`${styles.cube} ${styles.h3} ${styles.w1} ${styles.l3}`}>
          <div className={`${styles.face} ${styles.top}`}></div>
          <div className={`${styles.face} ${styles.left}`}></div>
          <div className={`${styles.face} ${styles.right}`}></div>
        </div>

        <div className={`${styles.cube} ${styles.h3} ${styles.w2} ${styles.l1}`}>
          <div className={`${styles.face} ${styles.top}`}></div>
          <div className={`${styles.face} ${styles.left}`}></div>
          <div className={`${styles.face} ${styles.right}`}></div>
        </div>

        <div className={`${styles.cube} ${styles.h3} ${styles.w2} ${styles.l2}`}>
          <div className={`${styles.face} ${styles.top}`}></div>
          <div className={`${styles.face} ${styles.left}`}></div>
          <div className={`${styles.face} ${styles.right}`}></div>
        </div>

        <div className={`${styles.cube} ${styles.h3} ${styles.w2} ${styles.l3}`}>
          <div className={`${styles.face} ${styles.top}`}></div>
          <div className={`${styles.face} ${styles.left}`}></div>
          <div className={`${styles.face} ${styles.right}`}></div>
        </div>

        <div className={`${styles.cube} ${styles.h3} ${styles.w3} ${styles.l1}`}>
          <div className={`${styles.face} ${styles.top}`}></div>
          <div className={`${styles.face} ${styles.left}`}></div>
          <div className={`${styles.face} ${styles.right}`}></div>
        </div>

        <div className={`${styles.cube} ${styles.h3} ${styles.w3} ${styles.l2}`}>
          <div className={`${styles.face} ${styles.top}`}></div>
          <div className={`${styles.face} ${styles.left}`}></div>
          <div className={`${styles.face} ${styles.right}`}></div>
        </div>

        <div className={`${styles.cube} ${styles.h3} ${styles.w3} ${styles.l3}`}>
          <div className={`${styles.face} ${styles.top}`}></div>
          <div className={`${styles.face} ${styles.left}`}></div>
          <div className={`${styles.face} ${styles.right}`}></div>
        </div>
      </div>

    </div>
  )
}
export default LoaderCube