import React, { Fragment } from 'react'
import { Form }  from 'react-bootstrap';


const FIELDS_CHOICES = {
  'GoodBad': {
    'good': 'Good',
    'bad': 'Bad',
  },
  'YesNo': {
    'yes': 'Yes',
    'no': 'No',
  },
  'PresentNotPresent': {
    'present': 'Present',
    'not_present': 'Not Present',
  },
  'Thikness': {
    'thick': 'Thick',
    'thin': 'Thin',
  },
  'Tip': {
    'thick_tip': 'Thick tip',
    'thin_tip': 'Thin tip',
  },
  'LiquidContent': {
    'precent_0_20': '0%-20%',
    'precent_21_40': '21%-40%',
    'precent_50': '50%',
    'precent_60_80': '60%-80%',
    'precent_90_100': '90%-100%',
  },
  'Wrinkle': {
    'precent_less_50': '<50%',
    'precent_50': '50%',
    'precent_more_50': '>50%',
    'full_appearance': 'Full appearance',
  },
  'Color': {
    'white': 'White',
    'clear': 'Clear',
    'dark': 'Dark',
  },
  'Shell': {
    'soft': 'Soft',
    'hard': 'Hard',
  },
  'Eyes': {
    'clear': 'Clear',
    'cloudy': 'Cloudy',
  },
  'Gills': {
    'clear': 'Clear',
    'colored': 'Colored',
  },
  'GastrointestinalTract': {
    'full': 'Full',
    'half': 'Half',
    'empty': 'Empty',
  },
}

const FIELDS_MAPPING = {
  'hp_color': 'GoodBad',
  'hp_necrosis': 'PresentNotPresent',
  'hp_fluidity': 'GoodBad',
  'hp_upper_tissue': 'PresentNotPresent',
  'hp_microphili_thickness': 'Thikness',
  'hp_microphili_double_layer': 'YesNo',
  'hp_microphili_tip': 'Tip',
  'hp_liquid_content': 'LiquidContent',
  'hp_wrinkle': 'Wrinkle',
  'hp_gastrointestinal_tract': 'GastrointestinalTract',
  'color': 'Color',
  'muscle_damage': 'PresentNotPresent',
  'shell': 'Shell',
  'eyes': 'Eyes',
  'legs': 'GoodBad',
  'gills': 'Gills',
  'gills_parasite': 'PresentNotPresent',
}

const REASON_REQUIRED_MAPPING = {
  'hp_color': 'bad',
  'hp_necrosis': 'present',
  'hp_fluidity': 'bad',
  'hp_upper_tissue': 'present',
  'hp_wrinkle': null,
  'hp_gastrointestinal_tract': ['half', 'empty'],
  'color': null,
  'muscle_damage': 'present',
  'shell': 'soft',
  'eyes': 'cloudy',
  'legs': 'bad',
  'gills': 'colored',
  'gills_parasite': 'present',
}


const RadioReasonGroup = (props) => {

    const fields_map = FIELDS_CHOICES[FIELDS_MAPPING[props.name]];
    const reason = REASON_REQUIRED_MAPPING[props.name];

    const reasonInput = document.getElementById(props.name + '_reason_div');
    const show_reason = (
        reason !== undefined
    ) && (
        (
            ((reason instanceof Array) && reason.includes(props.value)) ||
            (!(reason instanceof Array) && (props.value === reason))
        ) ||
        (reason == null)
    );

    const toLabel = (fieldName) => {
        return fieldName.replaceAll('_', ' ').replace(/(^\w|\s\w)/g, m => m.toUpperCase());
    }

    const handleChange = (event) => {
        const value = event.target.value;
        const newChanges = {...props.changes};
        updateValue(newChanges, value);

        if(reason === undefined){
            props.setChanges(newChanges);
            return;
        }
        if(
            (
                ((reason instanceof Array) && !reason.includes(value)) ||
                (!(reason instanceof Array) && (value !== reason))
            ) &&
            (reason != null)
        ) {
            reasonInput.style.display = "none";
        } else {
            reasonInput.style.display = "table-row";

            document.querySelector("[name=" + props.name + "_reason]").value = "";
            updateReason(newChanges, '');
        }
        props.setChanges(newChanges);
    };

    const updateValue = (newChanges, newValue) => {
        if(props.value === newValue) {
            delete newChanges[props.name];
        } else {
            newChanges[props.name] = newValue;
        }
    }

    const updateReason = (newChanges, newReason) => {
        if(newReason === props.reason || (newReason === "" && props.reason === null)) {
            delete newChanges[props.name + '_reason'];
        } else{
            newChanges[props.name + '_reason'] = newReason;
        }
    }

    const setReason = (newReason) => {
        const newChanges = {...props.changes};
        updateValue(newChanges, document.querySelector("[name=" + props.name + "]:checked").value);
        updateReason(newChanges, newReason);
        props.setChanges(newChanges);
    }

    return (
        <Fragment>
          <tr>
              <td className='align-middle'>{toLabel(props.name)}</td>
              <td className='align-middle'>
                {Object.keys(fields_map).map((key, index) => (
                  <Form.Check
                    inline
                    label={fields_map[key]}
                    name={props.name}
                    type="radio"
                    value={key}
                    key={key}
                    defaultChecked={props.value === key}
                    onChange={handleChange}
                  />
                ))}
              </td>
          </tr>
          <tr id={`${props.name}_reason_div`} style={{display: show_reason ? "table-row" : "none"}}>
              <td className='align-middle'>{toLabel(props.name)} Reason</td>
              <td className='align-middle'>
                <Form.Control
                    type='text'
                    name={`${props.name}_reason`}
                    defaultValue={props.reason}
                    onChange={e => setReason(e.target.value)}
                    isInvalid={props.errors[`${props.name}_reason`]}
                />
                <Form.Control.Feedback type='invalid'>
                    {props.errors[`${props.name}_reason`]?.[0]?.message}
                </Form.Control.Feedback>
              </td>
          </tr>
        </Fragment>
    )
};

export default RadioReasonGroup;