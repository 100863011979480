import React from 'react';
import { Page, Text, Document, StyleSheet, View } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: { flexDirection: "column", padding: 25 },
  table: {
    fontSize: 10,
    width: 550,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch",
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 35
  },
  cellBorder: {
    borderColor: "lightgrey",
    borderStyle: "solid",
    borderWidth: 1,
    paddingLeft: 3,
    paddingTop: 3,
  },
  cellXS: {
    flexGrow: 0.5,
    flexShrink: 1,
    flexBasis: "0%",
    alignSelf: "stretch"
  },
  cellS: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "0%",
    alignSelf: "stretch"
  },
  cellM: {
    flexGrow: 2,
    flexShrink: 1,
    flexBasis: "0%",
    alignSelf: "stretch"
  },
  cellL: {
    flexGrow: 3,
    flexShrink: 1,
    flexBasis: "0%",
    alignSelf: "stretch"
  },
  cellXL: {
    flexGrow: 4,
    flexShrink: 1,
    flexBasis: "0%",
    alignSelf: "stretch"
  },
  header: {
    backgroundColor: "#eee"
  },
  headerText: {
    fontSize: 11,
    fontWeight: 1200,
    color: "#1a245c",
    margin: 8
  }
});

const PdfLabelList = (props) => {

    const {labels} =  props;

    return (
        <Document>
            {labels.map((routeLabelList, index) => (
            <Page key={index} size="A4" style={styles.page}>
                <View style={styles.table}>
                  <View style={[styles.row, styles.header]}>
                    <Text style={[styles.headerText, styles.cellXS]}>#</Text>
                    <Text style={[styles.headerText, styles.cellM]}>Product</Text>
                    <Text style={[styles.headerText, styles.cellM]}>Name</Text>
                    <Text style={[styles.headerText, styles.cellXL]}>Address</Text>
                    <Text style={[styles.headerText, styles.cellM]}>Contact</Text>
                    <Text style={[styles.headerText, styles.cellS]}>Route</Text>
                    <Text style={[styles.headerText, styles.cellXS]}>Box</Text>
                    <Text style={[styles.headerText, styles.cellXS]}>Tray</Text>
                  </View>
                  {routeLabelList.map((label, index) => (
                  <View key={label.id} style={[styles.row]} wrap={false}>
                     <Text style={[styles.cellBorder, styles.cellXS]}>{index+1}</Text>
                     <Text style={[styles.cellBorder, styles.cellM]}>{label.product_display}</Text>
                     <Text style={[styles.cellBorder, styles.cellM]}>{label.name}</Text>
                     <Text style={[styles.cellBorder, styles.cellXL]}>{label.address_display}</Text>
                     <Text style={[styles.cellBorder, styles.cellM]}>{label.contact}</Text>
                     <Text style={[styles.cellBorder, styles.cellXS]}>{label.route}</Text>
                     <Text style={[styles.cellBorder, styles.cellS]}>{label.box_display}</Text>
                     <Text style={[styles.cellBorder, styles.cellS]}>{label.tray_display}</Text>
                  </View>
                  ))}
                </View>
            </Page>
            ))}
        </Document>
    )
};

export default PdfLabelList;