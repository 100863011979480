import React, { Fragment } from 'react'
import { Popover, OverlayTrigger }  from 'react-bootstrap';
import { Col, Row, Table }  from 'react-bootstrap';
import { Button, Form, InputGroup }  from 'react-bootstrap';
import { Image, Calendar as CalendarIcon, Search } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import Calendar from 'react-calendar';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import 'react-calendar/dist/Calendar.css';
import Highlighter from "react-highlight-words";

import api from 'utils/api';
import './Monitor.css';


const DigitalLogbook = () => {

  const [tasks, setTasks] = React.useState([]);
  const [date, setDate] = React.useState(null);
  const [calendarData, setCalendarData] = React.useState([]);
  const [searchText, setSearchText] = React.useState('');
  const navigate = useNavigate();

  const PAGE_SIZE = 10;
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(7);
  const [totalItems, setTotalItems] = React.useState(70);

  const fetchTasks = React.useCallback(() => {
    const fetchData = () => {
      let url = '/task/?ordering=-date&detailed=true&type=DigitalLogbookTask&page=' + currentPage;

      if(date) {
        let iso_date = date;
        if(iso_date instanceof Date){
          iso_date = getIsoDate(iso_date);
        }

        url += '&date=' + iso_date;
      }

      if(searchText) {
        url += '&search=' + searchText;
      }

      api
        .get(url)
        .then(res => {
          const { data } = res;
          if (data) {
            console.log(data);
            setTasks(data.results);
            setTotalPages(Math.floor(data.count/PAGE_SIZE));
            setTotalItems(Math.floor(data.count));
          }
        })
    };
    fetchData();
  }, [date, currentPage, searchText]);

  const changePage = React.useCallback((page) => {
    setCurrentPage(page);
  }, [setCurrentPage]);

  const onSearch = React.useCallback((value) => {
    setSearchText(value);
    setCurrentPage(1);
  }, [setSearchText]);

  const fetchCalendar = React.useCallback(() => {
    const fetchData = () => {
      let url = '/task/calendar?type=DigitalLogbookTask';
      api
        .get(url)
        .then(res => {
          const { data } = res;
          if (data) {
            setCalendarData(data);
          }
        })
    };
    fetchData();
  }, [setCalendarData]);

  React.useEffect(() => {
    fetchCalendar();
    fetchTasks();
  }, [fetchCalendar, fetchTasks]);

  const dateClick = React.useCallback((dateSelected) => {
    if(date instanceof Date && dateSelected.getTime() === date.getTime()){
        return setDate(null);
    }
    setDate(dateSelected);
  }, [date]);

  const onTileDisabled = ({activeStartDate, date, view }) => {
    if(view==="month"){
      if(!calendarData.includes(getIsoDate(date))){
        return 1;
      }
    }
    return 0;
  }

  const popover = (
    <Popover id="popover-basic">
      <Calendar tileDisabled={onTileDisabled} onChange={dateClick} value={date} />
    </Popover>
  );

  const getIsoDate = (thisDate) => {
    return new Date(thisDate.getTime() - thisDate.getTimezoneOffset() * 60000).toISOString().slice(0, 10);
  };

  const displayDate = () => {
    if(date){
        const stringDate = (date instanceof Date) ? date.toLocaleDateString() : date
        return "[" + stringDate +"] "
    }
    return ""
  };

  return (
    <Fragment>
        <Row>
          <Col xs={3}>
            <Button style={{'width':'100px'}} onClick={() => navigate('/app/task/digitallogbook/add/')}>Create</Button>
          </Col>
          <Col className='text-center' xs={6}>
            <h3>{displayDate()}Digital Logbook</h3>
          </Col>
          <Col xs={3}>
              <Row>
                <Col xs={10}>
                  <InputGroup>
                    <InputGroup.Text>
                        <Search size={16}/>
                    </InputGroup.Text>
                    <Form.Control
                        type='text'
                        onChange={(e) => onSearch(e.target.value)}
                    />
                  </InputGroup>
                </Col>
                <Col xs={2}>
                  <OverlayTrigger trigger="click" placement="left" overlay={popover} rootClose>
                    <CalendarIcon size={35} />
                  </OverlayTrigger>
                </Col>
              </Row>
          </Col>
        </Row>
        <Row className='py-3'>
          <Col className='text-center px-2'>
            <Table bordered>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>User</th>
                  <th>Location</th>
                  <th>Tank</th>
                  <th>Batch</th>
                  <th>Notes</th>
                  <th>Attachments</th>
                </tr>
              </thead>
              <tbody>
                {tasks.map(task => (
                  <tr key={task.id}>
                    <td>{task.executed_at_display}</td>
                    <td>{task.owner ? task.owner.username : '-'}</td>
                    <td>{task.location_display}</td>
                    <td>
                      <div>{task.tank_display}</div>
                      {task.tanks_batchs.map(tank_batch => (
                        <div key={tank_batch.id}>{tank_batch.tank_display || '-'}</div>
                      ))}
                    </td>
                    <td>
                      <div>{task.batch_display}</div>
                      {task.tanks_batchs.map(tank_batch => (
                        <div key={tank_batch.id}>{tank_batch.batch_display || '-'}</div>
                      ))}
                    </td>
                    <td>
                      <Highlighter
                        searchWords={[searchText]}
                        autoEscape={true}
                        textToHighlight={task.notes}
                      />
                    </td>
                    <td>
                      {task.attachments && task.attachments.map(attachment => (
                        <a key={attachment.id} href={attachment.file} style={{"display": "block"}} target="_blank" rel="noreferrer"><Image size={16} /></a>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        {totalPages>1 &&
        <Row>
          <Col className='text-center'>
            <PaginationControl
              changePage={
                (page) => changePage(page)
              }
              page={currentPage}
              total={totalItems}
              limit={PAGE_SIZE}
              between={4}
              ellipsis={1}
            />
          </Col>
        </Row>
        }
    </Fragment>
  )
}

export default DigitalLogbook;
