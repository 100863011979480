import { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Button, Card, Col, Container, Form, Navbar, Row }  from 'react-bootstrap';

import { useAuth } from "utils/auth.js";
import api from 'utils/api';


const UserPassword = () => {

  const auth = useAuth();
  const navigate = useNavigate();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  function changePassword(event) {
    event.preventDefault();

    api
      .post('/user/' + auth.user.id + '/change-password/', {
        'password': password
      })
      .then(res => {
        auth.signout()
      }).catch(err => {
      }).finally(() => {
      });
  }

  return (
    <Fragment>
        <Row>
          <Col className="col-sm-8 offset-sm-2 col-lg-6 offset-lg-3">
            <Card>
              <Card.Body>
                <Row>
                  <Col>
                    <h3 className="text-center">Choose a new password</h3>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <hr/>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-md-8 offset-md-2">
                    <Form onSubmit={changePassword}>
                      <Form.Group className="mb-3">
                        <Form.Control type="password" placeholder="New password" onChange={(e) => setPassword(e.target.value)} />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Control type="password" placeholder="Confirm password" onChange={(e) => setConfirmPassword(e.target.value)}/>
                      </Form.Group>
                      <Button variant="primary" type="submit" className="float-end">
                        Change Password
                      </Button>
                    </Form>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
    </Fragment>
  )
}

export default UserPassword;
