import { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Button, Card, Col, Container, Form, Navbar, Row }  from 'react-bootstrap';

import { useAuth } from "utils/auth.js";


const Settings = () => {

  const auth = useAuth();
  const navigate = useNavigate();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  function changePassword() {
    navigate('/app/password/');
  }

  return (
    <Fragment>
      <Row>
        <Col className="col-sm-8 offset-sm-2 col-lg-6 offset-lg-3">
          <Card>
            <Card.Body>
              <Row>
                <Col>
                  <h3 className="text-center">Settings</h3>
                </Col>
              </Row>
              <Row>
                <Col>
                  <hr/>
                </Col>
              </Row>
              <Row>
                <Col className="col-md-8">
                  <Button variant="primary" onClick={() => changePassword()}>
                    Change Password
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  )
}

export default Settings;
