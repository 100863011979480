import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { useAuth } from "utils/auth.js";

const PrivateRoute = ({ children }) => {
  let auth = useAuth();
  let location = useLocation();

  if(!auth.user)
    return <Navigate to="/signin/" state={{ from: location }} />;      

  return children;
}

export default PrivateRoute;
