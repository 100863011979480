import React, { Fragment } from 'react'
import { Col, Row, Table }  from 'react-bootstrap';

import { statusColor } from 'utils/color';
import api from 'utils/api';


const TaskList = (props) => {

  const [tasks, setTasks] = React.useState([]);

  const fetchTasks = React.useCallback(() => {
    const fetchData = () => {
      let url = '/task/';
      url += '?date=' + props.date;
      url += '&type=' + props.task_class;

      props.loadingHandler(true);
      api
        .get(url)
        .then(res => {
          const { data } = res;
          if (data) {
            setTasks(data);
          }
        })
        .finally(() => {
          props.loadingHandler(false);
        });
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  return (
    <Fragment>
        <Row className='py-3'>
          <Col className='text-center px-2'>
            <Table bordered>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Task</th>
                  <th>User</th>
                  <th>Tank ID</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {tasks.map(task => (
                  <tr key={task.id}>
                    <td>{task.date_display}</td>
                    <td>{task.time}</td>
                    <td>{task.type_display}</td>
                    <td>{task.owner_display}</td>
                    <td>{task.tank_display}</td>
                    <td style={{backgroundColor: statusColor(task.status_display)}}>{task.status_display}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
    </Fragment>
  )
}

export default TaskList;
