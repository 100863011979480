import React, { Fragment } from 'react'
import { Form, Col, Row, Table, Button }  from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { localISODateStr, localISOTimeStr } from 'utils/date';
import api from 'utils/api';


const FeedConsumptionTask = () => {
  const navigate = useNavigate();

  const [date, setDate] = React.useState(localISODateStr(new Date()));
  const [time, setTime] = React.useState(localISOTimeStr(new Date()));
  const [unit, setUnit] = React.useState(null);
  const [amount, setAmount] = React.useState(null);
  const [notes, setNotes] = React.useState(null);
  const [feeds, setFeeds] = React.useState(undefined);
  const [feed, setFeed] = React.useState(undefined);
  const [remaining_units, setRemaining_units] = React.useState(null);
  const [remaining_quantity, setRemaining_quantity] = React.useState(null);
  const [errors, setErrors] = React.useState({});

  React.useEffect(() => {
    api
      .get('/feed/')
      .then(res => {
        const { data } = res;
        if (data) {
          setFeeds(data);
        }
      }).catch(err => {
      });
  }, [setFeeds]);

  const onFeedsChange = React.useCallback((feed_id) => {
    setFeed(feed_id);
    if(feed_id) {
      for(var i in feeds)
        if(feeds[i].id == feed_id) {
          setRemaining_units(feeds[i].remaining_units);
          setRemaining_quantity(feeds[i].remaining_quantity);
        }
    } else {
      setRemaining_units(null);
      setRemaining_quantity(null);
    }
  }, [feeds]);

  const onTaskSubmit = React.useCallback(() => {
    let errors = {};
    if(!unit && !amount) {
      errors['unit_amount'] = [{
        'code': 'invalid',
        'message': 'Enter Unit(s) or Amount (Kg)',
      }];
    }
    if(unit && amount) {
      errors['unit_amount'] = [{
        'code': 'invalid',
        'message': 'Enter either Unit(s) or Amount (Kg) only',
      }];
    }
    setErrors(errors);
    if(Object.keys(errors).length)
        return;

    let params = {
      status: 'complete',
      type: 'Feed Consumption',
      date: date,
      start_time: time,
      feed: feed,
    };
    if(unit)
        params['unit'] = unit;
    if(amount)
        params['amount_kg'] = amount;
    if(notes)
        params['notes'] = notes;

    const postData = () => {
      api
        .post('/task/', params)
        .then(res => {
          navigate(-2);
        }).catch(err => {
        });
    }
    postData();
  }, [feed, date, time, unit, amount, notes, errors]);

  return (
    <Fragment>
      <Form noValidate>
        <Row>
          <Col className='text-center'>
            <h3>Add Feed Consumption Task</h3>
          </Col>
        </Row>
        <Row className='py-3'>
          <Col className='text-center'>
            <Table bordered>
              <tbody>
                <tr>
                  <td className='align-middle'>Feed</td>
                  <td className='align-middle'>
                    <Form.Control
                      as="select"
                      value={feed}
                      onChange={e => onFeedsChange(e.target.value)}
                    >
                      <option value="">Select feed...</option>
                      {feeds &&
                        feeds.map((feed) => (
                          <option key={feed.id} value={feed.id}>{feed.display_name}</option>
                        ))
                      }
                    </Form.Control>
                  </td>
                </tr>
                <tr>
                  <td className='align-middle'>Remaining Unit(s)</td>
                  <td className='align-middle'>{remaining_units}</td>
                </tr>
                <tr>
                  <td className='align-middle'>Remaining Quantity (Kg)</td>
                  <td className='align-middle'>{remaining_quantity}</td>
                </tr>
                <tr>
                  <td className='align-middle'>Date</td>
                  <td className='align-middle' colSpan="2">
                    <Form.Group as="span">
                      <Form.Control
                        type='date'
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                      />
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td className='align-middle'>Time</td>
                  <td className='align-middle' colSpan="2">
                    <Form.Group as="span">
                      <Form.Control
                        type='time'
                        value={time}
                        onChange={(e) => setTime(e.target.value)}
                      />
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td className='align-middle'>Unit(s)</td>
                  <td className='align-middle' colSpan="2">
                    <Form.Group as="span">
                      <Form.Control
                        type='text'
                        onChange={(e) => setUnit(e.target.value)}
                      />
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td className='align-middle'>Amount (Kg)</td>
                  <td className='align-middle' colSpan="2">
                    <Form.Group as="span">
                      <Form.Control
                        type='text'
                        onChange={(e) => setAmount(e.target.value)}
                     />
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td className='align-middle'>Notes</td>
                  <td className='align-middle'>
                    <Form.Control
                      type='text'
                      onChange={e => setNotes(e.target.value)}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
            <p className="text-danger">{errors.unit_amount?.[0]?.message}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button variant='secondary' style={{'width':'100px'}} onClick={() => navigate(-1)}>Back</Button>
          </Col>
          <Col className='text-end'>
            <Button
              className='mx-0 px-0'
              variant='success'
              style={{'width':'100px'}}
              disabled={!feed}
              onClick={() => onTaskSubmit()}
            >Submit</Button>
          </Col>
        </Row>
      </Form>
    </Fragment>
  )
}

export default FeedConsumptionTask;
