import React, { Fragment } from 'react'
import { Row, Col, ListGroup }  from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';


const AddTask = () => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <Row>
        <Col className='text-center'>
          <h3>Add Task</h3>
        </Col>
      </Row>
      <Row className='py-3'>
        <Col className='text-center'>
          <ListGroup>
            <ListGroup.Item action onClick={() => navigate('/app/task/digitallogbook/add/')}>
              Digital Logbook
            </ListGroup.Item>
            <ListGroup.Item action onClick={() => navigate('/app/task/feedconsumption/add/')}>
              Feed Consumption
            </ListGroup.Item>
            <ListGroup.Item action onClick={() => navigate('/app/task/saltconsumption/add/')}>
              Salt Consumption
            </ListGroup.Item>
            <ListGroup.Item action onClick={() => navigate('/app/task/chemicalconsumption/add/')}>
              Chemical Consumption
            </ListGroup.Item>
            <ListGroup.Item action onClick={() => navigate('/app/task/probioticconsumption/add/')}>
              Probiotic Consumption
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
    </Fragment>
  )
}

export default AddTask;