import React, { Fragment } from 'react'
import { Spinner, Col, Image, Row, Table }  from 'react-bootstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { ArrowClockwise } from 'react-bootstrap-icons';

import { useAuth } from "utils/auth.js";
import api from 'utils/api';
import assign from 'images/assign_to_me.png';
import './Tasks.css';


const Tasks = () => {

  const auth = useAuth();
  const [tasks, setTasks] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const fetchTasks = React.useCallback((noLoading) => {
    console.log(noLoading);
    if(noLoading !== true)
        setLoading(true);
    const fetchData = () => {
      api
        .get('/task/?unassigned=true&alltasks=true')
        .then(res => {
          const { data } = res;
          if (data) {
            setTasks(data);
          }
        }).finally(() => {
          setLoading(false);
        });
    };
    fetchData();
  }, [setLoading]);

  React.useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  React.useEffect(() => {
    let interval = setInterval(() => {
      fetchTasks();
    }, 1000 * 60);
    return () => clearInterval(interval);
  }, [fetchTasks]);

  function onAssignToMe(task) {
    const fetchData = () => {
      api
        .patch('/task/' + task.id + '/', {owner: auth.user.id, type: task.type})
        .then(res => {
          fetchTasks(true);
        })
    };
    fetchData();
  }

  return (
    <Fragment>
        <Row>
          <Col xs={3}>
          </Col>
          <Col className='text-center'>
            <h3>Unassigned Tasks</h3>
          </Col>
          <Col xs={3} className='text-end'>
            <div className='pt-1 ps-2' style={{'cursor': 'pointer'}} onClick={fetchTasks}><ArrowClockwise size={32} /></div>
          </Col>
        </Row>
        <Row className='py-3'>
          <Col className='text-center'>
            {loading ? <Spinner /> :
            <Table bordered>
              <thead>
                {tasks.length ?
                <tr>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Task</th>
                  <th>Tank</th>
                  <th>Add</th>
                </tr>
                : <tr></tr>
                }
              </thead>
              <tbody>
                <TransitionGroup component={null}>
                {tasks.map(task => {
                  const itemRef = React.createRef(null);
                  return (
                    <CSSTransition
                      key={task.id}
                      timeout={500}
                      classNames="item"
                      nodeRef={itemRef} 
                    >
                      <tr style={{cursor: 'pointer'}} ref={itemRef}>
                        <td className='align-middle'>{task.date_display}</td>
                        <td className='align-middle'>{task.time}</td>
                        <td className='align-middle'>{task.type_display}</td>
                        <td className='align-middle'>{task.tank_display}</td>
                        <td className='align-middle'>
                          <Image src={assign} style={{width: '30px'}} onClick={() => onAssignToMe(task)}/>
                        </td>
                      </tr>
                    </CSSTransition>
                  )
                })}
                </TransitionGroup>
              </tbody>
            </Table>
            }
          </Col>
        </Row>
    </Fragment>
  )
}

export default Tasks;
