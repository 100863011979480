import React, { Fragment } from 'react';
import { Spinner, Button, Col, Row, Table, Modal, Form, Carousel }  from 'react-bootstrap';
import { ArrowClockwise } from 'react-bootstrap-icons';

import api from 'utils/api';
import './Tasks.css';


const WaterExchangeRegime = () => {

  const [interval, setInterval] = React.useState(null);
  const [tanks, setTanks] = React.useState([]);
  const [ytdTimetable, setYtdTimetable] = React.useState({});
  const [tdyTimetable, setTdyTimetable] = React.useState({});
  const [tmrTimetable, setTmrTimetable] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [backwashSchedule, setBackwashSchedule] = React.useState([]);
  const [backwashStartTimes, setBackwashStartTimes] = React.useState([]);

  const [show, setShow] = React.useState(false);
  const [waterParameter, setWaterParameter] = React.useState({});

  const fetchTankParameters = (parameterId) => {
    api
        .get('/waterexchangeregime/'+parameterId)
        .then(res => {
          const { data } = res;
          if (data) {
            setWaterParameter(data);
          }
        }).finally(() => {
          setShow(true);
        });
  }

  const handleClose = () => setShow(false);

  const fetchTimetable = React.useCallback(() => {
    setLoading(true);

    const fetchData = () => {
      api
        .get('/waterexchangeregime/get_regime/')
        .then(res => {
          const { data } = res;
          if (data) {
            setInterval(data['interval']);
            setTanks(data['tank_order']);
            setYtdTimetable(data['timetable'][0]);
            setTdyTimetable(data['timetable'][1]);
            setTmrTimetable(data['timetable'][2]);
          }
        }).finally(() => {
          setLoading(false);
        });
    };
    fetchData();
  }, [setLoading]);

  const fetchBackwashSchedule = React.useCallback(() => {

    const fetchData = () => {
      api
        .get('/backwash/')
        .then(res => {
          const { data } = res;
          if (data) {
            setBackwashSchedule(data);
            let backwashStartTimes = [];
            data.forEach(backwash => (
                backwash['backwash_start_times'].forEach((startTime, index) => {
                    if(backwashStartTimes[index]){
                        backwashStartTimes[index].push(startTime.start_time)
                    }
                    else{
                        backwashStartTimes.push([startTime.start_time])
                    }
                })
            ));
            setBackwashStartTimes(backwashStartTimes);
          }
        });
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    fetchTimetable();
    fetchBackwashSchedule();
  }, [fetchTimetable, fetchBackwashSchedule]);


  return (
    <Fragment>
        <Row>
          <Col xs={3}>
          </Col>
          <Col xs={6} className='text-center'>
            <h3>Water Exchange Regime</h3>
          </Col>
          <Col xs={3} className='text-end'>
            <div className='pt-1 ps-2' style={{'cursor': 'pointer'}} onClick={fetchTimetable}><ArrowClockwise size={32} /></div>
          </Col>
        </Row>
        <Row>
          <Col xs={3} className='text-start'>
            Backwash Schedule
          </Col>
          <Col xs={6} className='text-center'>
          </Col>
          <Col xs={3} className='text-end'>
          </Col>
        </Row>
        <Row className='py-3'>
          <Col className='text-center'>
            <Table bordered>
              <thead>
                <tr>
                 {backwashSchedule.map(backwash => (
                    <th key={backwash.tank}>{backwash.tank_display} ({backwash.duration}mins)</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {backwashStartTimes.map(timeSet => (
                  <tr key={timeSet[0]}>
                    {timeSet.map(startTime => (
                        <td className='align-middle'
                        key={startTime}>{startTime.slice(0, -3)}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Carousel interval={null} wrap={false} defaultActiveIndex={1}>
          <Carousel.Item>
            {/* Yesterday */}
            <Row>
              <Col xs={3} className='text-start'>
                <strong>Schedule for yesterday: {ytdTimetable['date']}</strong>
              </Col>
              <Col xs={6} className='text-center'>
                <h3>Yesterday</h3>
              </Col>
              <Col xs={3} className='text-end'>
                Exchange Interval (mins): <strong>{interval}</strong>
              </Col>
            </Row>
            <Row className='py-3'>
              <Col className='text-center'>
                {loading ? <Spinner /> :
                <Table bordered>
                  <thead>
                    <tr>
                     <th>Cycle</th>
                     {tanks.map(tank => (
                        <th key={tank.value}>{tank.value}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {ytdTimetable['schedule'].map((cycle, index) => (
                      <tr key={index + 1}>
                        <td className='align-middle'>{index + 1}</td>
                        {cycle.map(tankCycle => (
                            <td className='align-middle'
                            style={tankCycle[3] ? { background: 'darksalmon' } : {}}
                            key={tankCycle[0]}>{tankCycle[0].split('T')[1].slice(0, 5)}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
                }
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            {/* Today */}
            <Row>
              <Col xs={3} className='text-start'>
                <strong>Schedule for today: {tdyTimetable['date']}</strong>
              </Col>
              <Col xs={6} className='text-center'>
                <h3>Today</h3>
              </Col>
              <Col xs={3} className='text-end'>
                Exchange Interval (mins): <strong>{interval}</strong>
              </Col>
            </Row>
            <Row className='py-3'>
              <Col className='text-center'>
                {loading ? <Spinner /> :
                <Table bordered>
                  <thead>
                    <tr>
                     <th>Cycle</th>
                     {tanks.map(tank => (
                        <th key={tank.value} onClick={() => fetchTankParameters(tank.id)}
                        style={{'cursor': 'pointer'}}>{tank.value}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {tdyTimetable['schedule'].map((cycle, index) => (
                      <tr key={index + 1}>
                        <td className='align-middle'>{index + 1}</td>
                        {cycle.map(tankCycle => (
                            <td className='align-middle'
                            style={tankCycle[3] ? { background: 'darksalmon' } : {}}
                            key={tankCycle[0]}>{tankCycle[0].split('T')[1].slice(0, 5)}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
                }
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            {/* Tomorrow */}
            <Row>
              <Col xs={3} className='text-start'>
                <strong>Schedule for tomorrow: {tmrTimetable['date']}</strong>
              </Col>
              <Col xs={6} className='text-center'>
                <h3>Tomorrow</h3>
              </Col>
              <Col xs={3} className='text-end'>
                Exchange Interval (mins): <strong>{interval}</strong>
              </Col>
            </Row>
            <Row className='py-3'>
              <Col className='text-center'>
                {loading ? <Spinner /> :
                <Table bordered>
                  <thead>
                    <tr>
                     <th>Cycle</th>
                     {tanks.map(tank => (
                        <th key={tank.value}>{tank.value}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {tmrTimetable['schedule'].map((cycle, index) => (
                      <tr key={index + 1}>
                        <td className='align-middle'>{index + 1}</td>
                        {cycle.map(tankCycle => (
                            <td className='align-middle'
                            style={tankCycle[3] ? { background: 'darksalmon' } : {}}
                            key={tankCycle[0]}>{tankCycle[0].split('T')[1].slice(0, 5)}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
                }
              </Col>
            </Row>
            </Carousel.Item>
        </Carousel>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>{waterParameter?.display_name} Water Exchange Parameters</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="drainTime">
                        <Form.Label>Drain Time(mins)</Form.Label>
                        <Form.Control defaultValue={waterParameter?.drain_time} />
                    </Form.Group>
                    <Form.Group controlId="fillTime">
                        <Form.Label>Fill Time(mins)</Form.Label>
                        <Form.Control defaultValue={waterParameter?.fill_time} />
                    </Form.Group>
                    <Form.Group controlId="bufferTime">
                        <Form.Label>Buffer Time(mins)</Form.Label>
                        <Form.Control defaultValue={waterParameter?.buffer_time} />
                    </Form.Group>
                    <Form.Group controlId="exchangeHeight">
                        <Form.Label>Exchange Height(cm)</Form.Label>
                        <Form.Control defaultValue={waterParameter?.exchange_height} />
                    </Form.Group>
                    <Form.Group controlId="sequence">
                        <Form.Label>Sequence</Form.Label>
                        <Form.Control defaultValue={waterParameter?.sequence} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleClose}>
                Save
              </Button>
            </Modal.Footer>
        </Modal>
    </Fragment>
  )
}

export default WaterExchangeRegime;
