import React, { Fragment } from 'react'
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Col, Row, Table, Form, Button, ButtonGroup }  from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import api from 'utils/api';
import './Label.css';

const Label = () => {

  const { date } = useParams()

  const [labels, setLabels] = React.useState([]);

  const [harvestDates, setHarvestDates] = React.useState([]);
  const [harvestDate, setHarvestDate] = React.useState(date);

  const fetchLabels = React.useCallback(() => {
    const fetchData = () => {
      // clear all
      setLabels([]);

      let url = '/label/';

      if(harvestDate) {
        url += '?harvest_date=' + harvestDate;
        api
        .get(url)
        .then(res => {
          const { data } = res;
          if (data) {
            setLabels(data);
          }
        })
       }
    };
    fetchData();
  }, [harvestDate]);

  const fetchHarvestDates = React.useCallback(() => {
    const fetchData = () => {
      let url = '/packaging/dates/';
      api
        .get(url)
        .then(res => {
          const { data } = res;
          if (data) {
            var dates = [];
            for(var date in data) {
              dates.push({
                'id': data[date], 
                'label': new Date(data[date]).toLocaleDateString(),
              });
            }            
            setHarvestDates(dates);
          }
        })
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    fetchLabels();
    fetchHarvestDates();
  }, [fetchLabels, fetchHarvestDates]);

  const navigate = useNavigate();
  const toPrintLabels = () =>{
    const path = harvestDate ? '/app/pdf-labels/'+harvestDate : '/app/pdf-labels/';
    navigate(path);
  };

  const toPrintLabelList = () =>{
    const path = harvestDate ? '/app/pdf-label-list/'+harvestDate : '/app/pdf-label-list/';
    navigate(path);
  };

  return (
    <Fragment>
        <Row>
          <Col>
            <ButtonGroup aria-label="Basic example">
                <Button variant="secondary" onClick={toPrintLabels}>Print Labels</Button>
                <Button onClick={toPrintLabelList}>Print Page</Button>
            </ButtonGroup>
          </Col>
          <Col className='text-center'>
            <h3>Labelling</h3>
          </Col>
          <Col>
            <Typeahead
              id="harvestDate-typeahead"
              onChange={(date) => setHarvestDate(date[0]?.['id'])}
              placeholder='Harvest date (dd/mm/yyy)'
              options={harvestDates}
              defaultSelected={harvestDate ? [new Date(harvestDate).toLocaleDateString()] : []}
            />
          </Col>
        </Row>
        <Row className='py-3'>
          <Col className='text-center px-2'>
            <Table bordered>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Product</th>
                  <th>Name</th>
                  <th>Address</th>
                  <th>Contact</th>
                  <th>Route</th>
                  <th>Box</th>
                  <th>Tray</th>
                </tr>
              </thead>
              <tbody className='text-start'>
                {labels.map((label, index) => (
                  <tr key={label.id}>
                    <td>{index+1}</td>
                    <td>{label.product_display}</td>
                    <td>{label.name}</td>
                    <td>{label.address_display}</td>
                    <td>{label.contact}</td>
                    <td>{label.route}</td>
                    <td>{label.box_display}</td>
                    <td>{label.tray_display}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
    </Fragment>
  )
}

export default Label;
