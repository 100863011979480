import React, { Fragment } from 'react'
import { Col, Row, Table }  from 'react-bootstrap';
import { Container, Button, Form }  from 'react-bootstrap';

import api from 'utils/api';
import './Monitor.css';


const SeaweedMonitor = () => {

  const [trays, setTrays] = React.useState([]);

  const fetchTrays = React.useCallback(() => {
    const fetchData = () => {
      let url = '/tray/';

      api
        .get(url)
        .then(res => {
          const { data } = res;
          if (data) {
            setTrays(data);
          }
        })
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    fetchTrays();
  }, [fetchTrays]);

  React.useEffect(() => {
    let interval = setInterval(() => {
      fetchTrays();
    }, 1000 * 60);
    return () => clearInterval(interval);
  }, [fetchTrays]);

  return (
    <Fragment>
        <Row>
          <Col className='text-center'>
            <h3>Seaweed Management</h3>
          </Col>
        </Row>
        <Row className='py-3'>
          {/* RESERVED
          <Col className='text-center px-2' xs={8}>
          */}
          <Col className='text-center px-2'>
            <Table bordered>
              <thead>
                <tr>
                  <th>Batch</th>
                  <th>Family</th>
                  <th>Serial number</th>
                  <th>Tank ID</th>
                  <th>State</th>
                  <th>Days in current state</th>
                  <th>Times trimmed</th>
                  <th>SGR</th>
                </tr>
              </thead>
              <tbody>
                {trays.map(tray => (
                  <tr key={tray.id}>
                    <td>{tray.seaweedbatch_display}</td>
                    <td>{tray.family_display}</td>
                    <td>{tray.serial_number}</td>
                    <td>{tray.tank_display}</td>
                    <td>{tray.state_display}</td>
                    <td>{tray.hours_in_state}</td>
                    <td>{tray.times_trimmed}</td>
                    <td>{tray.sgr}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
    </Fragment>
  )
}

export default SeaweedMonitor;
