import React, { Fragment } from 'react'
import { Container, Button, Form, Col, Row, Table }  from 'react-bootstrap';
import { Modal }  from 'react-bootstrap';
import DropdownMultiselect from 'thirdparty/react-multiselect-dropdown-bootstrap/DropdownMultiselect';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "utils/auth.js";
import { Image, Plus, X } from 'react-bootstrap-icons';
import { Editor } from '@tinymce/tinymce-react';

import PhysicalExaminationTaskComponent from 'components/tasks/PhysicalExaminationTask'
import api from 'utils/api';


const Task = () => {

  const navigate = useNavigate();
  const auth = useAuth();
  let { id } = useParams();
  const [mediatypes, setMediaTypes] = React.useState(null);
  const [users, setUsers] = React.useState(null);
  const [task, setTask] = React.useState(null);
  const [quantity, setQuantity] = React.useState(null);
  const [quantity_kg, setQuantity_kg] = React.useState(null);
  const [weight, setWeight] = React.useState(null);
  const [amountGArray, setAmountGArray] = React.useState({});
  const [amountPcsArray, setAmountPcsArray] = React.useState({});
  const [notes, setNotes] = React.useState(null);
  const [errors, setErrors] = React.useState({});

  // Transfer task
  const [transfer_all_tank, setTransfer_all_tank] = React.useState(false);

  // WaterQuality task
  const [ammonia_nh4_mgl, setAmmonia_nh4_mgl] = React.useState(null);
  const [nitrite_no2_mgl, setNitrite_no2_mgl] = React.useState(null);
  const [nitrate_no3_mgl, setNitrate_no3_mgl] = React.useState(null);
  const [phosphate_po4_mgl, setPhosphate_po4_mgl] = React.useState(null);
  const [do_mgl, setDo_mgl] = React.useState(null);
  const [temperature_degc, setTemperature_degc] = React.useState(null);
  const [ph, setPh] = React.useState(null);
  const [salinity_ppt, setSalinity_ppt] = React.useState(null);
  const [alkalinity_caco3_mgl, setAlkalinity_caco3_mgl] = React.useState(null);
  const [turbidity, setTurbidity] = React.useState(null);
  const [ntu, setNtu] = React.useState(null);

  // WaterFlow task
  const [fm1_cumulative_flow, setFm1_cumulative_flow] = React.useState(undefined);
  const [fm2_cumulative_flow, setFm2_cumulative_flow] = React.useState(undefined);

  // VibrioTest task
  const [vibrio_screen, setVibrio_screen] = React.useState('plate1');
  const [plate1_media_type, setPlate1_media_type] = React.useState(null);
  const [plate1_media_lot_number, setPlate1_media_lot_number] = React.useState(null);
  const [plate1_media_expiration_date, setPlate1_media_expiration_date] = React.useState(null);
  const [plate1_inoculation_volume_ul, setPlate1_inoculation_volume_ul] = React.useState(null);
  const [plate1_incubation_start_datetime, setPlate1_incubation_start_datetime] = React.useState(null);
  const [plate1_incubation_end_datetime, setPlate1_incubation_end_datetime] = React.useState(null);
  const [plate1_yellow_colony_counts, setPlate1_yellow_colony_counts] = React.useState('');
  const [plate1_yellow_colony_counts_disabled, setPlate1_yellow_colony_counts_disabled] = React.useState(false);
  const [plate1_yellow_colony_counts_many, setPlate1_yellow_colony_counts_many] = React.useState(null);
  const [plate1_green_colony_counts, setPlate1_green_colony_counts] = React.useState(null);
  const [plate1_green_colony_counts_disabled, setPlate1_green_colony_counts_disabled] = React.useState(false);
  const [plate1_green_colony_counts_many, setPlate1_green_colony_counts_many] = React.useState(null);
  const [plate1_notes, setPlate1_notes] = React.useState(null);

  const [plate2_media_type, setPlate2_media_type] = React.useState(null);
  const [plate2_media_lot_number, setPlate2_media_lot_number] = React.useState(null);
  const [plate2_media_expiration_date, setPlate2_media_expiration_date] = React.useState(null);
  const [plate2_inoculation_volume_ul, setPlate2_inoculation_volume_ul] = React.useState(null);
  const [plate2_incubation_start_datetime, setPlate2_incubation_start_datetime] = React.useState(null);
  const [plate2_incubation_end_datetime, setPlate2_incubation_end_datetime] = React.useState(null);
  const [plate2_yellow_colony_counts, setPlate2_yellow_colony_counts] = React.useState(null);
  const [plate2_yellow_colony_counts_disabled, setPlate2_yellow_colony_counts_disabled] = React.useState(false);  // TODO: this and same should be set to null and tested
  const [plate2_yellow_colony_counts_many, setPlate2_yellow_colony_counts_many] = React.useState(null);
  const [plate2_green_colony_counts, setPlate2_green_colony_counts] = React.useState(null);
  const [plate2_green_colony_counts_disabled, setPlate2_green_colony_counts_disabled] = React.useState(false);
  const [plate2_green_colony_counts_many, setPlate2_green_colony_counts_many] = React.useState(null);
  const [plate2_notes, setPlate2_notes] = React.useState(null);

  // IndividualMeasurement Task
  const [pieces, setPieces] = React.useState([]);

  // SeaweedWeightSampling Task
  const [weight_kg, setWeight_kg] = React.useState(null);

  // DailyMaintenance Task
  const [maintenanceChecklist, setMaintenanceChecklist] = React.useState({});

  // TankHusbandry Task
  const [remove_molts, setRemove_molts] = React.useState(null);
  const [remove_mortalities, setRemove_mortalities] = React.useState(null);
  const [report_mortalities, setReport_mortalities] = React.useState(null);
  const [mesh_filter_cleaning, setMesh_filter_cleaning] = React.useState(null);
  const [siphoning, setSiphoning] = React.useState(null);
  const [probe_cleaning, setProbe_cleaning] = React.useState(null);

  const tankHusbandryCompletedChoices = [
    ['completed', 'Completed'],
    ['not_completed', 'Not Completed'],
    ['not_applicable', 'Not Applicable'],
  ]

  // EndOfDayReport Task
  const [report, setReport] = React.useState(null);

  // DigitalLogbook Task
  const [tanksSelected, setTanksSelected] = React.useState([]);
  const [tankOptions, setTankOptions] = React.useState(null);
  const [batchsSelected, setBatchsSelected] = React.useState([]);
  const [batchOptions, setBatchOptions] = React.useState(null);

  const [image, setImage] = React.useState(null);

  const [taskDoneStatus, setTaskDoneStatus] = React.useState(null);
  const [taskDoneRelease, setTaskDoneRelease] = React.useState(null);

  const [doneDialogShow, setDoneDialogShow] = React.useState(false);
  const [skipDialogShow, setSkipDialogShow] = React.useState(false);
  const [partialDialogShow, setPartialDialogShow] = React.useState(false);
  const [releaseDialogShow, setReleaseDialogShow] = React.useState(false);
  const [releaseAdminDialogShow, setReleaseAdminDialogShow] = React.useState(false);
  const [releaseAdminAssignDialogShow, setReleaseAdminAssignDialogShow] = React.useState(false);
  const [releaseAdminReassignDialogShow, setReleaseAdminReassignDialogShow] = React.useState(false);
  const [reassignUser, setReassignUser] = React.useState(null);

  const [newMediaType, setNewMediaType] = React.useState(null);
  const [addMediaTypeDialogShow, setAddMediaTypeDialogShow] = React.useState(false);

  const [changes, setChanges] = React.useState({});

  const updateAmountGArray = (id, value) => {
    setAmountGArray(amounts => ({...amounts, [id]: value}));
  }

  const updateAmountPcsArray = (id, value) => {
    setAmountPcsArray(amounts => ({...amounts, [id]: value}));
  }

  const onTurbidityChanged = React.useCallback((value) => {
    setTurbidity(value);
    setNtu((0.191 + 926.1942*value).toFixed(3));
  }, [setTurbidity, setNtu]);

  React.useEffect(() => {
    if(plate1_yellow_colony_counts_many === true) {
      setPlate1_yellow_colony_counts_disabled(true);
      setPlate1_yellow_colony_counts('500');
    } else if(plate1_yellow_colony_counts_many === false) {
      setPlate1_yellow_colony_counts_disabled(false);
      setPlate1_yellow_colony_counts('');
    }
  }, [plate1_yellow_colony_counts_many]);

  React.useEffect(() => {
    if(plate1_green_colony_counts_many === true) {
      setPlate1_green_colony_counts_disabled(true);
      setPlate1_green_colony_counts('500');
    } else if(plate1_green_colony_counts_many === false) {
      setPlate1_green_colony_counts_disabled(false);
      setPlate1_green_colony_counts('');
    }
  }, [plate1_green_colony_counts_many]);

  React.useEffect(() => {
    if(plate2_yellow_colony_counts_many === true) {
      setPlate2_yellow_colony_counts_disabled(true);
      setPlate2_yellow_colony_counts('500');
    } else if(plate2_yellow_colony_counts_many === false) {
      setPlate2_yellow_colony_counts_disabled(false);
      setPlate2_yellow_colony_counts('');
    }
  }, [plate2_yellow_colony_counts_many]);

  React.useEffect(() => {
    if(plate2_green_colony_counts_many === true) {
      setPlate2_green_colony_counts_disabled(true);
      setPlate2_green_colony_counts('500');
    } else if(plate2_green_colony_counts_many === false) {
      setPlate2_green_colony_counts_disabled(false);
      setPlate2_green_colony_counts('');
    }
  }, [plate2_green_colony_counts_many]);
 
  const updatePiecesWeight = React.useCallback((index, value) => {
    const newPieces = [...pieces];
    newPieces[index]['weight'] = value;
    setPieces(newPieces);
  }, [pieces]);

  const updatePiecesLength = React.useCallback((index, value) => {
    const newPieces = [...pieces];
    newPieces[index]['length'] = value;
    setPieces(newPieces);
  }, [pieces]);

  const addPiece = React.useCallback((count=1) => {
    const newPieces = [...pieces];

    for (let i = 0; i < count; ++i)
      newPieces.push({
        'id': 0,
        'weight': '',
        'length': '',
      });
    setPieces(newPieces);
  }, [pieces]);

  const deletePiece = React.useCallback((index) => {
    const newPieces = [...pieces];
    newPieces.splice(index, 1);

    setPieces(newPieces);
    setErrors({});
  }, [pieces]);

  const fetchTankOptions = React.useCallback(() => {
    api
      .get('/tank/')
      .then(res => {
        const { data } = res;
        if (data) {
          var result = [];
          for(var i in data) {
            result.push(
              {
                'key': data[i].id,
                'label': data[i].display_id,
              }
            );
          }
          setTankOptions(result);
        }
      });
  }, [setTankOptions]);

  const fetchBatchOptions = React.useCallback(() => {
    api
      .get('/batch/')
      .then(res => {
        const { data } = res;
        if (data) {
          var result = [];
          for(var i in data) {
            result.push(
              {
                'key': data[i].id,
                'label': data[i].display_id,
              }
            );
          }
          setBatchOptions(result);
        }
      });
  }, [setBatchOptions]);

  const onTanksSelected = React.useCallback((selectedTanks) => {
    setTanksSelected(selectedTanks);
  }, [setTanksSelected]);

  const onBatchsSelected = React.useCallback((selectedBatchs) => {
    setBatchsSelected(selectedBatchs);
  }, [setBatchsSelected]);

  const fetchTask = React.useCallback(() => {
    const fetchData = () => {
      api
        .get('/task/' + id  + '/')
        .then(res => {
          const { data } = res;
          if (data) {
            setTask(data);
            switch(data.type) {
              case 'Weighing':
                setWeight(data.weight_g);
                setQuantity(data.quantity_pcs);
                break;
              case 'Seaweed Weighing':
                setWeight_kg(data.weight_kg);
                break;
              case 'Feeding':
                data.feeds.forEach((feed) => {
                  if (feed.feed.type === 'Dry') {
                    updateAmountGArray(feed.id, feed.amount_g);
                  } else {
                    updateAmountPcsArray(feed.id, feed.amount_pcs);
                  }
                });
                break;
              case 'Harvest':
                setQuantity_kg(data.quantity_kg);
                break;
              case 'Transfer':
                setQuantity_kg(data.quantity_kg);
                setTransfer_all_tank(data.transfer_all_tank);
                break;
              case 'Water Quality':
                setAmmonia_nh4_mgl(data.ammonia_nh4_mgl);
                setNitrite_no2_mgl(data.nitrite_no2_mgl);
                setNitrate_no3_mgl(data.nitrate_no3_mgl);
                setPhosphate_po4_mgl(data.phosphate_po4_mgl);
                setDo_mgl(data.do_mgl);
                setTemperature_degc(data.temperature_degc);
                setPh(data.ph);
                setSalinity_ppt(data.salinity_ppt);
                setAlkalinity_caco3_mgl(data.alkalinity_caco3_mgl);
                setTurbidity(data.turbidity);
                setNtu(data.ntu);
                break;
              case 'Water Flow':
                setFm1_cumulative_flow(data.fm1_cumulative_flow);
                setFm2_cumulative_flow(data.fm1_cumulative_flow);
                break;
              case 'Vibrio Testing':
                setPlate1_media_type(data.plate1_media_type);
                setPlate1_media_lot_number(data.plate1_media_lot_number);
                setPlate1_media_expiration_date(data.plate1_media_expiration_date);
                setPlate1_inoculation_volume_ul(data.plate1_inoculation_volume_ul);
                setPlate1_incubation_start_datetime(data.plate1_incubation_start_datetime);
                setPlate1_incubation_end_datetime(data.plate1_incubation_end_datetime);
                setPlate1_yellow_colony_counts(data.plate1_yellow_colony_counts);
                setPlate1_green_colony_counts(data.plate1_green_colony_counts);
                setPlate1_notes(data.plate1_notes);

                setPlate2_media_type(data.plate2_media_type);
                setPlate2_media_lot_number(data.plate2_media_lot_number);
                setPlate2_media_expiration_date(data.plate2_media_expiration_date);
                setPlate2_inoculation_volume_ul(data.plate2_inoculation_volume_ul);
                setPlate2_incubation_start_datetime(data.plate2_incubation_start_datetime);
                setPlate2_incubation_end_datetime(data.plate2_incubation_end_datetime);
                setPlate2_yellow_colony_counts(data.plate2_yellow_colony_counts);
                setPlate2_green_colony_counts(data.plate2_green_colony_counts);
                setPlate2_notes(data.plate2_notes);

                break;

              case 'Individual Measurement':
                setPieces(data.pieces);
                
                if(parseInt(data.number_of_pieces, 10) === 0)
                  if(pieces.length === 0)
                    addPiece(30);

                break;

              case 'Tank Husbandry':
                setRemove_molts(data.remove_molts);
                setRemove_mortalities(data.remove_mortalities);
                setReport_mortalities(data.report_mortalities);
                setMesh_filter_cleaning(data.mesh_filter_cleaning);
                setSiphoning(data.siphoning);
                setProbe_cleaning(data.probe_cleaning);
                break;

              case 'End Of Day Report':
                setReport(data.report);
                break;

              case 'Daily Maintenance':
                const checks = {
                    'arkal_filter': null,
                    'compressor': null,
                    'pumps': null,
                    'media_filter': null,
                    'site_cleaning': null,
                    'sensors': null
                };
                for (const key in checks) {
                    if(data.hasOwnProperty(key)){
                        checks[key] = data[key]
                    }
                }
                setMaintenanceChecklist(checks);
                break;
              case 'Digital Logbook':
                var tanks_batchs = data['tanks_batchs'];
                var tanks_selected = data['tank'] ? [data['tank']] : [];
                var batchs_selected = data['batch'] ? [data['batch']] : [];

                for(var tank_batch in tanks_batchs) {
                    if(tanks_batchs[tank_batch].tank)
                      tanks_selected.push('' + tanks_batchs[tank_batch].tank);
                    if(tanks_batchs[tank_batch].batch)
                      batchs_selected.push('' + tanks_batchs[tank_batch].batch);
                }
                setTanksSelected(tanks_selected);
                setBatchsSelected(batchs_selected);

                fetchTankOptions();
                fetchBatchOptions();

                break;

              default:
            }
          }
        })
    };
    fetchData();

  }, [id, fetchTankOptions, fetchBatchOptions]);


  const fetchMediaTypes = () => {
      api
        .get('/mediatype/')
        .then(res => {
          const { data } = res;
          if (data) {
            setMediaTypes(data);
          }
        });
  }

  React.useEffect(() => {
    fetchMediaTypes();
  }, [task]);

  React.useEffect(() => {
    const fetchData = () => {
      api
        .get('/user/')
        .then(res => {
          const { data } = res;
          if (data) {
            setUsers(data);
          }
        })
    };
    fetchData();
  }, [task]);


  const onMediaTypeAdd = React.useCallback(() => {
    if(addMediaTypeDialogShow)
      setAddMediaTypeDialogShow(false);
    else
      setAddMediaTypeDialogShow(true);
  }, [addMediaTypeDialogShow]);

  const addMediaType = React.useCallback(() => {
      var params = {
        'name': newMediaType
      }

      api
        .post('/mediatype/', params)
        .then(res => {
        }).catch(err => {
        }).finally(() => {
          fetchMediaTypes();
          setAddMediaTypeDialogShow(false);
        });

  }, [newMediaType]);


  const onTaskDonePressed = React.useCallback((status, release) => {
    setTaskDoneStatus('complete');
    setTaskDoneRelease(null);
    setDoneDialogShow(true);
  }, []);


  const onTaskSkipPressed = React.useCallback((status, release) => {
    setTaskDoneStatus('skipped');
    setTaskDoneRelease(null);
    setSkipDialogShow(true);
  }, []);

  const onTaskReleasePressed = React.useCallback((status, release) => {
    setTaskDoneStatus(null);
    setTaskDoneRelease(true);
    setReassignUser(null);
    if(auth.user.is_superuser)
      setReleaseAdminDialogShow(true);
    else
      setReleaseDialogShow(true);
  }, [auth]);

  const onTaskPartialPressed = React.useCallback((status, release) => {
    setTaskDoneStatus('ongoing');
    setTaskDoneRelease(null);
    setPartialDialogShow(true);
  }, []);

  const onTaskReleaseAdminPressed = React.useCallback(() => {
    setReleaseAdminDialogShow(false);
    setReleaseAdminAssignDialogShow(true);
  }, []);
  
  const onTaskAssignYesPressed = React.useCallback(() => {
    setReleaseAdminAssignDialogShow(false);
    setReleaseAdminReassignDialogShow(true);
  }, []);

  function hideDialogs() {
    setDoneDialogShow(false);
    setSkipDialogShow(false);
    setPartialDialogShow(false);
    setReleaseDialogShow(false);
    setReleaseAdminDialogShow(false);
    setReleaseAdminAssignDialogShow(false);
    setReleaseAdminReassignDialogShow(false);
  }

  const processTask = React.useCallback(() => {

    let status = taskDoneStatus;
    let release = taskDoneRelease;

    if(status !== 'skipped' && !release) {
      let errors = {}
      let have_errors = false;
      switch(task.type) {
        case 'Physical Examination':
          errors['update_pictures'] = {};
          if('update_pictures' in changes) {
            Object.keys(changes.update_pictures).map(function (key) {
              var picture = changes.update_pictures[key];
              if(!picture.description) {
                if(errors['update_pictures'][key] === undefined)
                  errors['update_pictures'][key] = {};
                errors['update_pictures'][key]['description'] = [{
                  'code': 'invalid',
                  'message': 'This field is required',
                }];
                have_errors = true;
              }
              return null;
            });
          }
          if(have_errors) {
            setErrors(errors);
            hideDialogs();
            return;
          }
          break;

        case 'Digital Logbook':
          errors['new_attachments'] = {};
          if('new_attachments' in changes) {
            Object.keys(changes.new_attachments).map(function (key) {
              var attachment = changes.new_attachments[key];
              if(!attachment.image) {
                if(errors['new_attachments'][key] === undefined)
                  errors['new_attachments'][key] = {};
                errors['new_attachments'][key]['image'] = [{
                  'code': 'invalid',
                  'message': 'This field is required',
                }];
                have_errors = true;
              }
              if(!attachment.description) {
                if(errors['new_attachments'][key] === undefined)
                  errors['new_attachments'][key] = {};
                errors['new_attachments'][key]['description'] = [{
                  'code': 'invalid',
                  'message': 'This field is required',
                }];
                have_errors = true;
              }
              return null;
            });
          }
          errors['update_attachments'] = {};
          if('update_attachments' in changes) {
            Object.keys(changes.update_attachments).map(function (key) {
              var attachment = changes.update_attachments[key];
              if(!attachment.description) {
                if(errors['update_attachments'][key] === undefined)
                  errors['update_attachments'][key] = {};
                errors['update_attachments'][key]['description'] = [{
                  'code': 'invalid',
                  'message': 'This field is required',
                }];
                have_errors = true;
              }
              return null;
            });
          }

          if(have_errors) {
            setErrors(errors);
            console.log(errors);
            hideDialogs();
            return;
          }
          break;

        case 'Weighing':
          if(!quantity)
            errors['quantity_pcs'] = [{
              'code': 'invalid',
              'message': 'This field is required',
            }];

          if(!weight)
            errors['weight_g'] = [{
              'code': 'invalid',
              'message': 'This field is required',
            }];

          if(Object.keys(errors).length) {
            setErrors(errors);
            hideDialogs();
            return;
          }
          break;
        case 'Feeding':

          // TODO: move this validation to django backend serializer, just set values as not empty
          errors['feeds'] = [];
          task.feeds.map((feed, index) => {
            Object.keys(amountGArray).forEach(function (key) { 
              if(feed.id === parseInt(key, 10)) {
                var value = amountGArray[key];
                if(!value) {
                  have_errors = true;
                  errors['feeds'][index] = {};
                  errors['feeds'][index]['amount_g'] = [{
                    'code': 'invalid',
                    'message': 'This field is required',
                  }];
                }
              }
              return null;
            });

            Object.keys(amountPcsArray).forEach(function (key) { 
              if(feed.id === parseInt(key, 10)) {
                var value = amountPcsArray[key];
                if(!value) {
                  have_errors = true;
                  errors['feeds'][index] = {};
                  errors['feeds'][index]['amount_pcs'] = [{
                    'code': 'invalid',
                    'message': 'This field is required',
                  }];
                }
              }
              return null;
            });

            return null;
          });

          break;
        case 'Harvest':
          if(!quantity_kg)
            errors['quantity_kg'] = [{
              'code': 'invalid',
              'message': 'This field is required',
            }];
          if(Object.keys(errors).length) {
            setErrors(errors);
            hideDialogs();
            return;
          }
          break;
        case 'Transfer':
          if(!quantity_kg && !transfer_all_tank)
            errors['quantity_kg'] = [{
              'code': 'invalid',
              'message': 'On completing the task, "Quantity (kg)" or "Transfer all tank content" should be set.',
            }];
          if(Object.keys(errors).length) {
            setErrors(errors);
            hideDialogs();
            return;
          }
          break;
        case 'Individual Measurement':
        // OBSOLETED:
        //  if(pieces.length < task.number_of_pieces) {
        //      have_errors = true;
        //      errors['pieces_completed'] = {'message': 'Number of pieces should be ' + task.number_of_pieces + ' or more'};
        //  }
          break;
        // TODO: check if all of the fields are empty
        //case 'Water Quality':
        //  break;
        // TODO: check if fields are empty
        //case 'Vibrio Testing':
        //  break;
        case 'Uneaten Feed':
          if(!task.uneaten_amount)
            errors['uneaten_amount'] = [{
              'code': 'invalid',
              'message': 'This field is required',
            }];
          if(Object.keys(errors).length) {
            setErrors(errors);
            hideDialogs();
            return;
          }
          break;
       default:
      }

      if(have_errors) {
        setErrors(errors);
        hideDialogs();
        return;
      }
    }

    const fetchData = () => {

      let new_status = status;
      if(!new_status)
        new_status = task.status;

      let params = {status: new_status, type: task.type};
      if(status === 'complete' || status === 'ongoing') {
        if(quantity !== null)
          params['quantity_pcs'] = quantity;
        if(quantity_kg !== null)
          params['quantity_kg'] = quantity_kg;
        if(weight !== null)
          params['weight_g'] = weight;
        if(weight_kg !== null)
          params['weight_kg'] = weight_kg;
        if(notes !== null)
          params['notes'] = notes;
        if(transfer_all_tank !== null)
          params['transfer_all_tank'] = transfer_all_tank;

        let feeds = [];

        Object.keys(amountGArray).forEach(function (key) { 
          var value = amountGArray[key];
          feeds.push({
            id: key,
            amount_g: value,
            amount_pcs: null,
          });
        });

        Object.keys(amountPcsArray).forEach(function (key) { 
          var value = amountPcsArray[key];
          feeds.push({
            id: key,
            amount_g: null,
            amount_pcs: value,
          });
        });

        params['feeds'] = feeds;
        params['pieces'] = pieces;

        // Water Quality Task
        if(report !== null)
            params['report'] = report;

        // Water Quality Task
        if(ammonia_nh4_mgl !== null)
            params['ammonia_nh4_mgl'] = ammonia_nh4_mgl;
        if(nitrite_no2_mgl !== null)
            params['nitrite_no2_mgl'] = nitrite_no2_mgl;
        if(nitrate_no3_mgl !== null)
            params['nitrate_no3_mgl'] = nitrate_no3_mgl;
        if(phosphate_po4_mgl !== null)
            params['phosphate_po4_mgl'] = phosphate_po4_mgl;
        if(do_mgl !== null)
            params['do_mgl'] = do_mgl;
        if(temperature_degc !== null)
            params['temperature_degc'] = temperature_degc;
        if(ph !== null)
            params['ph'] = ph;
        if(salinity_ppt !== null)
            params['salinity_ppt'] = salinity_ppt;
        if(alkalinity_caco3_mgl !== null)
            params['alkalinity_caco3_mgl'] = alkalinity_caco3_mgl;
        if(turbidity !== null)
            params['turbidity'] = turbidity;
        if(ntu !== null)
            params['ntu'] = ntu;

        // Water Flow Task
        if(fm1_cumulative_flow !== undefined)
            params['fm1_cumulative_flow'] = fm1_cumulative_flow;
        if(fm2_cumulative_flow !== undefined)
            params['fm2_cumulative_flow'] = fm2_cumulative_flow;

        if(plate1_media_type !== null)
            params['plate1_media_type'] = plate1_media_type;
        if(plate1_media_lot_number !== null)
            params['plate1_media_lot_number'] = plate1_media_lot_number;
        if(plate1_media_expiration_date !== null)
            params['plate1_media_expiration_date'] = plate1_media_expiration_date;
        if(plate1_inoculation_volume_ul !== null)
            params['plate1_inoculation_volume_ul'] = plate1_inoculation_volume_ul;
        if(plate1_incubation_start_datetime !== null)
            params['plate1_incubation_start_datetime'] = plate1_incubation_start_datetime;
        if(plate1_incubation_end_datetime !== null)
            params['plate1_incubation_end_datetime'] = plate1_incubation_end_datetime;
        if(plate1_yellow_colony_counts !== null)
            params['plate1_yellow_colony_counts'] = plate1_yellow_colony_counts;
        if(plate1_green_colony_counts !== null)
            params['plate1_green_colony_counts'] = plate1_green_colony_counts;
        if(plate1_notes !== null)
            params['plate1_notes'] = plate1_notes;

        if(plate2_media_type !== null)
            params['plate2_media_type'] = plate2_media_type;
        if(plate2_media_lot_number !== null)
            params['plate2_media_lot_number'] = plate2_media_lot_number;
        if(plate2_media_expiration_date !== null)
            params['plate2_media_expiration_date'] = plate2_media_expiration_date;
        if(plate2_inoculation_volume_ul !== null)
            params['plate2_inoculation_volume_ul'] = plate2_inoculation_volume_ul;
        if(plate2_incubation_start_datetime !== null)
            params['plate2_incubation_start_datetime'] = plate2_incubation_start_datetime;
        if(plate2_incubation_end_datetime !== null)
            params['plate2_incubation_end_datetime'] = plate2_incubation_end_datetime;
        if(plate2_yellow_colony_counts !== null)
            params['plate2_yellow_colony_counts'] = plate2_yellow_colony_counts;
        if(plate2_green_colony_counts !== null)
            params['plate2_green_colony_counts'] = plate2_green_colony_counts;
        if(plate2_notes !== null)
            params['plate2_notes'] = plate2_notes;

        if(remove_molts !== null)
            params['remove_molts'] = remove_molts;
        if(remove_mortalities !== null)
            params['remove_mortalities'] = remove_mortalities;
        if(report_mortalities !== null)
            params['report_mortalities'] = report_mortalities;
        if(mesh_filter_cleaning !== null)
            params['mesh_filter_cleaning'] = mesh_filter_cleaning;
        if(siphoning !== null)
            params['siphoning'] = siphoning;
        if(probe_cleaning !== null)
            params['probe_cleaning'] = probe_cleaning;

        // DigitalLogbookTask
        if(tanksSelected)
            params['tanks'] = tanksSelected;
        if(batchsSelected)
            params['batchs'] = batchsSelected;

        if(task.type === 'Daily Maintenance'){
            params = Object.assign(params,maintenanceChecklist);
        }

        if(task.type === 'Uneaten Feed'){
            params['uneaten_amount'] = task.uneaten_amount;
        }
      }

      if(release) {
        params['owner'] = null;

        if(reassignUser)
          params['owner'] = reassignUser;
      }

      if(task.type === 'Vibrio Testing' || task.type === 'Digital Logbook') {
        const formData = new FormData();

        Object.keys(params).map(function (key) {
          formData.append(key, params[key]);
          return null;
        });

        Object.keys(changes).map(function (key) {
          formData.append(key, changes[key]);
          return null;
        });
 
        if(image) {
          formData.append('image', image);
        }
        params = formData;
      }

      if(task.type === 'Physical Examination') {
        params = {...params, ...changes};
      }

      if(task.type === 'Physical Examination' && 'delete_pictures' in changes) {
        Object.keys(changes.delete_pictures).map(function (key) {
          var picture = changes.delete_pictures[key];
          if(picture.delete === true) {
            api.delete(
              '/physicalexaminationpicture/' + key  + '/',
            );
          }
          return null;
        });
      }

      if(task.type === 'Physical Examination' && 'new_pictures' in changes) {
        Object.keys(changes.new_pictures).map(function (key) {
          var picture = changes.new_pictures[key];
          const formData = new FormData();
          formData.append('image', picture.image);
          formData.append('description', picture.description);
          formData.append('task', picture.task);
          api.post(
            '/physicalexaminationpicture/',
            formData,
          );
          return null;
        });
      }

      if(task.type === 'Physical Examination' && 'update_pictures' in changes) {
        Object.keys(changes.update_pictures).map(function (key) {
          var picture = changes.update_pictures[key];
          const formData = new FormData();
          formData.append('description', picture.description);
          api.patch(
            '/physicalexaminationpicture/' + key  + '/',
            formData,
          );
          return null;
        });
      }

      if(task.type === 'Digital Logbook' && 'delete_attachments' in changes) {
        Object.keys(changes.delete_attachments).map(function (key) {
          var attachment = changes.delete_attachments[key];
          if(attachment.delete === true) {
            api.delete(
              '/digitallogbookattachment/' + key  + '/',
            );
          }
          return null;
        });
      }

      if(task.type === 'Digital Logbook' && 'new_attachments' in changes) {
        Object.keys(changes.new_attachments).map(function (key) {
          var attachment = changes.new_attachments[key];
          const formData = new FormData();
          formData.append('file', attachment.image);
          formData.append('description', attachment.description);
          formData.append('task', attachment.task);
          api.post(
            '/digitallogbookattachment/',
            formData,
          );
          return null;
        });
      }

      if(task.type === 'Digital Logbook' && 'update_attachments' in changes) {
        Object.keys(changes.update_attachments).map(function (key) {
          var attachment = changes.update_attachments[key];
          const formData = new FormData();
          formData.append('description', attachment.description);
          api.patch(
            '/digitallogbookattachment/' + key  + '/',
            formData,
          )
          return null;
        });
      }

      api
        .patch('/task/' + id  + '/', params)
        .then(res => {
          if(status === 'ongoing' && vibrio_screen === 'plate1') {
            setVibrio_screen('plate2');
            hideDialogs();
          }
          else   
            navigate(-1);
        }).catch(err => {
          setErrors(err?.response?.error?.detail);
          hideDialogs();
        })
    };
    fetchData();
  }, [
    task, id, navigate, taskDoneStatus, taskDoneRelease, reassignUser,
    quantity, quantity_kg, weight, weight_kg, notes, amountGArray, amountPcsArray, transfer_all_tank,
    ammonia_nh4_mgl, nitrite_no2_mgl, nitrate_no3_mgl, phosphate_po4_mgl, do_mgl, temperature_degc, ph,
    salinity_ppt, alkalinity_caco3_mgl, turbidity, ntu, vibrio_screen,
    fm1_cumulative_flow, fm2_cumulative_flow,
    plate1_media_type, plate1_media_lot_number, plate1_media_expiration_date, plate1_inoculation_volume_ul,
    plate1_incubation_start_datetime, plate1_incubation_end_datetime, plate1_yellow_colony_counts, plate1_green_colony_counts, plate1_notes,
    plate2_media_type, plate2_media_lot_number, plate2_media_expiration_date, plate2_inoculation_volume_ul, plate2_incubation_start_datetime,
    plate2_incubation_end_datetime, plate2_yellow_colony_counts, plate2_green_colony_counts, plate2_notes,
    remove_molts, remove_mortalities, report_mortalities, mesh_filter_cleaning, siphoning, probe_cleaning, image, pieces,
    report, maintenanceChecklist, changes, batchsSelected, tanksSelected,
  ]);

  React.useEffect(() => {
    fetchTask();
  }, [fetchTask]);

  const formatLabel = (rawLabel) =>{
    let label = rawLabel.replaceAll('_', ' ');
    label = label.charAt(0).toUpperCase() + label.slice(1);
    if(rawLabel==='site_cleaning'){
        label += " (wipe off salt)";
    }
    return label;
  };

  const checkMaintenanceTask = React.useCallback((key, value) => {
    const newMaintenanceChecklist =  Object.assign({}, maintenanceChecklist);
    newMaintenanceChecklist[key] = value;
    setMaintenanceChecklist(newMaintenanceChecklist);
  }, [maintenanceChecklist]);

  const onAttachmentAdd = React.useCallback((task_id) => {
    const newChanges = {...changes};
    if(!('new_attachments' in newChanges)) {
      newChanges['new_attachments'] = [];
    }
    newChanges['new_attachments'].push({
      'task': task_id,
      'description': '',
    });
    setChanges(newChanges);
  }, [changes]);

  const onAttachmentDelete = React.useCallback((id, value) => {
    const newChanges = {...changes};
    if(!('delete_attachments' in newChanges)) {
      newChanges['delete_attachments'] = {};
    }
    newChanges['delete_attachments'][id] = {'delete': value};
    setChanges(newChanges);
  }, [changes]);

  const onNewAttachmentDelete = React.useCallback((index) => {
    const newChanges = {...changes};
    newChanges['new_attachments'].splice(index, 1); 
    setChanges(newChanges);
  }, [changes]);

  const setAttachmentDescription = React.useCallback((id, value) => {
    const newChanges = {...changes};
    if(!('update_attachments' in newChanges)) {
      newChanges['update_attachments'] = {};
    }
    newChanges['update_attachments'][id] = {'description': value};
    setChanges(newChanges);
  }, [changes]);

  const setNewAttachmentDescription = React.useCallback((id, value) => {
    const newChanges = {...changes};
    newChanges['new_attachments'][id]['description'] = value;
    setChanges(newChanges);
  }, [changes]);

  const setNewAttachmentImage = React.useCallback((id, value) => {
    const newChanges = {...changes};
    newChanges['new_attachments'][id]['image'] = value;
    setChanges(newChanges);
  }, [changes]);

  let fields = <Fragment/>;
  let fields_after = <Fragment/>;
  let fields_plate1_colony;
  let fields_plate2_colony;
  let addition;

  if(task) {
    switch(task.type) {
      case 'Feeding':
        fields = (
          <Fragment>
            {task.feeds.map((feed, index) => (
              <Fragment key={feed.id}>
                <tr>
                  <td className='align-middle'>Feed</td>
                  <td className='align-middle'>{feed.feed.display_id}</td>
                </tr>

                {feed.feed.type === 'Dry' ? (
                  <tr>
                    <td className='align-middle'>Amount (g)</td>
                    <td className='align-middle'>
                      <Form.Group as="span">
                        <Form.Control
                          type='text'
                          defaultValue={feed.amount_g}
                          onChange={e => updateAmountGArray(feed.id, e.target.value)}
                          isInvalid={errors['feeds']?.[index]}
                        />
                        <Form.Control.Feedback type='invalid'>
                          {errors['feeds']?.[index]?.['amount_g']?.[0]?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td className='align-middle'>Amount (pcs)</td>
                    <td className='align-middle'>
                      <Form.Group as="span">
                        <Form.Control
                          type='text'
                          defaultValue={feed.amount_pcs}
                          onChange={e => updateAmountPcsArray(feed.id, e.target.value)}
                          isInvalid={errors['feeds']?.[index]}
                        />
                        <Form.Control.Feedback type='invalid'>
                          {errors['feeds']?.[index]?.['amount_pcs']?.[0]?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </td>
                  </tr>
                )}
              </Fragment>
            ))}
          </Fragment>
        )
        break;
      case 'Weighing':
        fields = (
          <Fragment>
            <tr>
              <td className='align-middle'>Quantity (pcs)</td>
              <td className='align-middle'>
                <Form.Group as="span">
                  <Form.Control
                    type='text'
                    defaultValue={task.quantity_pcs}
                    onChange={e => setQuantity(e.target.value)}
                    isInvalid={errors.quantity_pcs}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.quantity_pcs?.[0]?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </td>
            </tr>
            <tr>
              <td className='align-middle'>Weight (g)</td>
              <td className='align-middle'>
                <Form.Group as="span">
                  <Form.Control
                    type='text'
                    defaultValue={task.weight_g}
                    onChange={e => setWeight(e.target.value)}
                    isInvalid={errors.weight_g}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.weight_g?.[0]?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </td>
            </tr>
            <tr>
              {task.image && !image &&
                <td>
                  <a href={task.image} target="_blank" rel="noreferrer"><Image size={16} /></a>
                </td>
              }
              <td className='align-middle' colSpan={task.image && !image ? "1" : "2"}>
                <Form.Control
                  id="image"
                  type='file'
                  accept='image/*, application/pdf'
                  onChange={event => setImage(event.target.files[0])}
                  isInvalid={errors.image}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.image?.[0]?.message}
                </Form.Control.Feedback>
              </td>
            </tr>
          </Fragment>
        )
        break;
      case 'Harvest':
        fields = (
          <Fragment>
            <tr>
              <td className='align-middle'>Quantity (kg)</td>
              <td className='align-middle'>
                <Form.Group as="span">
                  <Form.Control
                    type='text'
                    defaultValue={task.quantity_kg}
                    onChange={e => setQuantity_kg(e.target.value)}
                    isInvalid={errors.quantity_kg}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.quantity_kg?.[0]?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </td>
            </tr>
          </Fragment>
        )
        break;
      case 'Transfer':
        fields = (
          <Fragment>
            <tr>
              <td className='align-middle'>Quantity (kg)</td>
              <td className='align-middle'>
                <Form.Group as="span">
                  <Form.Control
                    type='text'
                    defaultValue={task.quantity_kg}
                    onChange={e => setQuantity_kg(e.target.value)}
                    isInvalid={errors.quantity_kg}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.quantity_kg?.[0]?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </td>
            </tr>
            <tr>
              <td className='align-middle'>Transfer all tank</td>
              <td className='align-middle'>
                <Form.Group as="span">
                  <Form.Check
                    type="checkbox"
                    checked={transfer_all_tank}
                    onChange={e => setTransfer_all_tank(e.target.checked)}
                    label=""
                  />
                </Form.Group>
              </td>
            </tr>
          </Fragment>
        )
        break;
      case 'Water Quality':
        fields = (
          <Fragment>
            <tr>
              <td className='align-middle'>Ammonia NH4 (mgL)</td>
              <td className='align-middle'>
                <Form.Group as="span">
                  <Form.Control
                    type='text'
                    defaultValue={task.ammonia_nh4_mgl}
                    onChange={e => setAmmonia_nh4_mgl(e.target.value)}
                    isInvalid={errors.ammonia_nh4_mgl}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.ammonia_nh4_mgl?.[0]?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </td>
            </tr>
            <tr>
              <td className='align-middle'>Nitrite NO2 (mgL)</td>
              <td className='align-middle'>
                <Form.Group as="span">
                  <Form.Control
                    type='text'
                    defaultValue={task.nitrite_no2_mgl}
                    onChange={e => setNitrite_no2_mgl(e.target.value)}
                    isInvalid={errors.nitrite_no2_mgl}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.nitrite_no2_mgl?.[0]?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </td>
            </tr>
            <tr>
              <td className='align-middle'>Nitrate NO3 (mgL)</td>
              <td className='align-middle'>
                <Form.Group as="span">
                  <Form.Control
                    type='text'
                    defaultValue={task.nitrate_no3_mgl}
                    onChange={e => setNitrate_no3_mgl(e.target.value)}
                    isInvalid={errors.nitrate_no3_mgl}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.nitrate_no3_mgl?.[0]?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </td>
            </tr>
            <tr>
              <td className='align-middle'>Phosphate PO4 (mgL)</td>
              <td className='align-middle'>
                <Form.Group as="span">
                  <Form.Control
                    type='text'
                    defaultValue={task.phosphate_po4_mgl}
                    onChange={e => setPhosphate_po4_mgl(e.target.value)}
                    isInvalid={errors.phosphate_po4_mgl}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.phosphate_po4_mgl?.[0]?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </td>
            </tr>
            <tr>
              <td className='align-middle'>DO (mgL)</td>
              <td className='align-middle'>
                <Form.Group as="span">
                  <Form.Control
                    type='text'
                    defaultValue={task.do_mgl}
                    onChange={e => setDo_mgl(e.target.value)}
                    isInvalid={errors.do_mgl}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.do_mgl?.[0]?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </td>
            </tr>
            <tr>
              <td className='align-middle'>Temperature (degC)</td>
              <td className='align-middle'>
                <Form.Group as="span">
                  <Form.Control
                    type='text'
                    defaultValue={task.temperature_degc}
                    onChange={e => setTemperature_degc(e.target.value)}
                    isInvalid={errors.temperature_degc}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.temperature_degc?.[0]?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </td>
            </tr>
            <tr>
              <td className='align-middle'>PH</td>
              <td className='align-middle'>
                <Form.Group as="span">
                  <Form.Control
                    type='text'
                    defaultValue={task.ph}
                    onChange={e => setPh(e.target.value)}
                    isInvalid={errors.ph}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.ph?.[0]?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </td>
            </tr>
            <tr>
              <td className='align-middle'>Salinity (ppt)</td>
              <td className='align-middle'>
                <Form.Group as="span">
                  <Form.Control
                    type='text'
                    defaultValue={task.salinity_ppt}
                    onChange={e => setSalinity_ppt(e.target.value)}
                    isInvalid={errors.salinity_ppt}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.salinity_ppt?.[0]?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </td>
            </tr>
            <tr>
              <td className='align-middle'>Alkalinity CaCO3 (mgL)</td>
              <td className='align-middle'>
                <Form.Group as="span">
                  <Form.Control
                    type='text'
                    defaultValue={task.alkalinity_caco3_mgl}
                    onChange={e => setAlkalinity_caco3_mgl(e.target.value)}
                    isInvalid={errors.alkalinity_caco3_mgl}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.alkalinity_caco3_mgl?.[0]?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </td>
            </tr>
            <tr>
              <td className='align-middle'>Turbidity (750A°)</td>
              <td className='align-middle'>
                <Form.Group as="span">
                  <Form.Control
                    type='text'
                    defaultValue={task.turbidity}
                    onChange={e => onTurbidityChanged(e.target.value)}
                    isInvalid={errors.turbidity}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.turbidity?.[0]?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </td>
            </tr>
            <tr>
              <td className='align-middle'>NTU</td>
              <td className='align-middle'>{ntu}</td>
            </tr>
          </Fragment>
        )
        break;
      case 'Water Flow':
        fields = (
          <Fragment>
            <tr>
              <td className='align-middle'>FM1 Cumulative Flow (m3)</td>
              <td className='align-middle'>
                <Form.Group as="span">
                  <Form.Control
                    type='text'
                    defaultValue={task.fm1_cumulative_flow}
                    onChange={e => setFm1_cumulative_flow(e.target.value)}
                    isInvalid={errors.fm1_cumulative_flow}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.fm1_cumulative_flow?.[0]?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </td>
            </tr>
            <tr>
              <td className='align-middle'>FM2 Cumulative Flow (m3)</td>
              <td className='align-middle'>
                <Form.Group as="span">
                  <Form.Control
                    type='text'
                    defaultValue={task.fm2_cumulative_flow}
                    onChange={e => setFm2_cumulative_flow(e.target.value)}
                    isInvalid={errors.fm2_cumulative_flow}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.fm2_cumulative_flow?.[0]?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </td>
            </tr>
          </Fragment>
        )
        break;

      case 'Vibrio Testing':
        fields = (
          <>
            <tr>
              <td className='align-middle'>Location</td>
              <td className='align-middle'>{task.location_display}</td>
            </tr>
            <tr>
              {task.image && !image &&
                <td>
                  <a href={task.image} target="_blank" rel="noreferrer"><Image size={16} /></a>
                </td>
              }
              <td className='align-middle' colSpan={task.image && !image ? "1" : "2"}>
                <Form.Control
                  id="image"
                  type='file'
                  accept='image/*, application/pdf'
                  onChange={event => setImage(event.target.files[0])}
                  isInvalid={errors.image}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.image?.[0]?.message}
                </Form.Control.Feedback>
              </td>
            </tr>
          </>
        )
        fields_plate1_colony = (
          <Fragment>
            <tr>
              <td className='align-middle'>Media Type</td>
              <td className='align-middle'>
                <Form.Group as="span">
                  <Form.Control
                    as="select"
                    onChange={e => setPlate1_media_type(e.target.value)}
                    isInvalid={errors.plate1_media_type}
                    defaultValue={task.plate1_media_type}
                  >
                    <option value="0">Select...</option>
                    {mediatypes &&
                      mediatypes.map((mediatype) => (
                        <option key={mediatype.id} value={mediatype.id}>{mediatype.name}</option>
                      ))
                    }
                  </Form.Control>
                  <Form.Control.Feedback type='invalid'>
                    Please, select media type.
                  </Form.Control.Feedback>
                </Form.Group>
              </td>
              <td style={{'paddingLeft': '0px', 'paddingRight': '0px', 'cursor': 'pointer'}} onClick={() => onMediaTypeAdd()}>
                <Plus size={40} as="span" />
              </td>
            </tr>
            <tr>
              <td className='align-middle'>Media Lot Number</td>
              <td className='align-middle' colSpan="2">
                <Form.Group as="span">
                  <Form.Control
                    type='text'
                    defaultValue={task.plate1_media_lot_number}
                    onChange={e => setPlate1_media_lot_number(e.target.value)}
                    isInvalid={errors.plate1_media_lot_number}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.plate1_media_lot_number?.[0]?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </td>
            </tr>
            <tr>
              <td className='align-middle'>Media Expiration Date</td>
              <td className='align-middle' colSpan="2">
                <Form.Group as="span">
                  <Form.Control
                    type='date'
                    defaultValue={task.plate1_media_expiration_date}
                    onChange={e => setPlate1_media_expiration_date(e.target.value)}
                    isInvalid={errors.plate1_media_expiration_date}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.plate1_media_expiration_date?.[0]?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </td>
            </tr>
            <tr>
              <td className='align-middle'>Inoculation Volume uL</td>
              <td className='align-middle' colSpan="2">
                <Form.Group as="span">
                  <Form.Control
                    type='text'
                    defaultValue={task.plate1_inoculation_volume_ul}
                    onChange={e => setPlate1_inoculation_volume_ul(e.target.value)}
                    isInvalid={errors.plate1_inoculation_volume_ul}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.plate1_inoculation_volume_ul?.[0]?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </td>
            </tr>
            <tr>
              <td className='align-middle'>Media Incubation Start DateTime</td>
              <td className='align-middle' colSpan="2">
                <Form.Group as="span">
                  <Form.Control
                    type='datetime-local'
                    defaultValue={task.plate1_incubation_start_datetime ? task.plate1_incubation_start_datetime.slice(0, -6) : '' }
                    onChange={e => setPlate1_incubation_start_datetime(e.target.value)}
                    isInvalid={errors.plate1_incubation_start_datetime}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.plate1_incubation_start_datetime?.[0]?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </td>
            </tr>
            <tr>
              <td className='align-middle'>Media Incubation End DateTime</td>
              <td className='align-middle' colSpan="2">
                <Form.Group as="span">
                  <Form.Control
                    type='datetime-local'
                    defaultValue={task.plate1_incubation_end_datetime ? task.plate1_incubation_end_datetime.slice(0, -6) : '' }
                    onChange={e => setPlate1_incubation_end_datetime(e.target.value)}
                    isInvalid={errors.plate1_incubation_end_datetime}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.plate1_incubation_end_datetime?.[0]?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </td>
            </tr>
            <tr>
              <td className='align-middle'>Yellow Colony Counts</td>
              <td className='align-middle' style={{paddingBottom: '0px'}} colSpan="2">
                <Form.Group as="span">
                  <Form.Control
                    type='text'
                    value={plate1_yellow_colony_counts ? plate1_yellow_colony_counts : '' }
                    onChange={e => setPlate1_yellow_colony_counts(e.target.value)}
                    isInvalid={errors.plate1_yellow_colony_counts}
                    disabled={plate1_yellow_colony_counts_disabled}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.plate1_yellow_colony_counts?.[0]?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group style={{marginBottom: '0px'}}>
                  <Form.Check
                    type="checkbox"
                    label="Too many"
                    onChange={e => setPlate1_yellow_colony_counts_many(e.target.checked)}
                  />
                </Form.Group>
              </td>
            </tr>
            <tr>
              <td className='align-middle'>Green Colony Counts</td>
              <td className='align-middle' style={{paddingBottom: '0px'}} colSpan="2">
                <Form.Group as="span">
                  <Form.Control
                    type='text'
                    value={plate1_green_colony_counts ? plate1_green_colony_counts : '' }
                    onChange={e => setPlate1_green_colony_counts(e.target.value)}
                    isInvalid={errors.plate1_green_colony_counts}
                    disabled={plate1_green_colony_counts_disabled}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.plate1_green_colony_counts?.[0]?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group style={{marginBottom: '0px'}}>
                  <Form.Check
                    type="checkbox"
                    label="Too many"
                    onChange={e => setPlate1_green_colony_counts_many(e.target.checked)}
                  />
                </Form.Group>
              </td>
            </tr>
            <tr>
              <td className='align-middle'>Notes</td>
              <td className='align-middle' colSpan="2">
                <Form.Group as="span">
                  <Form.Control
                    type='text'
                    defaultValue={task.plate1_notes}
                    onChange={e => setPlate1_notes(e.target.value)}
                    isInvalid={errors.plate1_notes}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.plate1_notes?.[0]?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </td>
            </tr>
          </Fragment>
        )

        fields_plate2_colony = (
          <Fragment>
            <tr>
              <td className='align-middle'>Media Type</td>
              <td className='align-middle' colSpan="2">
                <Form.Group as="span">
                  <Form.Control 
                    as="select"
                    onChange={e => setPlate2_media_type(e.target.value)}
                    isInvalid={errors.plate2_media_type}
                    defaultValue={task.plate2_media_type}
                  >
                    <option value="0">Select...</option>
                    {mediatypes &&
                      mediatypes.map((mediatype) => (
                        <option key={mediatype.id} value={mediatype.id} >{mediatype.name}</option>
                      ))
                    }
                  </Form.Control>
                  <Form.Control.Feedback type='invalid'>
                    Please, select media type.
                  </Form.Control.Feedback>
                </Form.Group>
              </td>
            </tr>
            <tr>
              <td className='align-middle'>Media Lot Number</td>
              <td className='align-middle' colSpan="2">
                <Form.Group as="span">
                  <Form.Control
                    type='text'
                    defaultValue={task.plate2_media_lot_number}
                    onChange={e => setPlate2_media_lot_number(e.target.value)}
                    isInvalid={errors.plate2_media_lot_number}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.plate2_media_lot_number?.[0]?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </td>
            </tr>
            <tr>
              <td className='align-middle'>Media Expiration Date</td>
              <td className='align-middle' colSpan="2">
                <Form.Group as="span">
                  <Form.Control
                    type='date'
                    defaultValue={task.plate2_media_expiration_date}
                    onChange={e => setPlate2_media_expiration_date(e.target.value)}
                    isInvalid={errors.plate2_media_expiration_date}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.plate2_media_expiration_date?.[0]?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </td>
            </tr>
            <tr>
              <td className='align-middle'>Inoculation Volume uL</td>
              <td className='align-middle' colSpan="2">
                <Form.Group as="span">
                  <Form.Control
                    type='text'
                    defaultValue={task.plate2_inoculation_volume_ul}
                    onChange={e => setPlate2_inoculation_volume_ul(e.target.value)}
                    isInvalid={errors.plate2_inoculation_volume_ul}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.plate2_inoculation_volume_ul?.[0]?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </td>
            </tr>
            <tr>
              <td className='align-middle'>Media Incubation Start DateTime</td>
              <td className='align-middle' colSpan="2">
                <Form.Group as="span">
                  <Form.Control
                    type='datetime-local'
                    defaultValue={task.plate2_incubation_start_datetime ? task.plate2_incubation_start_datetime.slice(0, -6) : '' }
                    onChange={e => setPlate2_incubation_start_datetime(e.target.value)}
                    isInvalid={errors.plate2_incubation_start_datetime}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.plate2_incubation_start_datetime?.[0]?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </td>
            </tr>
            <tr>
              <td className='align-middle'>Media Incubation End DateTime</td>
              <td className='align-middle' colSpan="2">
                <Form.Group as="span">
                  <Form.Control
                    type='datetime-local'
                    defaultValue={task.plate2_incubation_end_datetime ? task.plate2_incubation_end_datetime.slice(0, -6) : '' }
                    onChange={e => setPlate2_incubation_end_datetime(e.target.value)}
                    isInvalid={errors.plate2_incubation_end_datetime}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.plate2_incubation_end_datetime?.[0]?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </td>
            </tr>
            <tr>
              <td className='align-middle'>Yellow Colony Counts</td>
              <td className='align-middle' colSpan="2">
                <Form.Group as="span">
                  <Form.Control
                    type='text'
                    value={plate2_yellow_colony_counts ? plate2_yellow_colony_counts : ''}
                    onChange={e => setPlate2_yellow_colony_counts(e.target.value)}
                    isInvalid={errors.plate2_yellow_colony_counts}
                    disabled={plate2_yellow_colony_counts_disabled}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.plate2_yellow_colony_counts?.[0]?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group style={{marginBottom: '0px'}}>
                  <Form.Check
                    type="checkbox"
                    label="Too many"
                    onChange={e => setPlate2_yellow_colony_counts_many(e.target.checked)}
                  />
                </Form.Group>
              </td>
            </tr>
            <tr>
              <td className='align-middle'>Green Colony Counts</td>
              <td className='align-middle' colSpan="2">
                <Form.Group as="span">
                  <Form.Control
                    type='text'
                    value={plate2_green_colony_counts ? plate2_green_colony_counts : ''}
                    onChange={e => setPlate2_green_colony_counts(e.target.value)}
                    isInvalid={errors.plate2_green_colony_counts}
                    disabled={plate2_green_colony_counts_disabled}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.plate2_green_colony_counts?.[0]?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group style={{marginBottom: '0px'}}>
                  <Form.Check
                    type="checkbox"
                    label="Too many"
                    onChange={e => setPlate2_green_colony_counts_many(e.target.checked)}
                  />
                </Form.Group>
              </td>
            </tr>
            <tr>
              <td className='align-middle'>Notes</td>
              <td className='align-middle' colSpan="2">
                <Form.Group as="span">
                  <Form.Control
                    type='text'
                    defaultValue={task.plate2_notes}
                    onChange={e => setPlate2_notes(e.target.value)}
                    isInvalid={errors.plate2_notes}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.plate2_notes?.[0]?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </td>
            </tr>
          </Fragment>
        )
        break;
      case 'Individual Measurement':
        fields = (
          <Fragment>
            <tr>
              <td className='align-middle'>Number of pieces</td>
              <td className='align-middle'>{task.number_of_pieces}</td>
            </tr>
            <tr>
              <td className='align-middle'>Total weight (g)</td>
              <td className='align-middle'>{task.total_weight}</td>
            </tr>
            <tr>
              <td className='align-middle'>Average weight (g)</td>
              <td className='align-middle'>{task.average_weight}</td>
            </tr>
            <tr>
              <td className='align-middle'>Standard deviation (g)</td>
              <td className='align-middle'>{task.std_weight}</td>
            </tr>
          </Fragment>
        )

        let rows = pieces.map((piece, index) => (
          <tr key={index}>
            <td className='align-middle'>#{index+1}</td>
            <td className='align-middle'>
              <Form.Group as="span">
                <Form.Control
                  type='text'
                  value={piece.weight || ''}
                  onChange={e => updatePiecesWeight(index, e.target.value)}
                  isInvalid={errors['pieces']?.[index]?.['weight']}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors['pieces']?.[index]?.['weight']?.[0]?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </td>
            <td className='align-middle'>
              <Form.Group as="span">
                <Form.Control
                  type='text'
                  value={piece.length || ''}
                  onChange={e => updatePiecesLength(index, e.target.value)}
                  isInvalid={errors['pieces']?.[index]?.['length']}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors['pieces']?.[index]?.['length']?.[0]?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </td>
            <td>
              <X size={40} as="span" onClick={() => deletePiece(index)} style={{'cursor': 'pointer'}}/>
            </td>
          </tr>
        ))

        addition = (
          <Fragment>
            <Row>
              <Col className='text-center'>
                <Table bordered>
                  <thead>
                    <tr>
                      <th>Sample</th>
                      <th>Weight (g)</th>
                      <th>Length (cm)</th>
                      <th>Delete?</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows}
                  </tbody>
                </Table>
                <p className="text-danger">{errors['pieces_completed']?.message}</p>
              </Col>
            </Row>
            <Row className='pb-3'>
              <Col>
                <span style={{cursor: 'pointer'}} onClick={() => addPiece()}><Plus size={40} as="span" className="text-success" /><span>Add another piece</span></span>
              </Col>
            </Row>
          </Fragment>
        )

        break;

      case 'Tank Husbandry':
        fields = (
          <Fragment>
            <tr>
              <td className='align-middle'>Remove molts</td>
              <td className='align-middle'>
                <Form.Control
                  as="select"
                  defaultValue={task.remove_molts}
                  onChange={e => setRemove_molts(e.target.value)}
                >
                  {
                    tankHusbandryCompletedChoices.map((tankHusbandryCompletedChoice) => (
                      <option key={tankHusbandryCompletedChoice[0]} value={tankHusbandryCompletedChoice[0]}>{tankHusbandryCompletedChoice[1]}</option>
                    ))
                  }
                </Form.Control>
              </td>
            </tr>
            <tr>
              <td className='align-middle'>Remove mortalities</td>
              <td className='align-middle'>
                <Form.Control
                  as="select"
                  defaultValue={task.remove_mortalities}
                  onChange={e => setRemove_mortalities(e.target.value)}
                >
                  {
                    tankHusbandryCompletedChoices.map((tankHusbandryCompletedChoice) => (
                      <option key={tankHusbandryCompletedChoice[0]} value={tankHusbandryCompletedChoice[0]}>{tankHusbandryCompletedChoice[1]}</option>
                    ))
                  }
                </Form.Control>
              </td>
            </tr>
            <tr>
              <td className='align-middle'>Report mortalities</td>
              <td className='align-middle'>
                <Form.Control
                  as="select"
                  defaultValue={task.report_mortalities}
                  onChange={e => setReport_mortalities(e.target.value)}
                >
                  {
                    tankHusbandryCompletedChoices.map((tankHusbandryCompletedChoice) => (
                      <option key={tankHusbandryCompletedChoice[0]} value={tankHusbandryCompletedChoice[0]}>{tankHusbandryCompletedChoice[1]}</option>
                    ))
                  }
                </Form.Control>
              </td>
            </tr>
            <tr>
              <td className='align-middle'>Mesh filter cleaning</td>
              <td className='align-middle'>
                <Form.Control
                  as="select"
                  defaultValue={task.mesh_filter_cleaning}
                  onChange={e => setMesh_filter_cleaning(e.target.value)}
                >
                  {
                    tankHusbandryCompletedChoices.map((tankHusbandryCompletedChoice) => (
                      <option key={tankHusbandryCompletedChoice[0]} value={tankHusbandryCompletedChoice[0]}>{tankHusbandryCompletedChoice[1]}</option>
                    ))
                  }
                </Form.Control>
              </td>
            </tr>
            <tr>
              <td className='align-middle'>Siphoning</td>
              <td className='align-middle'>
                <Form.Control
                  as="select"
                  defaultValue={task.siphoning}
                  onChange={e => setSiphoning(e.target.value)}
                >
                  {
                    tankHusbandryCompletedChoices.map((tankHusbandryCompletedChoice) => (
                      <option key={tankHusbandryCompletedChoice[0]} value={tankHusbandryCompletedChoice[0]}>{tankHusbandryCompletedChoice[1]}</option>
                    ))
                  }
                </Form.Control>
              </td>
            </tr>
            <tr>
              <td className='align-middle'>Probe cleaning</td>
              <td className='align-middle'>
                <Form.Control
                  as="select"
                  defaultValue={task.probe_cleaning}
                  onChange={e => setProbe_cleaning(e.target.value)}
                >
                  {
                    tankHusbandryCompletedChoices.map((tankHusbandryCompletedChoice) => (
                      <option key={tankHusbandryCompletedChoice[0]} value={tankHusbandryCompletedChoice[0]}>{tankHusbandryCompletedChoice[1]}</option>
                    ))
                  }
                </Form.Control>
              </td>
            </tr>
          </Fragment>
        )
        break;
      case 'Physical Examination':
        fields_after = (
        <PhysicalExaminationTaskComponent task={task} errors={errors}
                                          setImage={setImage} image={image}
                                          changes={changes} setChanges={setChanges}/>
        )
        break;
      case 'Seaweed Weighing':
        fields = (
          <Fragment>
            <tr>
              <td className='align-middle'>Tray ID</td>
              <td className='align-middle'>{task.tray_display}</td>
            </tr>
            <tr>
              <td className='align-middle'>Weight (kg)</td>
              <td className='align-middle'>
                <Form.Group as="span">
                  <Form.Control
                    type='text'
                    defaultValue={task.weight_kg}
                    onChange={e => setWeight(e.target.value)}
                    isInvalid={errors.weight_kg}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.weight_kg?.[0]?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </td>
            </tr>
          </Fragment>
        )
        break;
      case 'Daily Maintenance':
        fields = (
          <Fragment>
          {Object.entries(maintenanceChecklist).map(([key, value]) => {
            return(
                <tr key={key}>
                  <td className='align-middle'>{formatLabel(key)}</td>
                  <td className='align-middle'>
                    <Form.Group as="span">
                      <Form.Check
                        type="checkbox"
                        checked={value}
                        onChange={e => checkMaintenanceTask(key, e.target.checked)}
                        label=""
                      />
                    </Form.Group>
                  </td>
                </tr>
            )})}
          </Fragment>
        )
        break;
      case 'Feed Consumption':
        fields = (
          <Fragment>
            <tr>
              <td className='align-middle'>Feed</td>
              <td className='align-middle'>{task.feed_display}</td>
            </tr>
            <tr>
              <td className='align-middle'>Unit(s)</td>
              <td className='align-middle'>{task.unit}</td>
            </tr>
            <tr>
              <td className='align-middle'>Amount (kg)</td>
              <td className='align-middle'>{task.amount_kg}</td>
            </tr>
          </Fragment>
        )
        break;
      case 'Digital Logbook':
        fields = (
          <Fragment>
            <tr>
              <td className='align-middle'>Location</td>
              <td className='align-middle'>{task.location_display}</td>
            </tr>
            <tr>
              <td className='align-middle'>Tanks</td>
              <td className='align-middle'>
                {tankOptions &&
                  <DropdownMultiselect
                    options={tankOptions}
                    selected={tanksSelected}
                    name='tanks'
                    placeholder='Select tanks...'
                    handleOnChange={(selectedTanks) => {
                     onTanksSelected(selectedTanks);
                    }}
                  />
                }
              </td>
            </tr>
            <tr>
              <td className='align-middle'>Batches</td>
              <td className='align-middle'>
                {batchOptions &&
                  <DropdownMultiselect
                    options={batchOptions}
                    selected={batchsSelected}
                    name='tanks'
                    placeholder='Select batches...'
                    handleOnChange={(selectedBatchs) => {
                     onBatchsSelected(selectedBatchs);
                    }}
                  />
                }
              </td>
            </tr>
          </Fragment>
        )
        fields_after = (
          <Fragment>
            <tr>
              <td
                style={{backgroundColor: "LightSlateGray", color: "white"}}
                className='align-middle'
                colSpan="2"
              >
                <span>Attachments</span>
                <span className="float-end" style={{'paddingLeft': '0px', 'paddingRight': '0px', 'cursor': 'pointer'}} onClick={() => onAttachmentAdd(task.id)}>
                  <Plus size={30} as="span" />
                </span>
              </td>
            </tr>
            <tr>
              <td colSpan="2" style={{'padding': '0px'}} >
                <Table bordered className='mb-0'>
                  <thead>
                    <tr>
                      <th>File</th>
                      <th>Description</th>
                      <th>Delete?</th>
                    </tr>
                  </thead>
                  <tbody>
                    {task.attachments.map(attachment => (
                      <tr key={attachment.id}>
                        <td className='align-middle'>{ attachment.file &&
                          <a href={attachment.file} target="_blank" rel="noreferrer"><Image size={16} /></a>
                        }
                        </td>
                        <td className='align-middle'>
                          <Form.Group as="span">
                            <Form.Control
                              type='text'
                              defaultValue={attachment.description}
                              onChange={e => setAttachmentDescription(attachment.id, e.target.value)}
                              isInvalid={errors.update_attachments?.[attachment.id]?.description}
                            />
                            <Form.Control.Feedback type='invalid'>
                              {errors.update_attachments?.[attachment.id]?.description?.[0]?.message}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </td>
                        <td className='align-middle'>
                          <Form.Group as="span">
                            <Form.Check
                              type="checkbox"
                              onChange={e => onAttachmentDelete(attachment.id, e.target.checked)}
                              label=""
                            />
                          </Form.Group>
                        </td>
                      </tr>
                    ))}
                    {changes.new_attachments && changes.new_attachments.map((attachment, index) => (
                      <tr key={index}>

                        <td>
                          <Form.Control
                            type='file'
                            accept='image/*, application/pdf'
                            onChange={event => setNewAttachmentImage(index, event.target.files[0])}
                            isInvalid={errors.new_attachments?.[index]?.image}
                          />
                          <Form.Control.Feedback type='invalid'>
                            {errors.new_attachments?.[index]?.image?.[0]?.message}
                          </Form.Control.Feedback>
                        </td>
                        <td>
                          <Form.Group as="span">
                            <Form.Control
                              type='text'
                              value={attachment['description']}
                              onChange={e => setNewAttachmentDescription(index, e.target.value)}
                              isInvalid={errors.new_attachments?.[index]?.description}
                            />
                            <Form.Control.Feedback type='invalid'>
                              {errors.new_attachments?.[index]?.description?.[0]?.message}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </td>
                        <td>
                          <X size={40} as="span" onClick={() => onNewAttachmentDelete(index)} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </td>
            </tr>
          </Fragment>
        )
        break;
      case 'End Of Day Report':
        fields = (
          <Fragment>
            <tr>
              <td className='align-middle'>Location</td>
              <td className='align-middle'>{task.location_display}</td>
            </tr>
            <tr>
              <td className='align-middle'>Report</td>
              <td className='align-middle'>
                <Editor
                  initialValue={task.report}
                  onEditorChange={(newValue) => setReport(newValue)}
                  init={{
                    width: '100%',
                    height: 500,
                    menubar: false,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar: 'undo redo | formatselect | ' +
                     'bold italic underline backcolor | alignleft aligncenter ' +
                     'alignright alignjustify | bullist numlist outdent indent | ' +
                     'removeformat | help',
	                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                  }}
                  tinymceScriptSrc = {process.env.REACT_APP_BACKEND_URL.slice(0, -5) + '/static/tinymce/tinymce.min.js'}
                />
              </td>
            </tr>
          </Fragment>
        )
        break;
      case 'Uneaten Feed':
        fields = (
          <Fragment>
            <tr>
              <td className='align-middle'>Uneaten Amount</td>
              <td className='align-middle'>
                <Form.Group as="span">
                  <Form.Control
                    as="select"
                    onChange={e => { task.uneaten_amount = e.target.value }}
                    isInvalid={errors.uneaten_amount}
                    defaultValue={task.uneaten_amount}
                  >
                    <option value="">Select...</option>
                    {task.uneaten_amount_choices &&
                      task.uneaten_amount_choices.map((choice) => (
                        <option key={choice.value} value={choice.value}>{choice.display_name}</option>
                      ))
                    }
                  </Form.Control>
                  <Form.Control.Feedback type='invalid'>
                    Please select Uneaten Amount.
                  </Form.Control.Feedback>
                </Form.Group>
              </td>
            </tr>
          </Fragment>
        )
        break;
      default:
    }
  }

  return (
    <Fragment>
      { task &&
      <Fragment>
      <Form noValidate>
        <Row>
          <Col className='text-center'>
            <h3>{task.type_display} Task</h3>
          </Col>
        </Row>

        {task.type !== 'Vibrio Testing' &&

        <Row className='py-3'>
          <Col className='text-center'>
            <Table bordered>
              <tbody>
                <tr>
                  <td className='align-middle'>Status</td>
                  <td className='align-middle'>{task.status_display}</td>
                </tr>
                <tr>
                  <td className='align-middle'>Date</td>
                  <td className='align-middle'>{task.date_display}</td>
                </tr>
                <tr>
                  <td className='align-middle'>Time</td>
                  <td className='align-middle'>{task.time}</td>
                </tr>
                {task.type === 'Transfer' &&
                <Fragment>
                <tr>
                  <td className='align-middle'>From Tank ID</td>
                  <td className='align-middle'>{task.from_tank.display_id}</td>
                </tr>
                <tr>
                  <td className='align-middle'>To Tank ID</td>
                  <td className='align-middle'>{task.tank_display}</td>
                </tr>
                </Fragment>
                }
                {task.type === 'Seaweed Transfer' &&
                <Fragment>
                <tr>
                  <td className='align-middle'>Tray ID</td>
                  <td className='align-middle'>{task.tray_display}</td>
                </tr>
                <tr>
                  <td className='align-middle'>Current Tank ID</td>
                  <td className='align-middle'>{task.from_tank?.display_id}</td>
                </tr>
                <tr>
                  <td className='align-middle'>Destination Tank ID</td>
                  <td className='align-middle'>{task.tank?.display_id}</td>
                </tr>
                </Fragment>
                }
                {task.type === 'Seaweed Merging' &&
                <Fragment>
                {task.trays_set.map((tray, index) => (
                  <Fragment>
                    <tr>
                      <td className='align-middle'>Donating Tray ID #{index + 1}</td>
                      <td className='align-middle'>{tray.tray}</td>
                    </tr>
                    <tr>
                      <td className='align-middle'>Tank of Donation Tray #{index + 1}</td>
                      <td className='align-middle'>{tray.tank}</td>
                    </tr>
                  </Fragment>
                ))}
                <tr>
                  <td className='align-middle'>New Tray ID</td>
                  <td className='align-middle'>{task.new_tray_id}</td>
                </tr>
                {task.new_tray_id !== task.real_new_tray_id &&
                <tr>
                  <td className='align-middle' colspan='2' style={{color: 'red'}}>
                    Warning: Another Tray with this ID was created after this task was scheduled. The new Tray ID has been changed to: "{task.real_new_tray_id}"
                  </td>
                </tr>
                }
                <tr>
                  <td className='align-middle'>State of New Tray</td>
                  <td className='align-middle'>{task.new_state}</td>
                </tr>
                <tr>
                  <td className='align-middle'>Tank ID of New Tray</td>
                  <td className='align-middle'>{task.tank?.display_id}</td>
                </tr>
                <tr>
                  <td className='align-middle'>Weight (Kg) of New Tray</td>
                  <td className='align-middle'>
                    <Form.Group as="span">
                      <Form.Control
                        type='text'
                        defaultValue={task.weight}
                        onChange={e => setWeight(e.target.value)}
                        isInvalid={errors.weight}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.weight?.[0]?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </td>
                </tr>
                </Fragment>
                }
                {task.type === 'Seaweed Trim Multiple' &&
                <Fragment>
                {task.trays_set.map((tray, index) => (
                  <Fragment>
                    <tr>
                      <td className='align-middle'>Donating Tray ID #{index + 1}</td>
                      <td className='align-middle'>{tray.tray}</td>
                    </tr>
                    <tr>
                      <td className='align-middle'>Tank of Donation Tray #{index + 1}</td>
                      <td className='align-middle'>{tray.tank}</td>
                    </tr>
                  </Fragment>
                ))}
                <tr>
                  <td className='align-middle'>New Tray ID</td>
                  <td className='align-middle'>{task.new_tray_id}</td>
                </tr>
                {task.new_tray_id !== task.real_new_tray_id &&
                <tr>
                  <td className='align-middle' colspan='2' style={{color: 'red'}}>
                    Warning: Another Tray with this ID was created after this task was scheduled. The new Tray ID has been changed to: "{task.real_new_tray_id}"
                  </td>
                </tr>
                }
                <tr>
                  <td className='align-middle'>State of New Tray</td>
                  <td className='align-middle'>{task.new_state}</td>
                </tr>
                <tr>
                  <td className='align-middle'>Tank ID of New Tray</td>
                  <td className='align-middle'>{task.tank?.display_id}</td>
                </tr>
                <tr>
                  <td className='align-middle'>Weight (Kg) of New Tray</td>
                  <td className='align-middle'>
                    <Form.Group as="span">
                      <Form.Control
                        type='text'
                        defaultValue={task.weight}
                        onChange={e => setWeight(e.target.value)}
                        isInvalid={errors.weight}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.weight?.[0]?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </td>
                </tr>

                </Fragment>
                }
                {task.type !== 'Transfer' && task.type !== 'Seaweed Transfer' && task.type !== 'Seaweed Merging' && task.type !== 'Seaweed Trim Multiple' &&
                 task.type !== 'Daily Maintenance' && task.type !== 'Feed Consumption' && task.type !== 'Digital Logbook' && task.type !== 'End Of Day Report' &&
                <tr>
                  <td className='align-middle'>Tank ID</td>
                  <td className='align-middle'>{task.tank_display}</td>
                </tr>
                }
                <Fragment>
                  {fields}
                </Fragment>
                {task.type !== 'End Of Day Report' &&
                <tr>
                  <td className='align-middle'>Notes</td>
                  <td className='align-middle'>
                    <Form.Control
                      type='text'
                      defaultValue={task.notes}
                      onChange={e => setNotes(e.target.value)}
                    />
                  </td>
                </tr>
                }
                <Fragment>
                  {fields_after}
                </Fragment>
              </tbody>
            </Table>
          </Col>
        </Row>

        }

        {task.type === 'Vibrio Testing' &&

        <Fragment>

        <Row className='py-3'>
          <Col className='text-center'>
            <Table bordered>
              <tbody>
                <tr>
                  <td className='align-middle'>Status</td>
                  <td className='align-middle'>{task.status_display}</td>
                </tr>
                <tr>
                  <td className='align-middle'>Date</td>
                  <td className='align-middle'>{task.date_display}</td>
                </tr>
                <tr>
                  <td className='align-middle'>Time</td>
                  <td className='align-middle'>{task.time}</td>
                </tr>
                <tr>
                  <td className='align-middle'>Tank ID</td>
                  <td className='align-middle'>{task.tank_display}</td>
                </tr>
                <Fragment>
                  {fields}
                </Fragment>
              </tbody>
            </Table>
          </Col>
        </Row>
        {vibrio_screen === 'plate1' &&
        <Fragment>
        <Row>
          <Col className='text-center'>
            <h3>Plate 1</h3>
          </Col>
        </Row>
        <Row className='py-3'>
          <Col className='text-center'>
            <Table bordered>
              <tbody>
                <Fragment>
                  {fields_plate1_colony}
                </Fragment>
              </tbody>
            </Table>
          </Col>
        </Row>
        </Fragment>
        }
        {vibrio_screen === 'plate2' &&
        <Fragment>
        <Row>
          <Col className='text-center'>
            <h3>Plate 2</h3>
          </Col>
        </Row>
        <Row className='py-3'>
          <Col className='text-center'>
            <Table bordered>
              <tbody>
                <Fragment>
                  {fields_plate2_colony}
                </Fragment>
              </tbody>
            </Table>
          </Col>
        </Row>
        </Fragment>
        }
        </Fragment>
        }

        {task.type === 'Individual Measurement' &&
          <Fragment>
            {addition}
          </Fragment>
        }

        <Row>
          <Col>
            <Button variant='secondary' style={{'width':'100px'}} onClick={() => navigate(-1)}>Back</Button>
            <span className='float-end'>
              <Container>
                <Row className='mx-0 px-0'>
                  {task.type === 'Vibrio Testing' &&
                  <div className='col-12 mx-0 px-0 pb-2'>
                    <Button
                      className='mx-0 px-0'
                      variant='primary'
                      style={{'width':'100%'}}
                      onClick={() => onTaskPartialPressed('ongoing')}
                    >Submit Partial</Button>
                  </div>
                  }
                  <div className='col-6 mx-0 ps-0 pe-2 pb-2'>
                    <Button
                      className='mx-0 px-0'
                      disabled={task?.status === 'complete'}
                      variant='warning'
                      style={{'width':'100%'}}
                      onClick={() => onTaskSkipPressed('skipped')}
                    >Skip</Button>
                  </div>
                  {(task.type !== 'Vibrio Testing' || vibrio_screen === 'plate2') &&
                  <div className='col-6 mx-0 px-0'>
                    <Button
                      className='mx-0 px-0'
                      variant='success'
                      style={{'width':'100%'}}
                      onClick={() => onTaskDonePressed('complete')}
                    >Done</Button>
                  </div>
                  }
                  <div className='col mx-0 px-0'>
                    <Button
                      className='mx-0 px-0'
                      disabled={task?.status === 'complete'}
                      variant='info'
                      style={{'width':'100%'}}
                      onClick={() => onTaskReleasePressed(null, true)}
                    >Release task</Button>
                  </div>
                </Row>
              </Container>
            </span>
          </Col>
        </Row>
      </Form>
      </Fragment>
      }

      <Modal
        show={doneDialogShow}
      >
        <Modal.Header closeButton>
          <Modal.Title>Is this task completed?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setDoneDialogShow(false)}>No</Button>
          <Button variant='primary' onClick={() => processTask()}>Yes</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={skipDialogShow}
      >
        <Modal.Header closeButton>
          <Modal.Title>Skip this task? This task will be skipped. This task will remain assigned to you.</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setSkipDialogShow(false)}>No</Button>
          <Button variant='primary' onClick={() => processTask()}>Yes</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={partialDialogShow}
      >
        <Modal.Header closeButton>
          <Modal.Title>Submit partial?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setPartialDialogShow(false)}>No</Button>
          <Button variant='primary' onClick={() => processTask()}>Yes</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={releaseDialogShow}
      >
        <Modal.Header closeButton>
          <Modal.Title>Release this task? The task will no longer have an owner.</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setReleaseDialogShow(false)}>No</Button>
          <Button variant='primary' onClick={() => processTask()}>Yes</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={releaseAdminDialogShow}
      >
        <Modal.Header closeButton>
          <Modal.Title>Release this task?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setReleaseAdminDialogShow(false)}>No</Button>
          <Button variant='primary' onClick={() => onTaskReleaseAdminPressed()}>Yes</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={releaseAdminAssignDialogShow}
      >
        <Modal.Header closeButton>
          <Modal.Title>Assign this task to someone else?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => processTask()}>No</Button>
          <Button variant='primary' onClick={() => onTaskAssignYesPressed()}>Yes</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={releaseAdminReassignDialogShow}
      >
        <Modal.Header closeButton>
          <Modal.Title>Reassign to user</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            as="select"
            onChange={e => setReassignUser(e.target.value)}
          >
            <option value="0">Select user...</option>
            {users &&
              users.map((user) => (
                <option key={user.id} value={user.id}>{user.email}</option>
              ))
            }

          </Form.Control>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setReleaseAdminReassignDialogShow(false)}>No</Button>
          <Button variant='primary' onClick={() => processTask()}>Yes</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={addMediaTypeDialogShow}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Meda Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control 
            type='text'
            onChange={e => setNewMediaType(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={() => setAddMediaTypeDialogShow()}>Cancel</Button>
          <Button variant='primary' onClick={() => addMediaType()}>Add</Button>
        </Modal.Footer>
      </Modal>

    </Fragment>
  )
}


export default Task;

	