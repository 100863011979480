import React, { Fragment } from 'react'
import { Carousel, Col, Row, Table, Button }  from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import api from 'utils/api';
import shrimpColorChart from 'images/shrimp_color_chart.png';


const ShrimpEvaluationTask = () => {
  const navigate = useNavigate();
  const [task, setTask] = React.useState({});

  let { id } = useParams();

  React.useEffect(() => {
    api
      .get('/task/' + id  + '/')
      .then(res => {
        const { data } = res;
        if (data) {
          setTask(data);
          console.log(data);
        }
      }).catch(err => {
      });
  }, [setTask]);

  return (
    <Fragment>
        <Row>
          <Col className='text-center'>
            <h3>Shrimp Sensory Evaluation Task</h3>
          </Col>
        </Row>
        <Row className='py-3'>
          <Col lg={4} className='text-center'>
            <Table bordered>
              <tbody>
                <tr>
                  <td className='align-middle'>Date</td>
                  <td className='align-middle'>{task.date_display}</td>
                </tr>
                <tr>
                  <td className='align-middle'>Batch</td>
                  <td className='align-middle'>{task.batch_display}</td>
                </tr>
                <tr>
                  <td className='align-middle'>Tank</td>
                  <td className='align-middle'>{task.tank_display}</td>
                </tr>
                <tr>
                  <td className='align-middle'>Status</td>
                  <td className='align-middle'>{task.status_display}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col lg={8} className='text-center'>
            <Carousel>
              {task.attachments && task.attachments.map(attachment => (
                <Carousel.Item key={attachment.id} style={{backgroundColor: 'gray'}} className="text-center">
                  <img className="d-block mx-auto" height="300px" src={attachment.file} />
                  <Carousel.Caption>
                    <p>{attachment.description}</p>
                  </Carousel.Caption>
                </Carousel.Item>
              ))}
              <Carousel.Item key="shrimp_color_chart" style={{backgroundColor: 'gray'}} className="text-center">
                  <img className="d-block mx-auto" height="300px" src={shrimpColorChart} />
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
        <Row className='py-3 justify-content-center'>
            <p>Excellent = 5, Good = 4, Fair = 3, Poor = 2, Unfit = 1</p>
        </Row>
        <Row className='py-3'>
          <Col xs={6} lg={4} className='text-center'>
            <Table bordered>
              <thead>
                <tr>
                  <th>Pre-cooked Evaluation</th>
                  <th>AVG</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='align-middle text-nowrap'>Odor</td>
                  <td className='align-middle'>{task.pre_cooked_odor}</td>
                </tr>
                <tr>
                  <td className='align-middle text-nowrap'>Shell color</td>
                  <td className='align-middle'>{task.pre_cooked_shell_color}</td>
                </tr>
                <tr>
                  <td className='align-middle text-nowrap'>Shell texture</td>
                  <td className='align-middle'>{task.pre_cooked_shell_texture}</td>
                </tr>
                <tr>
                  <td className='align-middle text-nowrap'>Black spot</td>
                  <td className='align-middle'>{task.pre_cooked_black_spot}</td>
                </tr>
                <tr>
                  <td className='align-middle text-nowrap'>Guts line</td>
                  <td className='align-middle'>{task.pre_cooked_guts_line}</td>
                </tr>
                <tr>
                  <td className='align-middle text-nowrap'>Adhesion of head and body</td>
                  <td className='align-middle'>{task.pre_cooked_adhesion_of_head_and_body}</td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th>Cooked Evaluation</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='align-middle text-nowrap'>Color of the drip</td>
                  <td className='align-middle'>{task.cooked_color_of_the_drip}</td>
                </tr>
                <tr>
                  <td className='align-middle text-nowrap'>Odor</td>
                  <td className='align-middle'>{task.cooked_odor}</td>
                </tr>
                <tr>
                  <td className='align-middle text-nowrap'>No Off flavour</td>
                  <td className='align-middle'>{task.cooked_no_off_flavour}</td>
                </tr>
                <tr>
                  <td className='align-middle text-nowrap'>Flavor (Sweetness)</td>
                  <td className='align-middle'>{task.cooked_flavor_sweetness}</td>
                </tr>
                <tr>
                  <td className='align-middle text-nowrap'>Shell color</td>
                  <td className='align-middle'>{task.cooked_shell_color}</td>
                </tr>
                <tr>
                  <td className='align-middle text-nowrap'>Shell texture</td>
                  <td className='align-middle'>{task.cooked_shell_texture}</td>
                </tr>
                <tr>
                  <td className='align-middle text-nowrap'>Peelability</td>
                  <td className='align-middle'>{task.cooked_peelability}</td>
                </tr>
                <tr>
                  <td className='align-middle text-nowrap'>Meat color</td>
                  <td className='align-middle'>{task.cooked_meat_color}</td>
                </tr>
                <tr>
                  <td className='align-middle text-nowrap'>Meat texture</td>
                  <td className='align-middle'>{task.cooked_meat_texture}</td>
                </tr>
                <tr>
                  <td className='align-middle text-nowrap'>Overall satisfaction</td>
                  <td className='align-middle'>{task.cooked_overall_satisfaction}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col xs={6} lg={8} className='text-center'>
            <Table responsive bordered striped style={{overflowX: 'auto', width: '100%'}}>
              <thead>
                <tr>
                  {task.results && Array.from({ length: task.results.length }, (_, i) =>
                    <th key={i}>{task.results[i].panelist_department_display}</th>
                  )}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {task.results && Array.from({ length: task.results.length }, (_, i) =>
                    <td key={i} className='align-middle'>{task.results[i].pre_cooked_odor}</td>
                  )}
                </tr>
                <tr>
                  {task.results && Array.from({ length: task.results.length }, (_, i) =>
                    <td className='align-middle'>{task.results[i].pre_cooked_shell_color}</td>
                  )}
                </tr>
                <tr>
                  {task.results && Array.from({ length: task.results.length }, (_, i) =>
                    <td className='align-middle'>{task.results[i].pre_cooked_shell_texture}</td>
                  )}
                </tr>
                <tr>
                  {task.results && Array.from({ length: task.results.length }, (_, i) =>
                    <td className='align-middle'>{task.results[i].pre_cooked_black_spot}</td>
                  )}
                </tr>
                <tr>
                  {task.results && Array.from({ length: task.results.length }, (_, i) =>
                    <td className='align-middle'>{task.results[i].pre_cooked_guts_line}</td>
                  )}
                </tr>
                <tr>
                  {task.results && Array.from({ length: task.results.length }, (_, i) =>
                    <td className='align-middle'>{task.results[i].pre_cooked_adhesion_of_head_and_body}</td>
                  )}
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th className='align-middle'>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {task.results && Array.from({ length: task.results.length }, (_, i) =>
                    <td className='align-middle'>{task.results[i].cooked_color_of_the_drip}</td>
                  )}
                </tr>
                <tr>
                  {task.results && Array.from({ length: task.results.length }, (_, i) =>
                    <td className='align-middle'>{task.results[i].cooked_odor}</td>
                  )}
                </tr>
                <tr>
                  {task.results && Array.from({ length: task.results.length }, (_, i) =>
                    <td className='align-middle'>{task.results[i].cooked_no_off_flavour}</td>
                  )}
                </tr>
                <tr>
                  {task.results && Array.from({ length: task.results.length }, (_, i) =>
                    <td className='align-middle'>{task.results[i].cooked_flavor_sweetness}</td>
                  )}
                </tr>
                <tr>
                  {task.results && Array.from({ length: task.results.length }, (_, i) =>
                    <td className='align-middle'>{task.results[i].cooked_shell_color}</td>
                  )}
                </tr>
                <tr>
                  {task.results && Array.from({ length: task.results.length }, (_, i) =>
                    <td className='align-middle'>{task.results[i].cooked_shell_texture}</td>
                  )}
                </tr>
                <tr>
                  {task.results && Array.from({ length: task.results.length }, (_, i) =>
                    <td className='align-middle'>{task.results[i].cooked_peelability}</td>
                  )}
                </tr>
                <tr>
                  {task.results && Array.from({ length: task.results.length }, (_, i) =>
                    <td className='align-middle'>{task.results[i].cooked_meat_color}</td>
                  )}
                </tr>
                <tr>
                  {task.results && Array.from({ length: task.results.length }, (_, i) =>
                    <td className='align-middle'>{task.results[i].cooked_meat_texture}</td>
                  )}
                </tr>
                <tr>
                  {task.results && Array.from({ length: task.results.length }, (_, i) =>
                    <td className='align-middle'>{task.results[i].cooked_overall_satisfaction}</td>
                  )}
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
    </Fragment>
  )
}

export default ShrimpEvaluationTask;
