import React, { Fragment } from 'react'
import { Form, Col, Row, Table, Button }  from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { localISODateStr, localISOTimeStr } from 'utils/date';
import api from 'utils/api';


const SaltConsumptionTask = () => {
  const navigate = useNavigate();

  const [date, setDate] = React.useState(localISODateStr(new Date()));
  const [time, setTime] = React.useState(localISOTimeStr(new Date()));
  const [amount, setAmount] = React.useState(null);
  const [notes, setNotes] = React.useState(null);
  const [salts, setSalts] = React.useState(undefined);
  const [salt, setSalt] = React.useState(undefined);
  const [remaining_quantity, setRemaining_quantity] = React.useState(null);
  const [errors, setErrors] = React.useState({});

  React.useEffect(() => {
    api
      .get('/salt/')
      .then(res => {
        const { data } = res;
        if (data) {
          setSalts(data);
        }
      }).catch(err => {
      });
  }, [setSalts]);

  const onSaltChange = React.useCallback((salt_id) => {
    setSalt(salt_id);
    if(salt_id) {
      for(var i in salts)
        if(salts[i].id == salt_id) {
          setRemaining_quantity(salts[i].remaining_quantity);
        }
    } else {
      setRemaining_quantity(null);
    }
  }, [salts]);

  const onTaskSubmit = React.useCallback(() => {
    let params = {
      status: 'complete',
      type: 'Salt Consumption',
      date: date,
      start_time: time,
      salt: salt,
    };
    if(amount)
        params['amount_kg'] = amount;
    if(notes)
        params['notes'] = notes;

    const postData = () => {
      api
        .post('/task/', params)
        .then(res => {
          navigate(-2);
        }).catch(err => {
        });
    }
    postData();
  }, [salt, date, time, amount, notes, errors]);

  return (
    <Fragment>
      <Form noValidate>
        <Row>
          <Col className='text-center'>
            <h3>Add Salt Consumption Task</h3>
          </Col>
        </Row>
        <Row className='py-3'>
          <Col className='text-center'>
            <Table bordered>
              <tbody>
                <tr>
                  <td className='align-middle'>Salt</td>
                  <td className='align-middle'>
                    <Form.Control
                      as="select"
                      value={salt}
                      onChange={e => onSaltChange(e.target.value)}
                    >
                      <option value="">Select salt...</option>
                      {salts &&
                        salts.map((salt) => (
                          <option key={salt.id} value={salt.id}>{salt.display_name}</option>
                        ))
                      }
                    </Form.Control>
                  </td>
                </tr>
                <tr>
                  <td className='align-middle'>Remaining Quantity (Kg)</td>
                  <td className='align-middle'>{remaining_quantity}</td>
                </tr>
                <tr>
                  <td className='align-middle'>Date</td>
                  <td className='align-middle' colSpan="2">
                    <Form.Group as="span">
                      <Form.Control
                        type='date'
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                      />
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td className='align-middle'>Time</td>
                  <td className='align-middle' colSpan="2">
                    <Form.Group as="span">
                      <Form.Control
                        type='time'
                        value={time}
                        onChange={(e) => setTime(e.target.value)}
                      />
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td className='align-middle'>Amount (Kg)</td>
                  <td className='align-middle' colSpan="2">
                    <Form.Group as="span">
                      <Form.Control
                        type='text'
                        onChange={(e) => setAmount(e.target.value)}
                     />
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td className='align-middle'>Notes</td>
                  <td className='align-middle'>
                    <Form.Control
                      type='text'
                      onChange={e => setNotes(e.target.value)}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button variant='secondary' style={{'width':'100px'}} onClick={() => navigate(-1)}>Back</Button>
          </Col>
          <Col className='text-end'>
            <Button
              className='mx-0 px-0'
              variant='success'
              style={{'width':'100px'}}
              disabled={!salt}
              onClick={() => onTaskSubmit()}
            >Submit</Button>
          </Col>
        </Row>
      </Form>
    </Fragment>
  )
}

export default SaltConsumptionTask;
