import { useState } from 'react';
import { Image, Container, Navbar, Nav } from 'react-bootstrap';
import { Outlet, useLocation } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import logo from 'images/logo.png';
import logo_white from 'images/logo_white.svg';
import { ReactComponent as MoanaBrainLogo } from 'images/moana-brain-logo.svg';
import DarkModeMenu from 'components/DarkModeMenu';

import { useAuth, ProvideAuth } from 'utils/auth.js';
import { useTheme } from 'components/ThemeProvider';
import './Application.css';


const Application = () => {

  const auth = useAuth();
  const theme = useTheme();
  const location = useLocation();
  const [signoutShow, setSignoutShow] = useState(false);
  const [navExpanded, setNavExpanded] = useState(false);
  const signoutUrl = '#';

  function showSignOut() {
    setNavExpanded(false);
    setSignoutShow(true);
  }

  return (
    <ProvideAuth>
      <Navbar collapseOnSelect expand='xs' expanded={navExpanded} onToggle={(toggle) => setNavExpanded(toggle)}>
      <Container fluid>
        <Navbar.Brand href='/'>
          {
            location.pathname.includes('/moana-brain') ? 
              (<MoanaBrainLogo className={`moana-brain-logo ${theme[0] === 'dark' && 'logo-white'}`} />) : 
              (<Image src={theme[0] == 'dark' ? logo_white : logo} />)
          }
        </Navbar.Brand>
        <Nav className='ms-auto me-2'>
          <div className='alert alert-primary app-email' style={{cursor: 'pointer'}} role='alert'>
            <span>{auth.user.email.split('@')[0]}</span>
          </div>
        </Nav>
        <DarkModeMenu />
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav>
            <Link to='/app/tasks/' className='nav-link ms-auto' onClick={() => setNavExpanded(false)}>Unassigned Tasks</Link>
          </Nav>
          <Nav>
            <Link to='/app/mytasks/' className='nav-link ms-auto' onClick={() => setNavExpanded(false)}>My Tasks</Link>
          </Nav>
          <Nav>
            <Link to='/app/monitor/' className='nav-link ms-auto' onClick={() => setNavExpanded(false)}>Monitor</Link>
          </Nav>
          <Nav>
            <Link to='/app/monitor/seaweed/' className='nav-link ms-auto' onClick={() => setNavExpanded(false)}>Seaweed Management</Link>
          </Nav>
          <Nav>
            <Link to='/app/logbook/' className='nav-link ms-auto' onClick={() => setNavExpanded(false)}>Digital Logbook</Link>
          </Nav>
          <Nav>
            <Link to='/app/packaging/' className='nav-link ms-auto' onClick={() => setNavExpanded(false)}>Packaging</Link>
          </Nav>
          <Nav>
            <Link to='/app/label/' className='nav-link ms-auto' onClick={() => setNavExpanded(false)}>Labelling</Link>
          </Nav>
          <Nav>
            <Link to='/app/trace-lot/' className='nav-link ms-auto' onClick={() => setNavExpanded(false)}>Trace Lot</Link>
          </Nav>
          <Nav>
            <Link to='/app/water-exchange-regime/' className='nav-link ms-auto' onClick={() => setNavExpanded(false)}>Water Exchange Regime</Link>
          </Nav>
          <Nav>
            <Link to='/app/moana-brain-reduced/' className='nav-link ms-auto' onClick={() => setNavExpanded(false)}>Moana Brain</Link>
          </Nav>
          <Nav>
            <Link to='/app/reports/' className='nav-link ms-auto' onClick={() => setNavExpanded(false)}>Reports</Link>
          </Nav>
          <Nav>
            <Link to='/app/settings/' className='nav-link ms-auto' onClick={() => setNavExpanded(false)}>Settings</Link>
          </Nav>
          <Nav>
            <a href={signoutUrl} className='nav-link ms-auto' onClick={() => showSignOut()}>Sign Out</a>
          </Nav>
        </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid='md' className='mb-2'>
        <Outlet />
      </Container>
      <Modal
        show={signoutShow}
        onHide={() => setSignoutShow(false)}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Sign out?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setSignoutShow(false)}>No</Button>
          <Button variant='primary' onClick={() => auth.signout()}>Yes</Button>
        </Modal.Footer>
      </Modal>
    </ProvideAuth>
  )
}

export default Application;
