import React, { Fragment } from 'react'
import { Form, Col, Row, Table, Button, Container }  from 'react-bootstrap';
import { Image, Plus, X } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import DropdownMultiselect from 'thirdparty/react-multiselect-dropdown-bootstrap/DropdownMultiselect';

import { localISODateStr, localISOTimeStr } from 'utils/date';
import api from 'utils/api';


const DigitalLogbookTask = () => {

  const DEFAULT_LOCATION = 2; // TPD

  const navigate = useNavigate();
  const [errors, setErrors] = React.useState({});
  const [date, setDate] = React.useState(localISODateStr(new Date()));
  const [time, setTime] = React.useState(localISOTimeStr(new Date()));
  const [locations, setLocations] = React.useState(null);
  const [location, setLocation] = React.useState(DEFAULT_LOCATION);
  const [tanksSelected, setTanksSelected] = React.useState(null);
  const [tankOptions, setTankOptions] = React.useState(null);
  const [batchsSelected, setBatchsSelected] = React.useState(null);
  const [batchOptions, setBatchOptions] = React.useState(null);
  const [notes, setNotes] = React.useState(null);
  const [attachments, setAttachments] = React.useState([]);

  React.useEffect(() => {
    api
      .get('/location/')
      .then(res => {
        const { data } = res;
        if (data) {
          setLocations(data);
        }
      });
  }, [setLocations]);

  React.useEffect(() => {
    api
      .get('/tank/')
      .then(res => {
        const { data } = res;
        if (data) {
          var result = [];
          for(var i in data) {
            result.push(
              {
                'key': data[i].id,
                'label': data[i].display_id,
              }
            );
          }
          setTankOptions(result);
        }
      });
  }, [setTankOptions]);

  React.useEffect(() => {
    api
      .get('/batch/')
      .then(res => {
        const { data } = res;
        if (data) {
          var result = [];
          for(var i in data) {
            result.push(
              {
                'key': data[i].id,
                'label': data[i].display_id,
              }
            );
          }
          setBatchOptions(result);
        }
      });
  }, [setBatchOptions]);

  const onTanksSelected = React.useCallback((selectedTanks) => {
    setTanksSelected(selectedTanks);
  }, [setTanksSelected]);

  const onBatchsSelected = React.useCallback((selectedBatchs) => {
    setBatchsSelected(selectedBatchs);
  }, [setBatchsSelected]);

  const onTaskSubmit = React.useCallback(() => {

    let errors = {'attachments': {}};
    let have_errors = false;
    Object.keys(attachments).map(function (key) {
      var attachment = attachments[key];
      var image = attachment.image;
      var description = attachment.description;
      if(!attachment.image) {
        if(errors['attachments'][key] === undefined)
          errors['attachments'][key] = {};
        errors['attachments'][key]['image'] = [{
          'code': 'invalid',
          'message': 'This field is required',
        }];
        have_errors = true;
      }
      if(!attachment.description) {
        if(errors['attachments'][key] === undefined)
          errors['attachments'][key] = {};
        errors['attachments'][key]['description'] = [{
          'code': 'invalid',
          'message': 'This field is required',
        }];
        have_errors = true;
      }

    });

    if(have_errors) {
      setErrors(errors);
      return;
    } else {
      setErrors({});
    }

    let params = {
      status: 'complete',
      type: 'Digital Logbook',
      date: date,
      start_time: time,
      location: location,
    };
    if(tanksSelected)
        params['tanks'] = tanksSelected;
    if(batchsSelected)
        params['batchs'] = batchsSelected;
    if(notes)
        params['notes'] = notes;

    const formData = new FormData();
    Object.keys(params).map(function (key) {
      formData.append(key, params[key]);
    });
    params = formData

    const fetchData = () => {
      api
        .post('/task/', params)
        .then(res => {
          Object.keys(attachments).map(function (key) {
            var attachment = attachments[key];
            const formData = new FormData();
            formData.append('file', attachment.image);
            formData.append('description', attachment.description);
            formData.append('task', res.data.id);
            api.post(
              '/digitallogbookattachment/',
              formData,
            ).catch(err => {
              return;
            });
          });
          navigate('/app/mytasks/')
        }).catch(err => {
        });
    }
    fetchData();
  }, [date, time, location, tanksSelected, batchsSelected, notes, attachments]);

  const onAttachmentAdd = React.useCallback(() => {
    const newAttachments = [...attachments];
    newAttachments.push({
      'description': '',
    });
    setAttachments(newAttachments);
    setErrors({});
  }, [attachments]);

  const onAttachmentDelete = React.useCallback((index) => {
    const newAttachments = [...attachments];
    newAttachments.splice(index, 1);
    setAttachments(newAttachments);
    setErrors({});
  }, [attachments]);

  const setAttachmentDescription = React.useCallback((id, value) => {
    const newAttachments = [...attachments];
    newAttachments[id]['description'] = value;
    setAttachments(newAttachments);
  }, [attachments]);

  const setAttachmentImage = React.useCallback((id, value) => {
    const newAttachments = [...attachments];
    newAttachments[id]['image'] = value;
    setAttachments(newAttachments);
  }, [attachments]);

  return (
    <Fragment>
      <Form noValidate>
        <Row>
          <Col className='text-center'>
            <h3>Add Digital Logbook Task</h3>
          </Col>
        </Row>
        <Row className='py-3'>
          <Col className='text-center'>
            <Table bordered>
              <tbody>
                <tr>
                  <td className='align-middle'>Date</td>
                  <td className='align-middle' colSpan="2">
                    <Form.Group as="span">
                      <Form.Control
                        type='date'
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                      />
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td className='align-middle'>Time</td>
                  <td className='align-middle' colSpan="2">
                    <Form.Group as="span">
                      <Form.Control
                        type='time'
                        value={time}
                        onChange={(e) => setTime(e.target.value)}
                      />
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td className='align-middle'>Location</td>
                  <td className='align-middle'>
                    <Form.Control
                      as="select"
                      value={location}
                      onChange={e => setLocation(e.target.value)}
                    >
                      {locations &&
                        locations.map((location) => (
                          <option key={location.id} value={location.id}>{location.name}</option>
                        ))
                      }
                    </Form.Control>
                  </td>
                </tr>
                <tr>
                  <td className='align-middle'>Tanks</td>
                  <td className='align-middle'>
                    {tankOptions &&
                      <DropdownMultiselect
                        options={tankOptions}
                        name='tanks'
                        placeholder='Select tanks...'
                        handleOnChange={(selectedTanks) => {
                          onTanksSelected(selectedTanks);
                        }}
                      />
                    }
                  </td>
                </tr>
                <tr>
                  <td className='align-middle'>Batches</td>
                  <td className='align-middle'>
                    {batchOptions &&
                      <DropdownMultiselect
                        options={batchOptions}
                        name='batchs'
                        placeholder='Select batches...'
                        handleOnChange={(selectedBatchs) => {
                          onBatchsSelected(selectedBatchs);
                        }}
                      />
                    }
                  </td>
                </tr>
                <tr>
                  <td className='align-middle'>Notes</td>
                  <td className='align-middle'>
                    <Form.Control
                      type='text'
                      onChange={e => setNotes(e.target.value)}
                    />
                  </td>
                </tr>

                <tr>
                  <td
                    style={{backgroundColor: "LightSlateGray", color: "white"}}
                    className='align-middle'
                    colSpan="2"
                  >
                    <span>Attachments</span>
                    <span className="float-end" style={{'paddingLeft': '0px', 'paddingRight': '0px', 'cursor': 'pointer'}} onClick={() => onAttachmentAdd()}>
                      <Plus size={30} as="span" />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2" style={{'padding': '0px'}} >
                    <Table bordered className='mb-0'>
                      <thead>
                        <tr>
                          <th>File</th>
                          <th>Description</th>
                          <th>Delete?</th>
                        </tr>
                      </thead>
                      <tbody>
                        {attachments && attachments.map((attachment, index) => (
                          <tr key={index}>
                            <td>
                                <Form.Control
                                  type='file'
                                  accept='image/*, application/pdf'
                                  onChange={event => setAttachmentImage(index, event.target.files[0])}
                                  isInvalid={errors.attachments?.[index]?.image}
                                />
                                <Form.Control.Feedback type='invalid'>
                                  {errors.attachments?.[index]?.image?.[0]?.message}
                                </Form.Control.Feedback>
                            </td>
                            <td>
                              <Form.Group as="span">
                                <Form.Control
                                  type='text'
                                  value={attachment['description']}
                                  onChange={e => setAttachmentDescription(index, e.target.value)}
                                  isInvalid={errors.attachments?.[index]?.description}
                                />
                                <Form.Control.Feedback type='invalid'>
                                  {errors.attachments?.[index]?.description?.[0]?.message}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </td>
                            <td>
                              <X size={40} as="span" onClick={() => onAttachmentDelete(index)} />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button variant='secondary' style={{'width':'100px'}} onClick={() => navigate(-1)}>Back</Button>
          </Col>
          <Col className='text-end'>
            <Button
              className='mx-0 px-0'
              variant='success'
              style={{'width':'100px'}}
              onClick={() => onTaskSubmit()}
            >Submit</Button>
          </Col>
        </Row>
      </Form>
    </Fragment>
  )
}

export default DigitalLogbookTask;
