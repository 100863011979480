import React, { Fragment } from 'react'


const Debug = () => {

  const [info, setInfo] = React.useState({});

  React.useEffect(() => {
    console.log(window);
    setInfo({
       'devicePixelRatio': window.devicePixelRatio,
       'userAgent': window.navigator.userAgent,
       'innerWidth': window.innerWidth,
       'innerHeight': window.innerHeight,
       'outerWidth': window.outerWidth,
       'outerHeight': window.outerHeight,
       'screen.availHeight:': window.screen.availHeight,
       'screen.availLeft': window.screen.availLeft,
       'screenavailTop.': window.screen.availTop,
       'screen.availWidth': window.screen.availWidth,
       'screen.colorDepth': window.screen.colorDepth,
       'screen.height': window.screen.height,
       'screen.pixelDepth': window.screen.pixelDepth,
    });
  }, [setInfo]);

  return (
    <Fragment>
      <div>
        {Object.entries(info).map(([key, value]) => (
          <div key={key}>
            <span>{key}</span>&nbsp;=&nbsp;
            <span>{value}</span>
          </div>
        ))}
      </div>
    </Fragment>
  )
}

export default Debug;
