import { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Image, Button, Card, Col, Container, Navbar, Row, Spinner }  from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useGoogleLogin } from '@react-oauth/google';

import { useAuth } from "utils/auth.js";
import { useTheme } from 'components/ThemeProvider';
import logo from 'images/logo.png';
import logo_white from 'images/logo_white.svg';
import user_icon from 'images/icons/user-icon.svg';
import google_icon from 'images/google-icon.svg';


const Signin = () => {

  const auth = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(false)

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async ({ code }) => {
      setIsLoading(true)

      auth.signin(
        code,
        () => {
          navigate('/', {replace: true});
        },
        (err) => {
          let errorResponse = err?.response
          if(errorResponse) {
            toast.error(errorResponse);
          } else {
            toast.error("Error occured")
          }
        }
      )

      setIsLoading(false)
    },
    onError: errorResponse => {
      if (errorResponse.error_description) {
        toast.error(errorResponse.error_description)
      } else {
        toast.error(errorResponse.error)
      }

      setIsLoading(false)
    }
  });

  return (
    <Fragment>
      <Navbar>
        <Container fluid>
          <Navbar.Brand href='/'>
            <Image data-testid="logo" src={theme[0] == 'dark' ? logo_white : logo} />
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container fluid='md'>
          <Col className="px-4 d-flex justify-content-center">
            <Card style={{
              width: "400px"
            }} className="py-4" id="sign-in-card">
              <Card.Body>
                <Container>
                  <img id="user-icon" width="80px" className="d-block mx-auto mb-2" src={user_icon}/>
                  <Card.Title className="text-center fs-2">Sign In</Card.Title>
                  <Card.Text className="text-center fs-6">Welcome back!</Card.Text>
                  <Row>
                    <Col className="col-md-8 offset-md-2">
                      <Button 
                        onClick={() => googleLogin()}
                        className="d-block mx-auto mt-3" 
                        variant="light"
                        disabled={isLoading === true}
                        >
                        {isLoading ? (
                          <Spinner role='status' size='sm'></Spinner>
                        ) : (
                          <>
                            <img src={google_icon} className="d-inline me-2" /> Sign in with Google
                          </>
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          </Col>
      </Container>
    </Fragment>
  )
}

export default Signin;
