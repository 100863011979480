import { useState } from 'react'
import { toast } from 'react-toastify'

import { convertToLocalTime } from 'utils/date'

import useErrorHandler from './useErrorHandler';
import api from 'utils/api'

const createFormField = (label, value = '', datetime = '') => ({ value, datetime, label })

const INITIAL_TODAY_VARIABLES_FIELDS = {
  abw: createFormField('ABW (g)'),
  adg: createFormField('ADG (g)'),
  fcr: createFormField('FCR'),
  survival: createFormField('Survival'),
  feed_rate: createFormField('Real Feed Rate Adj'),
  avg_exchange_rate: createFormField('Avg Exchange Rate (m3/hr)'),
  temp_degc: createFormField('Temperature (degC)'),
  density: createFormField('Density (kg/m3)'),
  ammonia_nh4_mgl: createFormField('Ammonia NH4 (mgL)'),
  do_mgl: createFormField('DO (mgL)'),
  turbidity: createFormField('Turbidity (NTU)'),
  latest_condition_factor: createFormField('Latest Condition Factor'),
  latest_overall_sensory: createFormField('Latest Overall Sensory'),
  current_phase: createFormField('Current Phase'),
}

const INITIAL_STATIC_VARIABLES_FIELDS = {
  size_at_stocking: createFormField('Size At Stocking (mm)'),
  health_at_stocking: createFormField('Health At Stocking'),
}

const useVariablesForm = () => {
  const [todayVariablesFields, setTodayVariablesFields] = useState(INITIAL_TODAY_VARIABLES_FIELDS)
  const [staticVariablesFields, setStaticVariablesFields] = useState(INITIAL_STATIC_VARIABLES_FIELDS)
  const [isFetchingLiveData, setIsFetchingLiveData] = useState(false)
  const [tankName, setTankName] = useState('')
  const [isFromLiveData, setIsFromLiveData] = useState(false)
  const [liveData, setLiveData] = useState({})

  const handleError = useErrorHandler()

  function isInputValid(val) {
    // Max 6 digits, 2 decimal places
    const regex = /^\d{0,4}(\.\d{0,2})?$/
    return regex.test(val)
  }

  const handleInputChange = (e, dataType) => {
    const { name, value } = e.target

    if (!isInputValid(value)) return

    const setterFunction = dataType === 'todayVariables' ? setTodayVariablesFields : setStaticVariablesFields
    setterFunction(prevData => ({
      ...prevData,
      [name]: { 
        ...prevData[name], 
        value,
        datetime: '' // reset datetime when user modify the field
      }
    }))
  }

  function updateFieldsWithNewData(prevData, newData, isLiveData = false) {
    const newVariablesFields = { ...prevData }

    for (const fieldName in newData) {
      if (fieldName in newVariablesFields) {
        newVariablesFields[fieldName] = {
          ...newVariablesFields[fieldName],
          value: isLiveData ? newData[fieldName].value : newData[fieldName] || '' ,
          ...(isLiveData && { datetime: convertToLocalTime(new Date(newData[fieldName].datetime)) })
        }
      }
    }

    return newVariablesFields
  }

  const handleGetLiveData = async () => {
    if (!tankName) {
      toast.error('Please fill in the tank name.')
      return
    }

    setIsFetchingLiveData(true)

    try {
      const { data } = await api.get('/moanabrain/get_live_data/', {
        params: {
          tank: tankName
        }
      })
      
      setLiveData(data)
      setTodayVariablesFields(prevState => updateFieldsWithNewData(prevState, data, true))
      setStaticVariablesFields(prevState => updateFieldsWithNewData(prevState, data, true))
      setIsFromLiveData(true)
    } catch (error) {
      handleError(error)
    }

    setIsFetchingLiveData(false)
  }

  function updateVariableFields(newData) {
    setTodayVariablesFields(prevState => updateFieldsWithNewData(prevState, newData))
    setStaticVariablesFields(prevState => updateFieldsWithNewData(prevState, newData))
  }

  function handleResetForm() {
    setIsFromLiveData(false)
    setTodayVariablesFields(INITIAL_TODAY_VARIABLES_FIELDS)
    setStaticVariablesFields(INITIAL_STATIC_VARIABLES_FIELDS)
  }

  return {
    todayVariablesFields,
    staticVariablesFields,
    isFetchingLiveData,
    isFromLiveData,
    liveData,
    setTankName,
    handleInputChange,
    handleGetLiveData,
    handleResetForm,
    updateVariableFields,
  }
}

export default useVariablesForm