import ResultModal from './ResultsModal';
import moanaBrainStyles from '../MoanaBrain.module.css';
import styles from './PastResults.module.css';

import { convertToLocalTime } from 'utils/date';

import { useEffect, useState } from 'react';
import { Table, Spinner, Pagination, Card, Button } from 'react-bootstrap';

const ELLIPSIS = "…"

const PastResults = ({ getPastResults, isLoading, pastResultList, pageData }) => {
  const [currentViewResultId, setCurrentViewResultId] = useState(null)
  const [isShowingResultModal, setIsShowingResultModal] = useState(false)

  useEffect(() => {
    getPastResults(pageData.current)
  }, [])

  function handleCloseModal() {
    setIsShowingResultModal(false)
    setCurrentViewResultId(null)
  }

  function handleViewResult(resultId) {
    setIsShowingResultModal(true)
    setCurrentViewResultId(resultId)
  }

  return (
    <>
      <ResultModal isShowing={isShowingResultModal} resultId={currentViewResultId} handleDismiss={handleCloseModal} />

      <Card className={`${moanaBrainStyles.card} mb-3`}>
        <Card.Header className={moanaBrainStyles['card-header']}>Past Simulations</Card.Header>
        <Card.Body className={`${moanaBrainStyles['card-body']} p-0`}>
          <Table responsive className={styles['table']}>
            <thead className={styles['table-head']}>
              <tr>
                <th>Date</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody className={styles['table-body']}>
              {
                isLoading ? 
                  <tr><td colSpan={3} className="text-center"><Spinner as="span" role="status" size="sm" /></td></tr> : 
                  pastResultList.map(result => (
                    <tr key={result.id}>
                      <td>{convertToLocalTime(new Date(result.started_at))}</td>
                      <td>{result.status}</td>
                      <td><Button variant="info" size="sm" onClick={() => handleViewResult(result.id)}>View</Button></td>
                  </tr>
                  ))
              }
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer className={`${moanaBrainStyles['card-footer']} d-flex justify-content-center align-items-center`}>
          <Pagination size="sm" className={`${styles['pagination']}`}>
            <Pagination.Prev data-testid="prev-button" disabled={!pageData.has_previous} onClick={() => getPastResults(pageData.current - 1)}/>

            {
              pageData.elided_pages.map((pageNumber, index) => (
                pageNumber === ELLIPSIS ?
                  <Pagination.Ellipsis key={`ellipsis-${index}`} />
                  : <Pagination.Item 
                      key={pageNumber}
                      disabled={pageData.current === pageNumber} 
                      active={pageData.current === pageNumber} 
                      onClick={() => getPastResults(pageNumber)}
                    >{pageNumber}</Pagination.Item>
              ))
            }

            <Pagination.Next data-testid="next-button" disabled={!pageData.has_next} onClick={() => getPastResults(pageData.current + 1)}/>
          </Pagination>
        </Card.Footer>
      </Card>
    </>
  )
}

export default PastResults
