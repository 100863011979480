import React, { Fragment } from 'react'
import { Form, Col, Row, Table, Button }  from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { localISODateStr, localISOTimeStr } from 'utils/date';
import api from 'utils/api';


const ChemicalConsumptionTask = () => {
  const navigate = useNavigate();

  const [date, setDate] = React.useState(localISODateStr(new Date()));
  const [time, setTime] = React.useState(localISOTimeStr(new Date()));
  const [unit, setUnit] = React.useState(null);
  const [notes, setNotes] = React.useState(null);
  const [chemicals, setChemicals] = React.useState(undefined);
  const [chemical, setChemical] = React.useState(undefined);
  const [remaining_units, setRemaining_units] = React.useState(null);
  const [remaining_quantity, setRemaining_quantity] = React.useState(null);
  const [errors, setErrors] = React.useState({});

  React.useEffect(() => {
    api
      .get('/chemical/')
      .then(res => {
        const { data } = res;
        if (data) {
          setChemicals(data);
        }
      }).catch(err => {
      });
  }, [setChemicals]);

  const onChemicalsChange = React.useCallback((chemical_id) => {
    setChemical(chemical_id);
    if(chemical_id) {
      for(var i in chemicals)
        if(chemicals[i].id == chemical_id) {
          setRemaining_units(chemicals[i].remaining_units);
          setRemaining_quantity(chemicals[i].remaining_quantity);
        }
    } else {
      setRemaining_units(null);
      setRemaining_quantity(null);
    }
  }, [chemicals]);

  const onTaskSubmit = React.useCallback(() => {
    let params = {
      status: 'complete',
      type: 'Chemical Consumption',
      date: date,
      start_time: time,
      chemical: chemical,
    };
    if(unit)
        params['unit'] = unit;
    if(notes)
        params['notes'] = notes;

    const postData = () => {
      api
        .post('/task/', params)
        .then(res => {
          navigate(-2);
        }).catch(err => {
          setErrors(err?.response?.error?.detail);
        });
    }
    postData();
  }, [chemical, date, time, unit, notes, errors]);

  return (
    <Fragment>
      <Form noValidate>
        <Row>
          <Col className='text-center'>
            <h3>Add Chemical Consumption Task</h3>
          </Col>
        </Row>
        <Row className='py-3'>
          <Col className='text-center'>
            <Table bordered>
              <tbody>
                <tr>
                  <td className='align-middle'>Chemical</td>
                  <td className='align-middle'>
                    <Form.Control
                      as="select"
                      value={chemical}
                      onChange={e => onChemicalsChange(e.target.value)}
                    >
                      <option value="">Select chemical...</option>
                      {chemicals &&
                        chemicals.map((chemical) => (
                          <option key={chemical.id} value={chemical.id}>{chemical.display_name}</option>
                        ))
                      }
                    </Form.Control>
                  </td>
                </tr>
                <tr>
                  <td className='align-middle'>Remaining Units</td>
                  <td className='align-middle'>{remaining_units}</td>
                </tr>
                <tr>
                  <td className='align-middle'>Remaining Quantity</td>
                  <td className='align-middle'>{remaining_quantity}</td>
                </tr>

                <tr>
                  <td className='align-middle'>Date</td>
                  <td className='align-middle' colSpan="2">
                    <Form.Group as="span">
                      <Form.Control
                        type='date'
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                      />
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td className='align-middle'>Time</td>
                  <td className='align-middle' colSpan="2">
                    <Form.Group as="span">
                      <Form.Control
                        type='time'
                        value={time}
                        onChange={(e) => setTime(e.target.value)}
                      />
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td className='align-middle'>Unit(s)</td>
                  <td className='align-middle' colSpan="2">
                    <Form.Group as="span">
                      <Form.Control
                        type='text'
                        onChange={(e) => setUnit(e.target.value)}
                        isInvalid={errors.unit}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.unit?.[0]?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td className='align-middle'>Notes</td>
                  <td className='align-middle'>
                    <Form.Control
                      type='text'
                      onChange={e => setNotes(e.target.value)}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button variant='secondary' style={{'width':'100px'}} onClick={() => navigate(-1)}>Back</Button>
          </Col>
          <Col className='text-end'>
            <Button
              className='mx-0 px-0'
              variant='success'
              style={{'width':'100px'}}
              disabled={!chemical}
              onClick={() => onTaskSubmit()}
            >Submit</Button>
          </Col>
        </Row>
      </Form>
    </Fragment>
  )
}

export default ChemicalConsumptionTask;
