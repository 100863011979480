import { ReactComponent as Logo } from 'images/moana-brain-logo.svg';

import { ReactComponent as CalendarIcon } from 'images/icons/calendar-icon.svg';
import { ReactComponent as GlassIcon } from 'images/icons/glass-icon.svg';
import { ReactComponent as LifeRingIcon } from 'images/icons/life-ring-icon.svg';
import { ReactComponent as WeightIcon } from 'images/icons/weight-scale-icon.svg';

import { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, FormControl, InputGroup, Row, Spinner, Image } from 'react-bootstrap';
import { toast } from 'react-toastify'

import { convertToLocalTime } from 'utils/date';
import { useTheme } from 'components/ThemeProvider';

import LoaderCube from 'components/loaders/LoaderCube';
import FormField from './components/FormField';
import SimulationResult from './components/SimulationResult';
import useErrorHandler from './hooks/useErrorHandler';
import useInterval from './hooks/useInterval';
import useVariablesForm from './hooks/useVariablesForm';

import api from 'utils/api';

import styles from './MoanaBrain.module.css';
import PastResults from './components/PastResults';


const SIMULATION_STATUS = {
  PENDING: 'Pending',
  COMPLETED: 'Completed',
  EXPIRED: 'Expired',
  ERROR: 'Error',
}

const POLL_INTERVAL_DELAY = 5000
const PAGINATION_PER_PAGE = 5

const INITIAL_SIMULATION_RESULT = {
  job_started_at: '',
  job_ended_at: '',
  data: {
    ending_abw_result: { name: 'Ending ABW (g)', value: '...', icon: WeightIcon },
    time_taken_result: { name: 'Time taken to completed phase (days)', value: '...', icon: CalendarIcon },
    phase_fcr_result: { name: 'Phase FCR', value: '...', icon: GlassIcon },
    phase_survival_result: { name: 'Phase Survival', value: '...', icon: LifeRingIcon },
  }
}

const MoanaBrain = () => {
  const theme = useTheme()
  const {
    todayVariablesFields,
    staticVariablesFields,
    isFetchingLiveData,
    isFromLiveData,
    liveData,
    setTankName,
    handleInputChange,
    handleGetLiveData,
    handleResetForm,
    updateVariableFields,
  } = useVariablesForm()
  const handleError = useErrorHandler()

  const [isProcessing, setIsProcessing] = useState(false)
  const [pollingIntervalDelay, setPollingIntervalDelay] = useState(null)
  const [simulationResult, setSimulationResult] = useState(INITIAL_SIMULATION_RESULT)
  const pendingSimulationId = useRef(null)
  const [isFetchingPastResults, setIsFetchingPastResults] = useState(false)
  const [pastResultList, setPastResultList] = useState([])
  const [pastResultPageData, setPastResultPageData] = useState({
    current: 1,
    elided_pages: [],
    has_previous: false,
    has_next: false,
  })

  useEffect(() => {
    async function fetchRunningSimulation() {
      const runningSimulation = await getRunningSimulation()
      if (runningSimulation.status === SIMULATION_STATUS['PENDING']) {
        // poll the pending simulation
        updateVariableFields(runningSimulation.variables)
        pendingSimulationId.current = runningSimulation.id
        pollPendingSimulation()
      }
    }

    fetchRunningSimulation()
  }, [])

  useInterval(() => {
    // The poll pending simulation that will run every 'pollingIntervalDelay'
    // You can trigger it to run just by updating the 'pollingIntervalDelay' value
    pollPendingSimulation()
  }, pollingIntervalDelay)

  async function pollPendingSimulation() {
    setIsProcessing(true)

    try {
      const response = await api.get(`/moanabrain/${pendingSimulationId.current}`)

      if (response.data.status === SIMULATION_STATUS['COMPLETED']) {
        // If simulation completed, display the result
        setSimulationResult(prevResult => ({
          job_started_at: response.data.started_at && convertToLocalTime(new Date(response.data.started_at)),
          job_ended_at: response.data.finished_at && convertToLocalTime(new Date(response.data.finished_at)),
          data: {
            ending_abw_result: { ...prevResult.data.ending_abw_result, value: response.data.ending_abw_result },
            time_taken_result: { ...prevResult.data.time_taken_result, value: response.data.time_taken_result },
            phase_fcr_result: { ...prevResult.data.phase_fcr_result, value: response.data.phase_fcr_result },
            phase_survival_result: { ...prevResult.data.phase_survival_result, value: response.data.phase_survival_result }
          },
        }))

        // stop polling
        pendingSimulationId.current = null
        setPollingIntervalDelay(0)
        setIsProcessing(false)

        // refresh the past results
        getPastResults(1)
      } else if (response.data.status === SIMULATION_STATUS['EXPIRED']) {
        toast.error('Simulation runtime exceeded limit.')

        pendingSimulationId.current = null
        setPollingIntervalDelay(0)
        setIsProcessing(false)
      } else if (response.data.status === SIMULATION_STATUS['ERROR']) {
        toast.error('Simulation error occurred.')

        pendingSimulationId.current = null
        setPollingIntervalDelay(0)
        setIsProcessing(false)
      } else {
        // if there is running simulation, display the job started datetime
        setSimulationResult(prevResult => ({
          ...prevResult,
          job_started_at: response.data.started_at && convertToLocalTime(new Date(response.data.started_at))
        }))

        // then do polling
        setPollingIntervalDelay(POLL_INTERVAL_DELAY)
      }
    } catch (error) {
      handleError(error)

      setIsProcessing(false)
    }
  }

  async function getRunningSimulation() {
    try {
      const response = await api.get('/moanabrain/get_running_simulation')
      return response.data
    } catch (error) {
      handleError(error)
    }
  }

  async function getPastResults(pageNumber) {
    setIsFetchingPastResults(true)

    try {
      const { data } = await api.get('/moanabrain/', {
        params: {
          page: pageNumber,
          per_page: PAGINATION_PER_PAGE
        }
      })

      setPastResultList(data.data)
      setPastResultPageData(data.page)
    } catch (error) {
      handleError(error)
    }

    setIsFetchingPastResults(false)
  }

  async function handleSimulatePhase() {
    // Create a form key:value to send to BE
    const formFields = { ...todayVariablesFields, ...staticVariablesFields }
    const fieldData = {}
    for (const fieldName in formFields) {
      if (formFields[fieldName].value === '') {
        return toast.error('All fields are required!')
      }

      if (fieldName === 'abw' && formFields[fieldName].value > 24) {
        return toast.error('ABW value exceeds maximum. Please enter a number up to 24')
      }

      fieldData[fieldName] = formFields[fieldName].value
    }

    // reset the result
    setSimulationResult(INITIAL_SIMULATION_RESULT)

    setIsProcessing(true)

    try {
      const res = await api.post('/moanabrain/', { variables: fieldData })
      const runningSimulation = await getRunningSimulation()

      if (runningSimulation.status === SIMULATION_STATUS['PENDING']) {
        // If the job still pending then do polling
        pendingSimulationId.current = runningSimulation.id
        pollPendingSimulation()
      } else {
        setIsProcessing(false)
      }
    } catch (error) {
      handleError(error)

      setIsProcessing(false)
    }
  }

  return (
    <>
      <Row>
        <Col xs={12}>
          <h3 className="text-center">Moana Brain Reduced Version 1.0</h3>
        </Col>
      </Row>
      <Row className={`mt-2 ${styles.container} ${theme[0] == 'dark' ? styles['dark-mode'] : ''}`}>
        <Col xs="12" lg="4">
          <Card className={`${styles.card} mb-3`}>
            <Card.Body>
              {
                isFromLiveData && (
                  <div className={`d-flex`}>
                    <Col xs={6} className="text-end border-end border-2 pe-2">
                      <div className="fw-bold">
                        {liveData?.tank.value}
                      </div>
                      <div className={`${styles['data-detail-datetime']} text-muted`}>
                        {liveData?.tank ? convertToLocalTime(new Date(liveData.tank.datetime)) : ''}
                      </div>
                    </Col>
                    <Col xs={6} className="text-start ps-2">
                      <div className="fw-bold">
                        Batch {liveData?.batch.value}
                      </div>
                      <div className={`${styles['data-detail-datetime']} text-muted`}>
                        {liveData?.batch ? convertToLocalTime(new Date(liveData.batch.datetime)) : ''}
                      </div>
                    </Col>
                  </div>
                )
              }

              {
                !isFromLiveData && (
                  <InputGroup>
                    <FormControl
                      placeholder="Tank Name (e.g. IHT1)"
                      type="text"
                      disabled={isProcessing}
                      onChange={(e) => setTankName(e.target.value)}
                    />
                    <Button
                      variant="info"
                      disabled={isProcessing}
                      onClick={handleGetLiveData}
                    >
                      {isFetchingLiveData && <Spinner
                        as="span"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-2" />
                      }
                      Start from Live Status
                    </Button>
                  </InputGroup>
                )
              }

              <div className="d-grid">
                <Button disabled={isProcessing} onClick={handleResetForm} variant="danger" className="mt-3">
                  Reset Form
                </Button>
              </div>

            </Card.Body>
          </Card>


          <Card className={`${styles.card} mb-3 mt-3`}>
            <Card.Header className={styles['card-header']}>Start from Manually Entered Status</Card.Header>
            <Card.Body className={styles['card-body']}>
              <Form>
                {
                  Object.entries(todayVariablesFields).map(([fieldName, fieldData]) => (
                    <FormField
                      key={fieldName}
                      fieldName={fieldName}
                      fieldData={fieldData}
                      isProcessing={isProcessing}
                      onChange={(e) => handleInputChange(e, 'todayVariables')}
                    />
                  ))
                }
              </Form>
            </Card.Body>
          </Card>

          <Card className={`${styles.card} mb-3`}>
            <Card.Header className={styles['card-header']}>Static Variables</Card.Header>
            <Card.Body className={styles['card-body']}>
              <Form>
                {
                  Object.entries(staticVariablesFields).map(([fieldName, fieldData]) => (
                    <FormField
                      key={fieldName}
                      fieldName={fieldName}
                      fieldData={fieldData}
                      isProcessing={isProcessing}
                      onChange={(e) => handleInputChange(e, 'staticVariables')}
                    />
                  ))
                }
              </Form>
            </Card.Body>
          </Card>
        </Col>

        <Col xs="12" lg="4" className="d-flex flex-column justify-content-center align-items-center p-3">
          <Row className={styles['loading-cube-container']}>
            { !isProcessing && <Logo className={styles['logo']}/> }
            <LoaderCube isAnimating={isProcessing} />
          </Row>
          <Row>
            <Button variant="info" onClick={handleSimulatePhase} disabled={isProcessing} size="lg">
              {isProcessing ? (
                <>
                  <Spinner
                    as="span"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  {' Processing..'}
                </>
              ) : 'Simulate Phase'}
            </Button>
          </Row>
        </Col>

        <Col xs="12" lg="4" className="d-flex flex-column justify-content-center">
          <Card className={`${styles.card} mb-3`}>
            <Card.Header className={styles['card-header']}>Simulation Results</Card.Header>
            <Card.Body className={styles['card-body']}>
              <SimulationResult result={simulationResult.data} isProcessing={isProcessing} />
            </Card.Body>
            {
              simulationResult.job_started_at && (
                <Card.Footer className={styles['card-footer']}>
                  <div className="d-flex">
                    <Col xs={6} className="text-end border-end border-2 pe-2">
                      <div>
                        Job started at
                      </div>
                      <div className={`${styles['data-detail-datetime']} text-muted`}>
                        {simulationResult.job_started_at}
                      </div>
                    </Col>
                    <Col xs={6} className="text-start ps-2">
                      <div>
                        Job ended at
                      </div>
                      <div className={`${styles['data-detail-datetime']} text-muted`}>
                        {simulationResult.job_ended_at}
                      </div>
                    </Col>
                  </div>
                </Card.Footer>
              )
            }
          </Card>

          <PastResults 
            getPastResults={getPastResults} 
            isLoading={isFetchingPastResults}
            pastResultList={pastResultList}
            pageData={pastResultPageData}
          />

        </Col>
      </Row>
    </>
  )
}

export default MoanaBrain