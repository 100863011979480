import { useEffect, useRef } from 'react';

function useInterval(callback, delay) {
    const savedCallback = useRef()

    // remember the latest callback function
    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    // set up the interval
    useEffect(() => {
        function tick() {
            savedCallback.current()
        }
        if (delay != null && delay > 0) {
            let id = setInterval(tick, delay)
            return () => clearInterval(id)
        }
    }, [delay])
}

export default useInterval
