import React from 'react';
import { Page, Text, Document, StyleSheet, Image } from '@react-pdf/renderer';

import template from 'images/label_template_address.jpeg';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  harvestDate: {
    position: 'absolute',
    top: '10%',
    left: '38%',
    width: '100%',
    textAlign: 'left',
    fontSize: '15'
  },
  labelDetails: {
      position: 'absolute',
      top: '37%',
      left: '3%',
      width: '100%',
      textAlign: 'left',
      fontSize: '10'
  }
});

const PdfLabel = (props) => {

    const {labels} =  props;

    return (
        <Document>
            {labels.map(label => (
            <Page key={label.id} size={[300,120]} style={styles.page} className="DisplayImg">
                <Image src={template} alt="label"/>
                <Text style={styles.labelDetails}>
                Name: {label?.name}{'\n'}
                Address: {label?.address1}{'\n'}
                {' '}{' '}{' '}{' '}{' '}{' '}{' '}{' '}{' '}{' '}{' '}{' '}{' '}{' '}{' '}{' '}
                {label?.address2}{'\n'}
                Contact: {label?.contact}{'\n'}
                Route: {label?.route}
                {' '}{' '}{' '}{' '}{' '}
                Box: {label?.box_display}
                {' '}{' '}{' '}{' '}{' '}
                Tray: {label?.tray_display}{'\n'}
                Lot Number: {label?.lot_number}
                </Text>
                <Text style={styles.harvestDate}>{label?.harvest_date}</Text>
            </Page>
            ))}
        </Document>
    )
};

export default PdfLabel;